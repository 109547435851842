import { Skeleton } from 'atp-react-ui';

export function InputSkeleton() {
  return (
    <section className="flex flex-col gap-y-1.5">
      <Skeleton className="h-[1.124rem] w-28" />
      <Skeleton className="h-[2.625rem] w-2/3 md:w-full" />
    </section>
  );
}
