import { lazy } from 'react';

import { SurveyPage } from '../shared';

const Page = lazy(() => import('./page'));

export function SelfOnboardingSurvey() {
  return (
    <SurveyPage title="Self Onboarding Survey">
      <Page />
    </SurveyPage>
  );
}
