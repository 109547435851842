import { Skeleton } from 'atp-react-ui';

import { Container, StickyPageHeader } from '@/_components';
import { HeadersTabWrapper } from '@/_containers';

export function OdtQuestionsSectionSkeleton() {
  return (
    <>
      <StickyPageHeader threshold={106} className="pb-0">
        <Container>
          <HeadersTabWrapper
            headerClassName="pt-2"
            className="flex-col-reverse"
            rightSection={
              <div className="flex items-center gap-4 pt-4 md:py-1">
                <div className="flex-1 lg:w-64">
                  <Skeleton className="h-[2.625rem]" />
                </div>
                <Skeleton className="h-[2.625rem] w-28" />
              </div>
            }
          >
            {[0, 1, 2].map((tab) => (
              <div key={tab} className="py-2">
                <Skeleton className="h-8 w-28" />
              </div>
            ))}
          </HeadersTabWrapper>
        </Container>
      </StickyPageHeader>

      <article className="mt-8 flex flex-col gap-y-8">
        <Container className="flex flex-col gap-y-4">
          <Skeleton className="h-8 w-52" />
          <Question />
          <Question />
          <Question />
        </Container>

        <Container className="flex flex-col gap-y-4">
          <Skeleton className="h-8 w-52" />
          <Question />
          <Question />
          <Question />
        </Container>

        <Container className="flex flex-col gap-y-4">
          <Skeleton className="h-8 w-52" />
          <Question />
          <Question />
          <Question />
        </Container>
      </article>
    </>
  );
}

function Question() {
  return (
    <div className="relative flex h-36 min-w-0 rounded-xl bg-white shadow-card">
      <div className="flex h-36 w-32 items-center justify-center border-r border-outline/30">
        <Skeleton className="h-4 w-10" />
      </div>
      <div className="flex flex-1 flex-col px-6 py-5">
        <Skeleton className="h-4 w-52" />
        <Skeleton className="mt-1 h-4 w-64" />
        <Skeleton className="mt-4 h-4 w-full" />
        <Skeleton className="mt-1 h-4 w-full" />
        <Skeleton className="mt-1 h-4 w-3/4" />
      </div>
      <Skeleton className="absolute right-6 top-5 h-4 w-11" />
    </div>
  );
}
