import { Skeleton } from 'atp-react-ui';

import { StickyPageHeader, Container, PageHead } from '@/_components';

const skeletons = Array.from(Array(5)).map((_, i) => i);

export default function Loading() {
  return (
    <>
      <StickyPageHeader className="top-12">
        <Container>
          <PageHead className="flex-col items-start">
            <section className="mt-2 flex items-center">
              <Skeleton className="h-[1.125rem] w-72" />
            </section>

            <Skeleton className="mt-3 h-[1.125rem] w-36" />
          </PageHead>
        </Container>
      </StickyPageHeader>

      <Container>
        {skeletons.map((i) => (
          <div key={`terms-loader-${i}`} className="mt-4">
            <Skeleton className="h-[1.125rem] w-48" />
            <Skeleton className="mt-2 h-[1.125rem] w-full" />
            <Skeleton className="mt-1 h-[1.125rem] w-full" />
            <Skeleton className="mt-1 h-[1.125rem] w-full" />
            <Skeleton className="mt-1 h-[1.125rem] w-full" />
            <Skeleton className="mt-1 h-[1.125rem] w-full" />
            <Skeleton className="mt-1 h-[1.125rem] w-3/4" />
          </div>
        ))}
      </Container>
    </>
  );
}
