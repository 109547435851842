import React from 'react';

import { noop } from '@/_shared';

import type { AccountDependencyQuerySchema } from '../hooks/use-account-dependencies';

export type AccountDependenciesContextType = {
  data: AccountDependencyQuerySchema | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isRewardProgramActive: boolean;
  setReferralModal: () => void;
};

export const AccountDependenciesContext = React.createContext<AccountDependenciesContextType>({
  data: undefined,
  isLoading: false,
  isFetching: false,
  isRewardProgramActive: false,
  setReferralModal: noop,
});
