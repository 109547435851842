import { Card, CardBody, ScrollArea, Skeleton } from 'atp-react-ui';

import { TakenCourseSkeleton } from './take-course-skeleton';
import { TakenOdtCourseCardSkeleton } from './taken-odt-course-card-skeleton';

export default function Loader() {
  return (
    <>
      <section className="flex w-full flex-col gap-y-4">
        <Skeleton className="h-[1.125rem] w-72 max-w-full" />

        <ScrollArea orientation="horizontal" type="auto">
          <div className="flex gap-x-6 px-0.5 pb-6">
            <TakenCourseSkeleton />
            <TakenCourseSkeleton />
            <TakenCourseSkeleton />
            <TakenCourseSkeleton />
            <TakenCourseSkeleton />
            <TakenCourseSkeleton />
            <TakenCourseSkeleton />
            <TakenCourseSkeleton />
          </div>
        </ScrollArea>
      </section>

      <section className="flex w-full flex-col gap-y-4">
        <Skeleton className="h-[1.125rem] w-72 max-w-full" />

        <ScrollArea orientation="horizontal" type="auto">
          <div className="flex gap-x-6 px-0.5 pb-6">
            <TakenOdtCourseCardSkeleton />
            <TakenOdtCourseCardSkeleton />
            <TakenOdtCourseCardSkeleton />
            <TakenOdtCourseCardSkeleton />
            <TakenOdtCourseCardSkeleton />
            <TakenOdtCourseCardSkeleton />
            <TakenOdtCourseCardSkeleton />
            <TakenOdtCourseCardSkeleton />
          </div>
        </ScrollArea>
      </section>

      <section className="flex w-full flex-col gap-y-4 pre-xl:w-3/4">
        <Skeleton className="h-[1.125rem] w-72 max-w-full" />

        <Card>
          <CardBody className="flex items-center justify-between">
            <Skeleton className="mt-1 h-[1.125rem] w-96" />
          </CardBody>
        </Card>
        <Card>
          <CardBody className="flex items-center justify-between">
            <Skeleton className="mt-1 h-[1.125rem] w-96" />
          </CardBody>
        </Card>
        <Card>
          <CardBody className="flex items-center justify-between">
            <Skeleton className="mt-1 h-[1.125rem] w-96" />
          </CardBody>
        </Card>
        <Card>
          <CardBody className="flex items-center justify-between">
            <Skeleton className="mt-1 h-[1.125rem] w-96" />
          </CardBody>
        </Card>
      </section>
    </>
  );
}
