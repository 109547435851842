import type { PropsWithChildren } from 'react';

export default function Layout({ children }: PropsWithChildren<unknown>) {
  return (
    <section
      className="flex h-screen w-screen items-center justify-center bg-cover bg-[-16rem_center] bg-no-repeat xs:bg-[30%_center] pre-lg:bg-left-top"
      style={{
        backgroundImage: 'url(/images/atp-waiting-desktop.jpg)',
      }}
    >
      <section className="absolute inset-0 bottom-auto">
        <article className="flex items-center justify-between pt-8">
          <div />
          <div className="pr-28">
            <img src="/images/atp-white-logo.svg" className="h-14" alt="Achieve Test Prep" />
          </div>
        </article>
      </section>
      {children}
    </section>
  );
}
