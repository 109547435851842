import type { ReactNode } from 'react';

import { toast } from 'react-toastify';

const success = (str: string | ReactNode, opt = {}) => {
  toast.success(str, opt);
};

const error = (str: string | ReactNode, opt = {}) => {
  toast.error(str, opt);
};

const info = (str: string | ReactNode, opt = {}) => {
  toast.info(str, opt);
};

const remove = () => {
  toast.dismiss();
};

export const snackbar = { success, error, info, remove };
