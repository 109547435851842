import { lazy, Suspense } from 'react';

import Loading from './loading';

const Routes = lazy(() => import('./routes'));

export default function WhyBridge() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes />
    </Suspense>
  );
}
