import type { ReactNode } from 'react';
import { Suspense } from 'react';

import { ErrorBoundary } from '@/_components';

import Layout from './layout';
import Loading from './loading';
import { SurveyProvider } from './survey-context';

export function SurveyPage({ title, children }: { title: string; children: ReactNode }) {
  return (
    <SurveyProvider>
      <Layout>
        <ErrorBoundary>
          <Suspense fallback={<Loading title={title} />}>{children}</Suspense>
        </ErrorBoundary>
      </Layout>
    </SurveyProvider>
  );
}
