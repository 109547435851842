/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { useBoolean, useIsMounted } from 'atp-react-ui';

import { config } from '@/_config';
import { invalidateMeQuery, useAccountDependencies, useMeMutation, useMeQuery } from '@/_providers';

let timerForIframe: string | number | NodeJS.Timeout | null | undefined = null;

declare global {
  interface Window {
    _zrl: any[];
    zrl_mi: any;
  }
}

window._zrl = window._zrl || [];

export const useZinrelo = () => {
  const [isLoaded, loaded] = useBoolean();
  const [isDashboardLoading, dashboardLoading] = useBoolean(false);
  const [patchMeDependencyApi, { isLoading: isUpdatingMe }] = useMeMutation<{
    allow_rewards: boolean;
  }>({
    method: 'PATCH',
  });

  const { data: meData, isFetching: isLoadingMeData } = useMeQuery();
  const { name } = meData || {};
  const { data: depData, isFetching: isLoadingAccountDeps, isSuccess, refetch } = useAccountDependencies();

  const { zinrelo_user_info, email } = depData || {};
  const isRewardProgramActive = Object.keys(zinrelo_user_info || {}).length > 0;

  const { access_token, uid, epoch_time } = zinrelo_user_info || {};

  const registerIntoRewardsProgram = async () => {
    if (isRewardProgramActive) {
      return;
    }
    const response = await patchMeDependencyApi({ allow_rewards: true });
    if (response) {
      await invalidateMeQuery();
      dashboardLoading.setTrue();
      setTimeout(() => refetch(), 2000);
    }
  };

  const readMessage = (event: MessageEvent) => {
    if (event.origin !== 'https://app.zinrelo.com' || !event.data) return;
    const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
    if (data.target === 'get_user_available_points') {
      if (isLoaded) {
        refetch();
      } else {
        loaded.setTrue();
      }
    }

    if (data.dashboard_loaded) {
      dashboardLoading.setFalse();
    }
  };

  const handleDashboardLoad = () => {
    if (!window.zrl_mi) {
      const script = document.createElement('script');
      script.src = 'https://cdn.zinrelo.com/js/all.js';
      script.async = true;
      script.onload = () => {
        const init_data = {
          partner_id: config.zinreloPartnerId,
          email,
          name,
          user_id: uid,
          ts: epoch_time,
          access_token,
        };
        window._zrl.push(['init', init_data]);
      };

      document.body.appendChild(script);
    } else {
      window.zrl_mi?.attach_dashboard();

      timerForIframe = setInterval(() => {
        const zinIframe = document.getElementById('zrl_iframe') as HTMLElement;
        if (zinIframe) {
          zinIframe.setAttribute('data-hj-allow-iframe', 'true');
          clearInterval(timerForIframe as NodeJS.Timeout);
        }
      }, 250);
    }
  };

  useEffect(() => {
    if (isSuccess && isRewardProgramActive) {
      handleDashboardLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isRewardProgramActive]);

  useIsMounted(() => {
    window.addEventListener('message', readMessage, false);

    // !important: double useEffect call issue react/strictMode
    return () => {
      window.removeEventListener('message', readMessage);
    };
  });

  return {
    registerIntoRewardsProgram,
    isRewardProgramActive,
    me: meData,
    isDashboardLoading: isDashboardLoading || isLoadingAccountDeps,
    isRegistering: isUpdatingMe || isLoadingMeData,
  };
};
