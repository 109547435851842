import { Button, Modal, ModalBody, ModalFooter } from 'atp-react-ui';

import { dateTime } from '@/_shared';

import { WellcoachesAgreementTerms } from './terms';
import { useWellcoachesAgreementModal } from './use-wellcoaches-agreement-modal';

export function WellcoachesAgreementModal() {
  const {
    actualFullName,
    fullname,
    showModal,
    handleNameChange,
    toggleModal,
    handlePositive,
    handleNegative,
    isLoading,
    error,
  } = useWellcoachesAgreementModal();

  return (
    <Modal open={showModal} onClose={toggleModal} className="md:max-w-5xl" disableInternalClosing>
      <ModalBody>
        <WellcoachesAgreementTerms
          name={fullname}
          actualFullName={actualFullName}
          date={dateTime.getFormattedDate(dateTime.now(), 'MM/DD/YYYY').toString()}
          error={error}
          handleNameChange={handleNameChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button modal as="contained" showLoader={isLoading} onClick={handlePositive}>
          Agree & Continue
        </Button>
        <Button modal as="outline" disabled={isLoading} onClick={handleNegative}>
          Decline & Exit
        </Button>
      </ModalFooter>
    </Modal>
  );
}
