import { useEffect } from 'react';

import { useIsMounted } from 'atp-react-ui';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { googleAnalytics4, initChat, queryClient, useInitHotJar } from '@/_services';

export default function Administrator() {
  const { pathname } = useLocation();

  const hotJarStateChange = useInitHotJar();

  useEffect(() => {
    window.scrollTo(0, 0);
    hotJarStateChange(pathname);
  }, [hotJarStateChange, pathname]);

  useIsMounted(() => {
    googleAnalytics4.init();
    initChat();
  });

  useIsMounted(() => {
    queryClient.invalidateQueries();

    (document.getElementById('ldr') as HTMLElement).style.display = 'none';
    (document.getElementById('root') as HTMLElement).style.display = '';
  });

  return (
    <>
      <ToastContainer
        limit={5}
        position="bottom-left"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
      <Outlet />
    </>
  );
}
