import React from 'react';

import { NotificationsContext } from './context';
import { systemNotifications } from './system-notifications';

export function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const [visible, setVisible] = React.useState(true);
  const [remindLater, setRemindLater] = React.useState(false);
  const [_systemNotifications] = React.useState<JSX.Element[]>(systemNotifications);
  const [notifications, setNotifications] = React.useState<JSX.Element[]>(systemNotifications);

  const show = React.useCallback(() => setVisible(true), []);
  const hide = React.useCallback(() => setVisible(false), []);
  const toggle = React.useCallback(() => setVisible((v) => !v), []);
  const onRemindLater = React.useCallback(() => setRemindLater(true), []);

  const value = React.useMemo(
    () => ({
      visible,
      toggle,
      show,
      hide,
      notifications,
      setNotifications,
      remindLater,
      onRemindLater,
      systemNotifications: _systemNotifications,
    }),
    [visible, toggle, show, hide, notifications, remindLater, onRemindLater, _systemNotifications]
  );

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
}
