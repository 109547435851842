import type { PropsWithChildren } from 'react';
import React from 'react';

import SingleSlashIcon from '@heroicons/react/24/outline/SignalSlashIcon';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Button, Icon, twMerge } from 'atp-react-ui';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { queryClient } from '@/_services';

import { BlockingRetry } from './blocking-retry';

type ErrorBoundaryType = 'default' | 'blocking';
type ErrorBoundaryProps = {
  boundaryType?: ErrorBoundaryType;
  className?: string;
};

export function ErrorBoundary({
  children,
  boundaryType = 'default',
  className,
}: PropsWithChildren<ErrorBoundaryProps>) {
  const { reset } = useQueryErrorResetBoundary();

  const onResetError = React.useCallback(() => {
    /* 
      Need to clear react-query client. It's needed because
      in case of access token getting renewed after expiration (refresh token)
      the client keeps the stale error data which causes app crash on some instances.
    */
    queryClient.removeQueries();
    reset();
  }, [reset]);

  return (
    <ReactErrorBoundary
      fallbackRender={(props) => <OnError {...props} boundaryType={boundaryType} className={className} />}
      onReset={onResetError}
    >
      {children}
    </ReactErrorBoundary>
  );
}

type OnErrorProps = FallbackProps & {
  boundaryType: ErrorBoundaryType;
  className?: string;
};
export function OnError({ error, resetErrorBoundary, boundaryType, className }: OnErrorProps) {
  console.error('Error Boundary:', error);

  if (boundaryType === 'blocking') {
    return <BlockingRetry isOpen refetch={resetErrorBoundary} />;
  }

  return (
    <section className={twMerge('mt-20 flex flex-col items-center justify-center', className)}>
      <Icon icon={SingleSlashIcon} className="size-16 text-accent-x-light" />
      <div className="mb-8 mt-2 w-96 text-center text-accent-light">
        Oops, something went wrong.
        <br />
        Try fixing your internet connection and try again.
      </div>
      <Button onClick={() => resetErrorBoundary()}>Try again</Button>
    </section>
  );
}
