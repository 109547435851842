import React from 'react';

import { noop } from '@/_shared';

import type { NotificationsContextType } from './types';

export const NotificationsContext = React.createContext<NotificationsContextType>({
  visible: true,
  show: noop,
  hide: noop,
  toggle: noop,
  notifications: [],
  systemNotifications: [],
  setNotifications: noop,
  remindLater: false,
  onRemindLater: noop,
});
