import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon';
import HomeIcon from '@heroicons/react/24/solid/HomeIcon';
import { Button, Icon } from 'atp-react-ui';
import { Link } from 'react-router-dom';

import type { RouteRecordsType } from '@/_navigation';
import { ROUTES } from '@/_navigation';

export type BreadcrumbPage = RouteRecordsType & {
  state?: Record<string, unknown>;
};

export default function BreadCrumbs({ pages }: { pages: BreadcrumbPage[] }) {
  return (
    <nav className="flex px-px" aria-label="Breadcrumb">
      <ol className="flex flex-wrap items-center gap-2">
        <li>
          <div>
            <Link to={ROUTES.IN_PROGRESS.path}>
              <Button size="xs" className="px-2" iconClassName="mx-0" as="link" icon={HomeIcon}>
                <span className="sr-only">Home</span>
              </Button>
            </Link>
          </div>
        </li>

        {pages.map((page, index) => {
          const isCurrent = index === pages.length - 1;
          return (
            <li key={page.META.name}>
              <div className="flex items-center">
                <Icon icon={ChevronRightIcon} className="text-accent-x-light" />
                {isCurrent ? (
                  <Button
                    size="xs"
                    as="link"
                    className={isCurrent ? 'italic' : undefined}
                    aria-current={isCurrent ? 'page' : undefined}
                  >
                    {page.META.name}
                  </Button>
                ) : (
                  <Link to={page.path} state={page.buildState((page.state || {}) as Record<string, never>)}>
                    <Button
                      size="xs"
                      as="link"
                      className={isCurrent ? 'italic' : undefined}
                      aria-current={isCurrent ? 'page' : undefined}
                    >
                      {page.META.name}
                    </Button>
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
