import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { STUDENT_STATUS } from '@/_config';
import type { ACCOUNT_IDENTITY } from '@/_services';
import { queryClient, apiClient } from '@/_services';

import type { RESTRICTIONS_TYPE } from '../../../_shared/policies';

import { transformMeApiResponse } from './helpers';

const PaymentSummarySchema = z.object({
  has_subscription: z.boolean(),
  outstanding_open_invoice_list: z.array(z.unknown()),
  paused_payment: z.boolean(),
});

export type PaymentSummary = z.infer<typeof PaymentSummarySchema>;

const PlanSummarySchema = z.object({
  student_status: z.nativeEnum(STUDENT_STATUS),
  has_plan: z.boolean(),
  atp_pilot: z.boolean(),
  unified_customers: z.boolean(),
  charge_over_id: z.string(),
  enrollment_end_date: z.string(),
  extension_ea_date: z.string().nullable(),
  payment_type: z.string(),
  stripe_payment_term_pc: z.string().nullable(),
  stripe_number_of_courses_pc: z.number().nullable(),
  gtm_partnership: z.string().nullable(),
});

export type PlanSummary = z.infer<typeof PlanSummarySchema>;

export const MailingAddressSchema = z.object({
  mailing_street: z.string().nullable().optional(),
  mailing_city: z.string().nullable().optional(),
  mailing_state: z.string().nullable().optional(),
  mailing_postal_code: z.string().nullable().optional(),
  mailing_country: z.string().nullable().optional(),
  mailing_latitude: z.string().nullable().optional(),
  mailing_longitude: z.string().nullable().optional(),
  mailing_geocode_accuracy: z.number().nullable().optional(),
});

export type MailingAddress = z.infer<typeof MailingAddressSchema>;

export const MeApiResponseSchema = z.object({
  id: z.string(),
  account: z.string(),
  email: z.string(),
  username: z.string(),
  last_name: z.string(),
  first_name: z.string(),
  name: z.string(),
  title: z.string().nullable(),
  mobile_phone: z.string().nullable(),
  mailing_street: z.string().nullable(),
  mailing_city: z.string().nullable(),
  mailing_state: z.string().nullable(),
  mailing_postal_code: z.string().nullable(),
  mailing_country: z.string().nullable(),
  mailing_latitude: z.string().nullable(),
  mailing_longitude: z.string().nullable(),
  mailing_geocode_accuracy: z.number().nullable(),
  mailing_address: z.string().nullable(),
  prep_course_plan_url: z.string().nullable(),
  payment_summary: PaymentSummarySchema,
  plan_summary: PlanSummarySchema,
  delinquent_date: z.string().nullable(),
  created_date: z.number(),
  why_bridge_completed: z.boolean(),
  total_registered_count: z.number(),
  running_registered_course_count: z.number(),
  image: z.string().nullable(),
  has_ssn: z.boolean(),
  ssn: z.string().nullable(),
  preferred_contact_method_master: z.string(),
  allow_rewards: z.boolean(),
  hardship_delinquent_date: z.string().nullable(),
  payment_status2: z.string(),
  preferred_contact_frequency: z.string().nullable(),
  preferred_day_and_time_to_contact: z.string().nullable(),
  wellcoaches_agreement_c: z.boolean(),
  wellcoaches_agreement_completed_c: z.boolean(),
});

export type MeApiResponse = z.infer<typeof MeApiResponseSchema>;
export type MeApiTransformedResponse = MeApiResponse & {
  accessType: RESTRICTIONS_TYPE;
  localAccountType: ACCOUNT_IDENTITY;
  isStudent: boolean;
  isInstructor: boolean;
};

export const ME_QUERY_KEY = ['ME'];

export const useMeQuery = () =>
  useSuspenseQuery<MeApiResponse, unknown, MeApiTransformedResponse>({
    queryKey: ME_QUERY_KEY,
    queryFn: async () =>
      apiClient.request({
        endpoint: '/me/',
        responseSchema: MeApiResponseSchema,
      }),
    select: transformMeApiResponse,
  });

export const invalidateMeQuery = () => queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
