import defaultPic from '@/_shared/img/default-course.png';

const courseImages = [
  'american_government',
  'art_of_the_western_world',
  'career_guidance',
  'chemistry',
  'college_algebra',
  'college_composition',
  'college_composition_with_modular',
  'college_mathematics',
  'comprehensive_nursing_prep',
  'dental_entrance_exam_preparation',
  'developmental_psychology',
  'ethics_in_america',
  'financial_accounting',
  'fundamentals_of_college_algebra',
  'fundamentals_of_english',
  'fundamentals_of_math',
  'hesi_rn_exit_prep',
  'history_of_the_united_states_1',
  'human_resource_management',
  'humanities',
  'introduction_to_psychology',
  'macroeconomics',
  'microeconomics',
  'national_board_of_dental_hygienist_examination',
  'nclex_lpn_preparation',
  'nclex_rn_preparation',
  'nursing_entrance_exam_preparation',
  'organizational_behavior',
  'principles_of_marketing',
  'sociology',
  'spanish_1',
  'spanish_2',
  'speech',
  'statistics',
  'supervision_and_management',
  'tutoring_anatomy_physiology_1',
  'tutoring_anatomy_physiology_2',
  'tutoring_chemistry',
  'tutoring_dental',
  'tutoring_dental_hygiene',
  'tutoring_dental_materials',
  'tutoring_dental_radiology',
  'tutoring_essentials_of_nursing_care_health_differences',
  'tutoring_essentials_of_nursing_care_health_safety',
  'tutoring_foundations_in_nursing_practice',
  'tutoring_general_and_oral_pathology',
  'tutoring_health_differences_across_the_life_span_1',
  'tutoring_health_differences_across_the_life_span_2',
  'tutoring_health_differences_across_the_life_span_3',
  'tutoring_microbiology',
  'tutoring_nursing',
  'tutoring_oral_a_p',
  'tutoring_oral_microbiology',
  'tutoring_pathophysiology',
  'tutoring_periodontology',
  'tutoring_reproductive_health',
  'tutoring_statistics',
  'tutoring_transition_to_the_professional_nursing_role',
  'tutoring_bsn_comprehensive_health_assessment',
  'tutoring_bsn_leadership_and_management_in_nursing',
  'tutoring_bsn_nursing_research',
  'tutoring_rn_concepts_of_nursing_practice',
  'tutoring_rn_health_assessment',
  'tutoring_rn_nursing_pharmacology',
  'tutoring_rn_transition_foundations_of_nursing_practice',
  'world_religions',
];

const courseNameConverter = (name: string) =>
  name
    .toLowerCase()
    .split(' ')
    .join('_')
    .split('&')
    .join('_')
    .split('/')
    .join('_')
    .split(')')
    .join('')
    .split('(')
    .join('')
    .split('-')
    .join('_')
    .split('___')
    .join('_')
    .split('__')
    .join('_');

export const getCourseImage = (courseName: string, useDefault = false) => {
  const courseImage = courseImages.find((image) => image === courseNameConverter(courseName));

  if (!courseImage && useDefault) {
    return defaultPic;
  }
  return `/images/courses/${courseImage || 'default-course'}.jpg`;
};
