import type { ApiErrorResponse } from './types';

export class ApiError extends Error {
  public data: ApiErrorResponse | null;

  constructor(message: string, apiErrorResponse: ApiErrorResponse | null) {
    super(message);
    this.data = apiErrorResponse;
  }
}
