import React from 'react';

import { useCurrentSearchParams } from './use-current-search-params';

export const useRedirection = (redirectTo: string) => {
  const { strParams } = useCurrentSearchParams();

  React.useEffect(() => {
    const redirectUrl = `${redirectTo}${strParams.length ? `?${strParams}` : ''}`;

    window.location.href = redirectUrl;
  }, [redirectTo, strParams]);
};
