import { Skeleton } from 'atp-react-ui';

export function Preloader() {
  return (
    <div className="flex flex-col gap-y-4 self-center md:w-3/4">
      <Skeleton className="h-8" />
      <Skeleton className="h-8" />
      <Skeleton className="h-8" />
      <Skeleton className="h-8" />
      <Skeleton className="h-8" />
      <Skeleton className="h-8 w-3/4" />
    </div>
  );
}
