import { useEffect, useMemo, useState } from 'react';

import { useBoolean } from 'atp-react-ui';

import { useAuth, useMeContext, useMeMutation } from '@/_providers';

export const useWellcoachesAgreementModal = () => {
  const [showModal, modal] = useBoolean();
  const [error, setError] = useState('');

  const { data: meData, isLoadingMeData } = useMeContext();

  const actualFullName = useMemo(() => {
    if (!meData) {
      return '';
    }
    return `${meData.first_name} ${meData.last_name}`;
  }, [meData]);

  const [fullname, setFullName] = useState('');

  const { logout } = useAuth();

  const [updateWellcoachesAgreement, { isLoading }] = useMeMutation<{
    wellcoaches_agreement_completed_c: boolean;
  }>({
    method: 'PATCH',
  });

  const handlePositive = async () => {
    if (fullname.length === 0) {
      setError('Please enter your name');
      return;
    }
    try {
      await updateWellcoachesAgreement({
        wellcoaches_agreement_completed_c: true,
      });
      modal.setFalse();
    } catch (error) {
      console.error('Error updating wellcoaches agreement', error);
    }
  };

  const handleNegative = () => {
    logout();
  };

  const handleNameChange = (name: string) => {
    setFullName(name);
    setError('');
  };

  useEffect(() => {
    if (meData && meData.wellcoaches_agreement_c && !meData.wellcoaches_agreement_completed_c) {
      modal.setTrue();
    }
  }, [isLoadingMeData, modal, meData]);

  return {
    actualFullName,
    fullname,
    handleNameChange,
    showModal,
    toggleModal: modal.toggle,
    handlePositive,
    handleNegative,
    isLoading,
    error,
  };
};
