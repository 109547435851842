import { lazy, Suspense } from 'react';

import { ErrorBoundary } from '@/_components';

import Layout from './layout';
import Loading from './loading';

const Page = lazy(() => import('./page'));

export function BillingHistory() {
  return (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Page />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
}
