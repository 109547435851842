import { Button, Text, useBoolean } from 'atp-react-ui';

import { Container, PageHead, StickyPageHeader } from '@/_components';
import { useAccountDependenciesContext } from '@/_providers';

import { TermsModal } from './terms-modal';

export function PageHeader() {
  const { isRewardProgramActive } = useAccountDependenciesContext();
  const [isOpenTerms, openTerms] = useBoolean();

  return (
    <>
      <TermsModal open={isOpenTerms} onClose={openTerms.setFalse} />

      <StickyPageHeader className="top-12">
        <Container>
          <PageHead className="flex flex-col items-start gap-4 md:flex-row md:items-end">
            <section className="mt-2 flex flex-col">
              <Text as="h1">Rewards</Text>
              <Text as="caption-medium" className="pt-3 text-accent-light/80">
                Complete tasks, earn points, and get rewards
              </Text>
            </section>

            <section className="flex w-full items-center gap-2 md:w-auto">
              {isRewardProgramActive && (
                <Button as="outline" className="md:flex-0 flex-1" onClick={openTerms.setTrue}>
                  Terms & Conditions
                </Button>
              )}
            </section>
          </PageHead>
        </Container>
      </StickyPageHeader>
    </>
  );
}
