import { Card, CardBody, Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <Skeleton className="h-5 w-44" />

          <section className="mt-2 flex items-center">
            <Skeleton className="mr-4 size-8" />
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center gap-2">
              <Skeleton className="size-5" />
              <Skeleton className="h-5 w-28" />
            </div>
            <div className="flex items-center gap-2">
              <Skeleton className="size-5" />
              <Skeleton className="h-5 w-28" />
            </div>
            <div className="flex items-center gap-2">
              <Skeleton className="size-5" />
              <Skeleton className="h-5 w-28" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6">
        <article className="grid grid-cols-12 gap-4">
          <section className="col-span-12 md:col-span-7 lg:col-span-8">
            <Card aria-labelledby="course-information-title" className="border border-outline">
              <CardBody className="flex h-full flex-1 flex-col gap-2 px-6 py-10 sm:px-10">
                <div className="flex items-center gap-2">
                  <Skeleton className="size-5" />
                  <Skeleton className="h-5 w-28" />
                </div>
                <Skeleton className="mt-4 h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-full" />
                <Skeleton className="h-5 w-2/3" />
              </CardBody>
            </Card>
          </section>
          <section className="col-span-12 gap-4 md:col-span-5 lg:col-span-4">
            <div>
              <Card aria-labelledby="course-information-title" className="border border-outline">
                <CardBody className="flex h-full flex-1 flex-col justify-between gap-2 px-6 py-10 sm:px-10">
                  <div className="flex items-center gap-2">
                    <Skeleton className="size-5" />
                    <Skeleton className="h-5 w-28" />
                  </div>
                  <div className="mt-4 flex flex-col gap-2">
                    <Skeleton className="h-12 w-full" />
                    <Skeleton className="h-12 w-full" />
                    <Skeleton className="h-12 w-full" />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="mt-4">
              <Card aria-labelledby="course-information-title" className="border border-outline">
                <CardBody className="flex h-full flex-1 flex-col gap-2 px-6 py-10 sm:px-10">
                  <div className="flex items-center gap-2">
                    <Skeleton className="size-5" />
                    <Skeleton className="h-5 w-28" />
                  </div>
                  <Skeleton className="mt-4 h-5 w-full" />
                  <Skeleton className="h-5 w-full" />
                  <Skeleton className="h-5 w-full" />
                  <Skeleton className="h-5 w-full" />
                  <Skeleton className="h-5 w-full" />
                  <Skeleton className="h-5 w-full" />
                  <Skeleton className="h-5 w-2/3" />
                </CardBody>
              </Card>
            </div>
          </section>
        </article>
      </Container>
    </>
  );
}
