import * as React from 'react';

import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { twMerge } from 'tailwind-merge';

export type AvatarBaseProps = React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>;
export type AvatarBaseRef = React.ElementRef<typeof AvatarPrimitive.Root>;

const AvatarBase = React.forwardRef<AvatarBaseRef, AvatarBaseProps>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={twMerge('relative flex size-10 shrink-0 overflow-hidden rounded-full', className)}
    {...props}
  />
));
AvatarBase.displayName = AvatarPrimitive.Root.displayName;

export { AvatarBase };
