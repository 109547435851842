import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { findRouteByURL } from '../helper';
import type { RouteRecordsType } from '../routes';

const initialValue = {
  location: {} as Location,
  data: {} as RouteRecordsType,
};

export type TLocationDetails = typeof initialValue;

export function useLocationDetails() {
  const location = useLocation();
  const [data, setData] = useState(initialValue);

  useEffect(() => {
    setData({ location: location as unknown as Location, data: findRouteByURL(location.pathname) });
  }, [location]);

  return data;
}
