import { Skeleton } from 'atp-react-ui';

import { ProgressSkeleton } from '@/_components';

export default function Loader() {
  return (
    <section className="flex w-full flex-col gap-y-2 pre-xl:w-3/4">
      <div className="mb-4 flex gap-2">
        <Skeleton className="h-6 w-16" />
        <Skeleton className="h-6 w-32" />
        <Skeleton className="h-6 w-32" />
        <Skeleton className="h-6 w-32" />
        <Skeleton className="h-6 w-16" />
        <Skeleton className="h-6 w-32" />
        <Skeleton className="h-6 w-32" />
      </div>
      <ProgressSkeleton />
      <ProgressSkeleton />
      <ProgressSkeleton />
      <ProgressSkeleton />
    </section>
  );
}
