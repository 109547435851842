import { useEffect } from 'react';

import { useBoolean } from 'atp-react-ui';
import { useLocation } from 'react-router-dom';

import { useAccountDependenciesContext, useMeContext } from '@/_providers';
import { dateTime } from '@/_shared';

import { ACH_CAMPAIGN_END_DATE } from './constants';

export const useAchCampaignModal = () => {
  const { state } = useLocation();

  const [showAchModal, achModal] = useBoolean();

  const { data: accountDepData, isLoading: isLoadingAccountDep } = useAccountDependenciesContext();
  const { data: meData, isLoadingMeData } = useMeContext();

  useEffect(() => {
    if (
      meData &&
      meData.why_bridge_completed &&
      (state?.from === 'WHY_BRIDGE' || state?.from === 'LOGIN') &&
      !isLoadingAccountDep &&
      !isLoadingMeData &&
      accountDepData &&
      accountDepData.ach_campaign.eligible_for_ach_campaign &&
      !accountDepData.ach_campaign.is_default &&
      (dateTime.isSame(dateTime.now(), ACH_CAMPAIGN_END_DATE, 'date') ||
        dateTime.isBefore(dateTime.now(), ACH_CAMPAIGN_END_DATE, 'date'))
    ) {
      achModal.setTrue();
    }
  }, [isLoadingAccountDep, isLoadingMeData, achModal, accountDepData, meData, state]);

  return { showAchModal, toggleAchModal: achModal.toggle };
};
