import { createContext, useContext } from 'react';

import { noop } from '@/_shared';

import type { WalkthroughContextType } from './types';

export const WalkthroughContext = createContext<WalkthroughContextType>({
  walkthroughs: {},
  setWalkthroughViewed: () => noop,
  startWalkthrough: noop,
});

export const useWalkthroughContext = () => useContext(WalkthroughContext);
