import { Card, CardBody, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'atp-react-ui';

const borderTable = ''; // 'border-t-2 border-0 border-solid border-divider';

export function RewardsTerms() {
  return (
    <>
      <section>
        <Text as="subtitle" className="text-accent">
          General
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          1.1 OVERVIEW
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          The ACHIEVE TEST PREP LOYALTY REWARDS PROGRAM (the “Program”) is a loyalty reward program offered by ACHIEVE
          TEST PREP (the “Company”, “us”, “our” or “we”) to certain customers of the Company. The Program is accessible
          through{' '}
          <a
            className="text-primary underline decoration-primary-medium hover:decoration-primary"
            target="_blank"
            href="https://prep.achievetestprep.com/"
            rel="noreferrer"
          >
            https://prep.achievetestprep.com
          </a>{' '}
          (the “Program Website”) and any other related sites and applications referred to on the Program Website.
          <br />
          <br />
          The Program allows persons who have completed the Loyalty program registration steps (each, a “Program Member”
          or “Member”) in accordance with the present Terms and Conditions to collect points when carrying out
          activities stipulated and making purchases on items identified from time to time by the Company (“Eligible
          Purchases”), and to redeem these points for rewards, benefits and/or rebates offered by the Company from time
          to time.
          <br />
          <br />
          Members acquire no vested right or entitlement to the continued availability of any particular reward, benefit
          or redemption level.
        </Text>

        <Text as="caption-medium-bold" className="mt-6 text-accent">
          1.2 TERMS AND CONDITIONS
        </Text>

        <Text as="caption-medium" className="mt-2 text-accent-light">
          The terms and conditions set forth herein (the “Terms & Conditions”, and together with any terms set forth on
          the Program Website, the Explainer Page and any other terms accessible through any of the foregoing,
          collectively the “Program Terms”) govern the Program. The Company in its sole discretion can, restrict,
          suspend, amend, extend or otherwise alter the Program Terms at any time and without prior notice. If the
          Program Terms have been updated, the Company will post the new Program Terms on the Program Website and note
          the date that they were last updated, and no change will be effective until such change has been posted for at
          least ten (10) days. We encourage Members to review the Program Terms each time they use the Program.
        </Text>

        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Achieve Test Prep reserves the right to update its Terms and/or refuse the Loyalty Program to anyone at
              their discretion.
            </Text>
          </CardBody>
        </Card>

        <Text as="caption-medium-bold" className="mt-6 text-accent">
          1.3 ACCEPTANCE
        </Text>

        <Text as="caption-medium" className="mt-2 text-accent-light">
          By registering for and agreeing to the terms of use of the reward program, you accept and agree to be bound by
          these Terms and Conditions.
        </Text>

        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Welcome. Thank you for complying with our house rules.
            </Text>
          </CardBody>
        </Card>
      </section>

      <section className="mt-8">
        <Text as="subtitle" className="mt-8 text-accent">
          PROGRAM MEMBERSHIP
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.1 MEMBERSHIP REGISTRATION
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Membership is free and no initial purchase is required in order to become a Member. To become a valid Member,
          you must complete the registration process by providing complete and accurate information and indicating your
          acceptance of the Program Terms.
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.2 PASSWORDS AND SECURITY
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          In order to register in the Program, you will have to register and create an Account with us and provide one
          or more names, email addresses, login usernames and passwords (together, “ID”). You are responsible for
          maintaining the confidentiality of your ID. You agree not to use the ID of any third party or disclose your ID
          to any third party. You are responsible for any and all activity that occurs on your Account. If you suspect
          any unauthorized use of your Account, you must notify us immediately by sending an email to{' '}
          <a
            className="text-primary underline decoration-primary-medium hover:decoration-primary"
            href="mailto:customersuccess@achieve-test-prep.com"
          >
            customersuccess@achieve-test-prep.com
          </a>
          . You agree to provide us with correct and complete Account information at all times and inform us of any
          changes to the information you have provided. We will keep your ID confidential. Only one ACHIEVE TEST PREP
          LOYALTY REWARDS PROGRAM rewards account (“Account”) is permitted per person.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Please be responsible and honest, and keep your ID safe.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.3 SUSPENSION RIGHTS; CAPACITY
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          If the Company determines that a Program Member has abused any of the Program’s privileges, fails to comply
          with any of the Program Terms, or makes any misrepresentation to the Company the Company may, in its sole
          discretion, take such actions as it deems appropriate, including without limitation, suspending such Member’s
          privileges under the Program, revoking any or all of the points in such Program Member’s Account, and/or
          revoking the Program Member’s membership in the Program, in each case, with or without advance notice to the
          Member and without liability to the Company.
          <br />
          <br />
          Points will not be awarded if in the Company’s reasonable opinion the product purchased will be used for
          resale or commercial use and any points awarded on such purchases will be forfeited. If a Member was awarded
          points for an offer or promotion in which a Member purchased product(s) in excess of reasonable quantities,
          the points awarded as a result of that offer or promotion may be forfeited without prior notice and the
          Account may be suspended or closed.
          <br />
          <br />
          Unless further restricted elsewhere in the Program Terms, Membership is only available to all Achieve Test
          Prep customers. If a Member does not meet the capacity requirements set out above, all points awarded to such
          Member may be forfeited without prior notice and the Account may be suspended or closed.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              No abuse of usage of any kind will be tolerated.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.4 CHANGE IN INFORMATION
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Program Members are responsible to advise the Company immediately of any change in contact information. The
          Company will not be responsible for any communication not received by a Program Member, provided that the
          Company sent such communication to the other contact information then on file from such Program Member.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Let’s keep connected! Keep us informed!
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.5 INACTIVE ACCOUNTS
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          A Program Member Account is deemed to be inactive if points are not earned in connection with such an Account
          for 5 years. Once an Account is deemed to be inactive, all points in such Account may, in the Company’s sole
          discretion, be deemed to have been forfeited by such Member, with or without notice.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              If your account goes inactive, you might lose all points accumulated
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.6 CANCELLATION AND WITHDRAWAL
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Points are reduced to zero upon withdrawal from ACHIEVE TEST PREP, cancellation of subscription or closure of
          loyalty Membership.
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.6.a CANCELLATION AND WITHDRAWAL WITH AN OUTSTANDING BALANCE
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Any Credit from existing redeemed points are reduced to zero upon withdrawal from ACHIEVE TEST PREP,
          cancellation of subscription or closure of the loyalty Membership,if a member has an outstanding balance.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              If you cancel your points will be lost.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          2.7 SECURITY
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          If a Membership account is used by any other person, all points relating to purchases made by such other
          person will be credited to the Member’s Account. The Company assumes no liability or responsibility for points
          redeemed by any person(s) prior to a notification to the Company’s Customer Service Center that the Membership
          has been compromised. Any points redeemed prior to notification shall be at the Member’s risk.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Treat your information as confidential as we assume no liability for misuse.
            </Text>
          </CardBody>
        </Card>
      </section>

      <section className="mt-8">
        <Text as="subtitle" className="mt-8 text-accent">
          REWARD POINTS
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          3.1 COLLECTION/REDEMPTION
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Points can be earned by Program Members in connection with purchases of goods and/or services made through the
          Company or interactions as set forth on the Explainer Page, regardless of method of payment, provided that the
          Membership is presented/entered at the time of purchase. <br />
          <br />
          Points can be redeemed for rewards. When enough points have been accumulated to obtain the reward(s) desired,
          redemption may occur as set forth on the Program website or Explainer Page. The redemption schedule, available
          rewards and other information is available on the Explainer Page. <br />
          <br />A Member must be logged on the prep portal when redeeming points to protect the integrity of the
          Member’s points balance. Each Member is responsible to ensure that all Personal Information is correct and
          up-to-date and the Company reserves the right to block redemptions where the Member information is inaccurate
          or incomplete. Members are solely and entirely responsible for keeping their Account secure.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              More Points = More rewards!
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          3.2 VALUE
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Points have no cash value and are not exchangeable for cash. The accumulation of points does not entitle the
          Program Members to any vested rights, and the Company does not guarantee in any way the continued availability
          of any reward, redemption level, rebate or any other benefit. The Company assumes no liability to Members as
          regards the addition or deletion of items from or for which points can be collected and/or redeemed.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              All rewards and benefits under our Loyalty Program are offered at the sole discretion of Management.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          3.3 TRANSFERABILITY
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Except as permitted from time to time by the Company, points cannot be assigned, exchanged, traded, bartered,
          purchased or given by gift or otherwise sold. Any points so acquired are void. For the avoidance of doubt,
          such prohibited transfers include transfers upon operation or law upon the death of a Member.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Sorry, no point swaps, combination of rewards, sales, and/or gift cards. (Unless we say so).
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          3.4 RETURN/EXCHANGE POLICY
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          In the event of a return/exchange of any eligible purchase that initially earned points, such points will be
          deducted automatically from the Account that was used for the eligible purchase (whether or not the Member was
          logged at the time of return and/or exchange). Points will be deducted at the same rate as they were earned.
          <br />
          <br />
          Upon the return and/or exchange of products awarded through the redemption of points, all redeemed points will
          be forfeited.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              A return may result in reduction in points earned at Management’s discretion.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          3.5 PROMOTION
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          From time to time, the Company may advertise or offer exclusive offers to select Members to redeem points for
          items other than a discount reward, or receive other benefits or discounts.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Keep an eye out for our exciting offers!
            </Text>
          </CardBody>
        </Card>
      </section>

      <section className="mt-8">
        <Text as="subtitle" className="mb-4 mt-8 text-accent">
          REFERRAL PROGRAM
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          As a “Referrer”, you are subject to Achieve Test Prep (ATP) Terms of Use and ATP’s Privacy Policy (both
          available on our website and stated in the Terms and conditions), as well as the following additional Terms &
          Conditions for ATP’s Loyalty referral program
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          4.1 QUALIFIED REFERRAL
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          A Qualified Referral is defined as a purchase made at Achievetestprep.com by a person (a &quot;Referred
          Customer&quot;) who arrives to our website by clicking your Loyalty referral program link. You are limited to
          one Qualified Referral for each Referred Customer; in other words, additional/repeat purchases made by a
          Referred Customer are not counted as additional Qualified Referrals.
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          4.2 REFERRED CUSTOMER
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Referred Customer. The Referred Customer and the Referrer cannot be the same person (for example, by using a
          different email address) The Referred Customer must be a new customer to Achieve Test Prep who has not made
          any previous purchase in the past.
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          4.3 REWARD
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Reward for the Loyalty referral program is disbursed in installments
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Table aria-label="tos table">
              <TableHeader>
                <TableRow>
                  <TableCell>
                    <Text as="caption-medium-bold">Stages of points disbursement </Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text as="caption-medium-bold">Points</Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text as="caption-medium-bold">Value ($)</Text>
                  </TableCell>
                </TableRow>
              </TableHeader>

              <TableBody>
                <TableRow key="1">
                  <TableCell className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      After referees Initial payment
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      4000
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      200
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow key="2">
                  <TableCell className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      After first full month referees is with achieve
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      4000
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      200
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow key="3">
                  <TableCell className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      Second month referee is active with achieve
                    </Text>{' '}
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      4000
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      200
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow key="4">
                  <TableCell className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      Third month referee is active with achieve
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      4000
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      200
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow key="5">
                  <TableCell className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      After referee submits result
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      4000
                    </Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium" className="leading-snug text-accent-light">
                      200
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow key="6">
                  <TableCell className={borderTable}>
                    <Text as="caption-medium-bold">Total</Text>
                  </TableCell>
                  <TableCell align="center" className={borderTable} />
                  <TableCell align="center" className={borderTable}>
                    <Text as="caption-medium-bold">$1000</Text>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          4.4 MAXIMUM QUALIFIED REFERRAL ALLOWED
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          There is no cap on the number qualified referral allowed
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          4.5 OTHER CONDITIONS
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          <ul className="flex list-disc flex-col gap-y-2 pl-6">
            <li>
              Eligibility. Eligibility is limited to individuals only. ATP’s Loyalty Referral Program cannot be used by
              businesses for affiliate lead generation as determined in ATP’s sole discretion.
            </li>
            <li>
              No Spam. You must comply with all up-to-date “SPAM” laws. For example, emails must be created and
              distributed in a personal manner and bulk email distribution is strongly discouraged. Any distribution of
              your referral link that could constitute unsolicited commercial email or &quot;spam&quot; under any
              applicable law or regulation is expressly prohibited and will be grounds for immediate termination of your
              account and exclusion from ATP’s Loyalty program.
            </li>
            <li>
              Right to Close Accounts. ATP reserves the right to close the account(s) of any Referrer and/or Referred
              Customer and to request proper payment if the Referrer and/or Referred Customer attempts to use the ATP
              Loyalty Referral Program in a questionable manner or breaches any of these Terms & Conditions or is in
              violation of any law, statute or governmental regulation.
            </li>
            <li>
              Right to Cancel Program or Change Terms. Achieve Test Prep reserves the right to cancel the Loyalty
              referral Program or to change these Terms & Conditions at any time in its sole discretion. Any unclaimed
              referral rewards will be forfeited at that time.
            </li>
          </ul>
        </Text>
      </section>

      <section className="mt-8">
        <Text as="subtitle" className="mt-8 text-accent">
          PRIVACY
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          5.1 CONFIDENTIAL INFORMATION
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          We are committed to protecting your privacy. We will maintain the privacy and security of all personal
          information collected from Members, including, but not limited to: name, address, email address, telephone
          numbers, date of birth, account number and purchasing information (“Personal Information”).
          <br />
          <br />
          You understand that through your use of the Program, you consent to the collection and use of this
          information. As part of providing you the Program, we may need to provide you with certain communications,
          such as service announcements and administrative messages. These communications are considered part of the
          Program and your Account.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Your info is safe with us.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          5.2 UNSUBSCRIBE
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          By sharing your email address and as an active customer and Program Member, we and/or our service providers
          may communicate to you special offers, services, promotions and program information. You can unsubscribe from
          receiving such messages at any time by updating your online account, by contacting customer service of the
          applicable entity, or by clicking unsubscribe at the bottom of any promotional email.
        </Text>
      </section>

      <section className="mt-8">
        <Text as="subtitle" className="mt-8 text-accent">
          GENERAL
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          6.1 ACCEPTANCE
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          By registering in the Program, each Member (i) certifies that he or she is over the age of majority in the
          province in which he or she resides (or is supervised by a parent or legal guardian who agrees to the Program
          Terms) and of mental capacity (or is supervised by a legal guardian who agrees to the Program Terms), (ii)
          consents to the company’s collection and use of certain personal information, except as he or she may
          otherwise notify the Company and (iii) agrees to the Program Terms, as they may be amended from time to time.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Registration means you agree to these Terms.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          6.2 OTHER TERMS
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          These Terms & Conditions are in addition to any other Program Terms, and shall be read together with such
          other Program Terms as one and the same instrument; provided, that if there is a conflict between any terms
          set forth in these terms and conditions, the Program Website, the Explainer Page and/or any other Program
          Terms, such conflict shall be resolved as follows: first, the Explainer Page shall prevail, thereafter these
          Terms & Conditions, thereafter the Program Website and finally any other Program Terms. For the avoidance of
          doubt, in the event of any action, proceeding or other dispute arising from or relating to any Program Terms,
          such dispute shall be settled in accordance with the provisions and procedures set forth in these Terms &
          Conditions.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              Our Hierarchy of Reference is as follows:
              <ol className="mt-4 flex list-disc flex-col gap-y-2 pl-6">
                <li>Explainer Page</li>
                <li>Terms & Conditions</li>
                <li>Program Website </li>
                <li>Program Terms</li>
              </ol>
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          6.3 ABUSE
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Any abuse by a Member of the Program’s privileges, any failure by a Member to follow these Terms and
          Conditions, or any misrepresentation by a Member may subject the Member to expulsion from the Program,
          including forfeiture of all accumulated points.
        </Text>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          6.4 WAIVER
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          Every effort has been made to ensure that the information herein is correct. The Company is not responsible
          for any errors or omissions in printed copies of these Terms and Conditions nor those published from time to
          time on its website.
          <br />
          <br />
          Any waiver by the Company of the strict observance, performance or compliance by a Member with any of the
          Terms and Conditions contained herein, either expressly granted or by course of conduct, shall be effective
          only in the specific instance and shall not be deemed to be a waiver of any rights or remedies of the Company
          as a result of any other failure to observe, perform or comply with the Terms and Conditions. No delay or
          omission by the Company in exercising any right or remedy hereunder shall operate as waiver thereof or of any
          other right or remedy.
          <br />
          <br />
          In the event that any provision in these Terms and Conditions is determined to be invalid, illegal, or
          unenforceable, such determination shall not affect the validity and enforceability of any other remaining
          provisions of these Terms and Conditions.
        </Text>
        <Card className="mt-6">
          <CardBody>
            <Text as="caption-medium-bold">In Summary</Text>
            <Text as="caption-medium" className="mt-2 text-accent-light">
              The Company is not liable for any errors or omissions in printed copies of these Terms and Conditions, as
              well as those posted on its website from time to time.
            </Text>
          </CardBody>
        </Card>
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          6.5 CONTACT
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          If you have any questions about these Terms and Conditions or if you wish to provide any feedback with respect
          to the Program, please contact us at:{' '}
          <a
            className="text-primary underline decoration-primary-medium hover:decoration-primary"
            href="mailto:customersuccess@achieve-test-prep.com"
          >
            customersuccess@achieve-test-prep.com
          </a>
          .
        </Text>
      </section>

      <section className="mt-8">
        <Text as="caption-medium-bold" className="mt-6 text-accent">
          7.0 DISPUTE RESOLUTION
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          By registering for this loyalty program
        </Text>
        <Text as="caption-medium" className="mt-4 leading-snug text-accent-light">
          <ol className="flex list-decimal flex-col gap-y-2 pl-6">
            <li>
              Your membership and all matters related thereto, including this Agreement are governed by Missouri law
            </li>
            <li>
              Any and all claims or disputes which arise between you and us, of any kind and over any matter, will be
              resolved through binding arbitration administered by the Global Arbitration Solutions arbitration company
              under and in accordance with its rules then in effect (www.globalarbitrationsolutions.com) and any action
              seeking to confirm, challenge, or enforce an award of the arbitrator(s) in the event of an arbitration
              (and any action if arbitration is for any reason not required despite your agreement to arbitrate), shall
              be filed and maintained in the Circuit Court for St. Louis County, State of Missouri, which will have
              exclusive jurisdiction over these matters, that you hereby submit to the jurisdiction of that Court and
              agree that you won’t ask to transfer or remove the case to any other state, federal or other court, and
            </li>
            <li>
              Any dispute resolution proceedings, whether in arbitration or in court, will be conducted on an individual
              basis only and not as a class or representative action or as a named or unnamed member in a class,
              consolidated, representative or private attorney general action.
            </li>
          </ol>
        </Text>
      </section>
    </>
  );
}
