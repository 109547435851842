import { dateTime } from '@/_shared';

import type { COURSE_COMPLETION_STATUS } from '../api/shared';

const now = dateTime.now();
const todayDay = now.format('dddd');
const afterOneWeek = now.add(1, 'week');

const unregCourse = {
  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
  aliquip ex ea commodo consequat.
  <br />
  <br />
  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
  dolore magna aliqua.`,

  duration: 8,
  credits: 3,
  examBoard: 'NCLEX',
};

export const unregCourses = [
  {
    id: 'xxxxx01',
    order: '1',
    name: 'Anatomy & Phisiology I',
    day: todayDay,
    time: '10:30PM',
    date: afterOneWeek.format('MM/DD/YYYY'),
    subTitle: 'PREP COURSE',
    imageUrl: '/images/courses/1.png',
    ...unregCourse,
  },
  {
    id: 'xxxxx02',
    order: '2',
    name: 'Chemistry',
    day: 'Saturday',
    time: '10:30PM',
    date: afterOneWeek.format('MM/DD/YYYY'),
    subTitle: 'PREP COURSE',
    imageUrl: '/images/courses/2.png',
    ...unregCourse,
  },
  {
    id: 'xxxxx03',
    order: '3',
    name: 'History of United States of America',
    day: 'Saturday',
    time: '10:30PM',
    date: afterOneWeek.format('MM/DD/YYYY'),
    subTitle: 'PREP COURSE',
    imageUrl: '/images/courses/8.png',
    ...unregCourse,
  },
  {
    id: 'xxxxx4',
    order: '4',
    name: 'Introduction to Psychology',
    day: 'Saturday',
    time: '10:30PM',
    date: afterOneWeek.format('MM/DD/YYYY'),
    subTitle: 'PREP COURSE',
    imageUrl: '/images/courses/4.png',
    ...unregCourse,
  },
];

export const unregOdtCourses = [
  {
    id: '1',
    name: 'Tutoring Statistics',
    subTitle: 'ON-DEMAND TUTORING',
    imageUrl: '/images/courses/1.png',
  },
  {
    id: '2',
    name: 'Tutoring Nursing',
    subTitle: 'ON-DEMAND TUTORING',
    imageUrl: '/images/courses/2.png',
  },
  {
    id: '3',
    name: 'Tutoring Microbiology',
    subTitle: 'ON-DEMAND TUTORING',
    imageUrl: '/images/courses/8.png',
  },
];

export const completedCourses = [
  {
    id: 'xxo1',
    name: 'Introduction to Psychology',
    subTitle: 'Donald White',
    start_time: '10:30PM',
    end_time: '12:30PM',
    next_lesson: '28/22/2022',
    day: 'Saturday',
    lesson_start_date: '4/4',
    date: '5/2/2023',
    status: 'PASSED' as COURSE_COMPLETION_STATUS,
    imageUrl: '/images/courses/3.png',
  },
  {
    id: 'xxo2',
    name: 'Dental Entrance Exam Preparation',
    subTitle: 'Donald White',
    start_time: '10:30PM',
    end_time: '12:30PM',
    next_lesson: '28/22/2022',
    day: 'Saturday',
    lesson_start_date: '4/4',
    date: '5/2/2023',
    status: 'FAILED' as COURSE_COMPLETION_STATUS,
    imageUrl: '/images/courses/7.png',
  },
];

export type UnregisteredPrepCourseProps = (typeof unregCourses)[number];
export type UnregisteredOdtCourseProps = (typeof unregOdtCourses)[number];

export type CompletedCoursesProps = (typeof completedCourses)[number];
