import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient } from '@/_services';

export const shippingStatusSchema = z.union([
  z.literal('CREATED'),
  z.literal('UNPAID'),
  z.literal('PAYMENT_IN_PROGRESS'),
  z.literal('PRODUCTION_DELAYED'),
  z.literal('PRODUCTION_READY'),
  z.literal('IN_PRODUCTION'),
  z.literal('SHIPPED'),
  z.literal('REJECTED'),
  z.literal('ERROR'),
  z.literal('CANCELED'),
  z.literal('CANCELLED'),
  z.literal('NOT_PRINTED'),
]);
export type ShippingStatusSchema = z.infer<typeof shippingStatusSchema>;

export const printJobCostLineItemSchema = z.object({
  line_item_id: z.number(),
  line_item_external_id: z.string(),
  cost_excl_discounts: z.string(),
  total_tax: z.string(),
  tax_rate: z.string(),
  quantity: z.number(),
  total_cost_excl_tax: z.string(),
  total_cost_excl_discounts: z.string(),
  total_cost_incl_tax: z.string(),
  discounts: z.array(z.unknown()),
  unit_tier_cost: z.string().nullable(),
});

export const printJobLineItem = z.object({
  id: z.number(),
  title: z.string(),
  printable_normalization: z.object({
    interior: z.object({
      source_file: z.object({
        file_id: z.number(),
        filename: z.string(),
      }),
      normalized_file: z.object({
        file_id: z.number(),
        filename: z.string(),
      }),
      source_url: z.string(),
      source_md5sum: z.string(),
      job_id: z.number(),
      page_count: z.number(),
    }),
    cover: z.object({
      source_file: z.object({
        file_id: z.number(),
        filename: z.string(),
      }),
      normalized_file: z.object({
        file_id: z.number(),
        filename: z.string(),
      }),
      source_url: z.string(),
      source_md5sum: z.string(),
      job_id: z.number(),
      page_count: z.number(),
    }),
    pod_package_id: z.string(),
  }),
  status: z.object({
    name: shippingStatusSchema,
    messages: z.object({
      tracking_id: z.string(),
      tracking_urls: z.array(z.string()),
    }),
  }),
  is_reprint: z.boolean(),
  thumbnail_url: z.string(),
  external_id: z.string(),
  quantity: z.number(),
  printable_id: z.string(),
  order_line_item_id: z.string(),
  tracking_id: z.string(),
  tracking_urls: z.array(z.string()),
  pod_package_id: z.string(),
});

export type PrintJobLineItem = z.infer<typeof printJobLineItem>;

export const printJobShippingCostSchema = z.object({
  total_cost_excl_tax: z.string(),
  total_cost_incl_tax: z.string(),
  total_tax: z.string(),
  tax_rate: z.string(),
});

export type PrintJobShippingCostSchema = z.infer<typeof printJobShippingCostSchema>;

export const printJobEstimatedShippingDateSchema = z.object({
  dispatch_min: z.string(),
  dispatch_max: z.string(),
  arrival_min: z.string(),
  arrival_max: z.string(),
});

export type PrintJobEstimatedShippingDateSchema = z.infer<typeof printJobEstimatedShippingDateSchema>;

export const printJobShippingAddressSchema = z.object({
  user_id: z.string(),
  organization: z.string().nullable(),
  street1: z.string(),
  street2: z.string().nullable(),
  city: z.string(),
  postcode: z.string(),
  phone_number: z.string(),
  email: z.string().nullable(),
  is_business: z.boolean(),
  name: z.string(),
  state_code: z.string(),
  country_code: z.string(),
});

export type PrintJobShippingAddressSchema = z.infer<typeof printJobShippingAddressSchema>;

export const printJobSchema = z.object({
  study_guide_applied: z.boolean(),
  details: z.object({
    id: z.number(),
    line_items: z.array(printJobLineItem),
    shipping_level: z.string(),
    shipping_option_id: z.string(),
    status: z.object({
      name: shippingStatusSchema,
      message: z.string(),
      changed: z.string(),
    }),
    costs: z.object({
      shipping_cost: printJobShippingCostSchema,
      line_item_costs: z.array(printJobCostLineItemSchema),
      total_cost_excl_tax: z.string(),
      total_tax: z.string(),
      total_cost_incl_tax: z.string(),
      currency: z.string(),
      fulfillment_cost: z.string().nullable(),
    }),
    parent_job_id: z.string().nullable(),
    child_job_ids: z.array(z.string()).nullable(),
    estimated_shipping_dates: printJobEstimatedShippingDateSchema,
    reprints: z.array(z.string()).nullable(),
    is_cancellable: z.boolean(),
    order_id: z.string(),
    date_created: z.string(),
    date_modified: z.string(),
    external_id: z.string(),
    dropship_profile_id: z.string(),
    contact_email: z.string(),
    production_delay: z.number(),
    production_due_time: z.string(),
    aggregated_payment_print_job: z.boolean(),
    aggregated_deposit_print_job: z.boolean(),
    tax_country: z.string(),
    order_marked_as_paid: z.boolean(),
    payments: z.array(z.number()),
    shipping_address: printJobShippingAddressSchema,
    shipping_option_level: z.string(),
  }),
});

export type PrintJobSchema = z.infer<typeof printJobSchema>;

export const studyGuideStatusSchema = z.object({
  id: z.string(),
  course_id: z.string(),
  course_name: z.string(),
  print_job: z.array(printJobSchema).nullable(),
  e_book: z.union([z.literal('True'), z.literal('False')]).nullable(),
  is_in_house: z.boolean(),
  book_pdf_link: z.string().nullable(),
});

export type StudyGuideStatusSchema = z.infer<typeof studyGuideStatusSchema>;

export const useStudyGuideStatusQuery = ({ id }: { id: string }) =>
  useSuspenseQuery<StudyGuideStatusSchema>({
    queryKey: ['STUDY_GUIDE_STATUS', id],
    queryFn: () =>
      apiClient.request({
        endpoint: `/registered-courses/${id}/study-guide-status/`,
      }),
  });

export const useStudyGuideStatusMutation = ({ id }: { id: string }) => {
  return useMutation<StudyGuideStatusSchema>({
    mutationFn: () =>
      apiClient.request({
        endpoint: `/registered-courses/${id}/study-guide-status/`,
      }),
  });
};
