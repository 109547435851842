import React from 'react';

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import type { MailingOptionKeyType } from '@/_prep-portal/courses/courses-print-book/constants';
import { apiClient } from '@/_services';

const LineItemSchema = z.object({
  cost_excl_discounts: z.string(),
  total_tax: z.string(),
  tax_rate: z.string(),
  quantity: z.number(),
  total_cost_excl_tax: z.string(),
  total_cost_excl_discounts: z.string(),
  total_cost_incl_tax: z.string(),
  discounts: z.array(z.unknown()),
  unit_tier_cost: z.unknown().nullable(),
  total_cost_incl_tax_with_fee: z.number(),
});

const ShippingCostSchema = z.object({
  total_cost_excl_tax: z.string(),
  total_cost_incl_tax: z.string(),
  total_tax: z.string(),
  tax_rate: z.string(),
});

const FulfillmentCostSchema = z.object({
  tax_rate: z.string(),
  total_cost_excl_tax: z.string(),
  total_cost_incl_tax: z.string(),
  total_tax: z.string(),
});

export const PrintJobCostCalculationApiSchema = z.object({
  line_item_costs: z.array(LineItemSchema).optional(),
  shipping_cost: ShippingCostSchema.optional(),
  total_tax: z.string(),
  total_cost_excl_tax: z.string(),
  total_cost_incl_tax: z.string(),
  total_discount_amount: z.string(),
  currency: z.string(),
  fulfillment_cost: FulfillmentCostSchema.optional(),
  book_page_count: z.string(),
  total_cost_incl_tax_with_fee: z.number(),
});

export type PrintJobCostCalculationApiResponseType = z.infer<typeof PrintJobCostCalculationApiSchema>;

type PrintJobCostCalculationApiModifiedResponseType = Record<
  MailingOptionKeyType,
  PrintJobCostCalculationApiResponseType | null | undefined
>;

type PrintJobCostCalculationApiPayload = {
  course_id: string;
  shipping_address: {
    city: string;
    country_code: string;
    phone_number: string;
    postcode: string;
    state_code: string;
    street1: string;
  };
  shipping_level: MailingOptionKeyType;
  is_first_order: boolean;
};

export const usePrintJobCostCalculationsQuery = (
  mutationOptions: UseMutationOptions<
    PrintJobCostCalculationApiResponseType,
    unknown,
    PrintJobCostCalculationApiPayload
  > = {}
) => {
  const [modifiedData, setModifiedData] = React.useState<PrintJobCostCalculationApiModifiedResponseType>({
    MAIL: undefined,
    EXPEDITED: undefined,
    EXPRESS: undefined,
  });
  const { mutateAsync, ...options } = useMutation<
    PrintJobCostCalculationApiResponseType,
    unknown,
    PrintJobCostCalculationApiPayload
  >({
    ...mutationOptions,
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: '/lulu/print-job-cost-calculations/',
        payload,
      }),
    onSuccess(data, variables) {
      if (data) {
        setModifiedData((_data) => ({
          ..._data,
          [variables.shipping_level]: data,
        }));
      }
    },
  });

  return [mutateAsync, { ...options, data: modifiedData }] as const;
};
