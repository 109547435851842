import type { PropsWithChildren } from 'react';
import { useLayoutEffect, useMemo, useRef } from 'react';

import { useBoolean } from 'atp-react-ui';
import { twMerge } from 'tailwind-merge';

import { StickyHeaderContext } from './context';

export function StickyPageHeader({
  children,
  className,
  threshold = 5,
}: PropsWithChildren<{ className?: string; threshold?: number }>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isSticky, sticky] = useBoolean(false);

  useLayoutEffect(() => {
    if (ref.current) {
      const fixedHeader = () => {
        if (ref.current) {
          if (window.scrollY > threshold) {
            ref.current.classList.remove('border-transparent');
            ref.current.classList.add('border-outline', 'shadow-md');
            sticky.setTrue();
          } else {
            ref.current.classList.add('border-transparent');
            ref.current.classList.remove('border-outline', 'shadow-md');
            sticky.setFalse();
          }
        }
      };
      window.addEventListener('scroll', fixedHeader);
      return () => {
        window.addEventListener('scroll', fixedHeader);
      };
    }
    return undefined;
  }, [sticky, threshold]);

  const value = useMemo(() => ({ isSticky }), [isSticky]);

  return (
    <StickyHeaderContext.Provider value={value}>
      <div ref={ref} className={twMerge('sticky top-14 z-[18] border-b border-transparent bg-gray-25 pb-6', className)}>
        {children}
      </div>
    </StickyHeaderContext.Provider>
  );
}
