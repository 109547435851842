import { useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import type { RouteRecordsType } from '@/_navigation';

export const useCoursesLinkActive = () => {
  const { pathname } = useLocation();
  const isLinkActive = useCallback(
    (route: RouteRecordsType, isActive: boolean) => {
      if (pathname.toLowerCase().startsWith('/courses') && route.path.toLowerCase().includes('/courses')) {
        return true;
      }

      return isActive;
    },
    [pathname]
  );

  return isLinkActive;
};
