import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, defaultShouldDehydrateQuery } from '@tanstack/react-query';
import type { PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';

const persistTimeout = 1000 * 60 * 60 * 24; // 24 hours

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      gcTime: persistTimeout,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const persistentQueries = ['ME', 'ACCOUNT_DEPENDENCIES'];

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  persister,
  maxAge: persistTimeout,
  dehydrateOptions: {
    shouldDehydrateQuery: (query) =>
      defaultShouldDehydrateQuery(query) && persistentQueries.some((key) => query.queryKey.includes(key)),
  },
};
