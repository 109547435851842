import { CookieManager } from '@/_services';

import { getAccessType } from '../../../_shared/policies';

import type { MeApiResponse, MeApiTransformedResponse } from './use-me-query';

export function transformMeApiResponse(data: MeApiResponse): MeApiTransformedResponse {
  const accountType = CookieManager.getAccountType();
  const isStudent = CookieManager.isStudentAccount();
  const isInstructor = CookieManager.isInstructorAccount();

  const { payment_summary, plan_summary } = data;

  const { has_subscription, paused_payment } = payment_summary || {};
  const { atp_pilot, student_status, has_plan, enrollment_end_date, extension_ea_date, payment_type } =
    plan_summary || {};

  const accessType = getAccessType({
    payment_type,
    has_subscription,
    paused_payment,
    student_status,
    has_plan,
    enrollment_end_date,
    extension_ea_date,
    atp_pilot,
  });

  return {
    ...data,
    accessType,
    localAccountType: accountType,
    isStudent,
    isInstructor,
  };
}
