import React from 'react';

import { noop } from '@/_shared';

import type { CourseTabType } from './constants';
import { coursesTabs } from './constants';

export type QuestionsCount = { unanswered: number; answered: number; solved: number };

export type CoursesTabsContextType = {
  tabs: CourseTabType[];
  selectedTab: CourseTabType;
  setSelectedTab: (tab: CourseTabType) => void;
};

export const CoursesTabsContext = React.createContext<CoursesTabsContextType>({
  tabs: coursesTabs,
  selectedTab: coursesTabs[0],
  setSelectedTab: noop,
});
