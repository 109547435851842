import React from 'react';

import { CookieManager } from '@/_services';
import { logoutUser } from '@/_shared/utils/user';

export const useAuth = () => {
  const login = React.useCallback(({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
    CookieManager.setAuthTokens(accessToken, refreshToken);
  }, []);

  return { login, logout: logoutUser };
};
