import { Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

import { OdtQuestionsSectionSkeleton } from './components/odt-questions-section/odt-questions-section-skeleton';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex items-center">
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="h-[1.125rem] w-96" />
            </div>
          </div>
        </PageHead>
      </Container>
      <OdtQuestionsSectionSkeleton />
    </>
  );
}
