import { Card, Skeleton } from 'atp-react-ui';

export function PrepCourseCardSkeleton() {
  return (
    <Card className="course-card divide-outline">
      <article className="course-card-article">
        <header className="course-card-article-header border-outline/30">
          <Skeleton className="h-28 rounded-bl-none rounded-br-none" />
        </header>

        <section className="course-card-article-body">
          <div className="px-6">
            <Skeleton className="h-4 w-1/2" />
            <Skeleton className="mt-1 h-5" />
          </div>
          <div className="mt-4 flex-grow-0 px-6">
            <Skeleton className="h-4 w-1/2" />
            <Skeleton className="mt-1 h-4" />
          </div>
        </section>
      </article>
      <footer className="mt-4 flex flex-col gap-1 border-none p-2">
        <Skeleton className="h-10" />
        <Skeleton className="h-[3.5625rem]" />
      </footer>
    </Card>
  );
}
