import { lazy, Suspense } from 'react';

import { ErrorBoundary } from '@/_components';

import Loading from './loading';

const Page = lazy(() => import('./page'));

export default function Course() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Page />
      </Suspense>
    </ErrorBoundary>
  );
}
