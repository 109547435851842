import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import type { CookieChangeListener } from 'universal-cookie';

import { config } from '@/_config';
import { setChatNameEmail, useIdentifyHotJar, CookieManager, logger } from '@/_services';
import { isPathAllowed, isRewardsAllowed, RESTRICTIONS_TYPE, shouldShowSelfOnboardingSurvey } from '@/_shared/policies';

import { useLocationDetails } from '../../_navigation/hooks/use-location-details';
import ROUTES from '../../_navigation/routes';
import { useAccountDependenciesContext } from '../account-dependencies-provider';
import { useMeQuery } from '../hooks/use-me-query';
import type { MeApiTransformedResponse } from '../hooks/use-me-query';

import type { MeContextType } from './me-context';
import { MeContext } from './me-context';

export function MeProvider({ children }: { children: ReactNode }) {
  const nav = useNavigate();
  const routeData = useLocationDetails();
  const { data: accountDepData } = useAccountDependenciesContext();

  const identifyHotJar = useIdentifyHotJar();
  const handleRedirection = useCallback(
    (data: MeApiTransformedResponse) => {
      const { accessType, isInstructor, isStudent, why_bridge_completed } = data;

      const isNotAllowedPath = isPathAllowed(accessType, routeData.data?.META);

      if (isInstructor) {
        // Redirect to instructor courses
        return;
      }
      if (isStudent) {
        // Self-onboarding survey
        if (
          shouldShowSelfOnboardingSurvey(
            accountDepData?.first_login,
            accountDepData?.has_submitted_onboarding_survey,
            why_bridge_completed
          ) &&
          routeData.data.path !== ROUTES.SELF_ONBOARDING_SURVEY.path
        ) {
          nav(ROUTES.SELF_ONBOARDING_SURVEY.path);
          return;
        }

        if (
          accessType === RESTRICTIONS_TYPE.PPP_ACCESS_BLOCKED &&
          isNotAllowedPath &&
          routeData.data.path !== ROUTES.BILLING.path
        ) {
          nav(ROUTES.BILLING.path);
          return;
        }

        if (
          accessType === RESTRICTIONS_TYPE.PREP_ACCESS_ONLY &&
          isNotAllowedPath &&
          routeData.data.path !== ROUTES.IN_PROGRESS.path
        ) {
          nav(ROUTES.IN_PROGRESS.path);
          return;
        }

        if (
          config.isATP &&
          why_bridge_completed === false &&
          routeData.data.path &&
          !routeData.data.path.includes('why-bridge') &&
          routeData.data.path !== ROUTES.HARDSHIP_PLANS.path &&
          accessType !== RESTRICTIONS_TYPE.PREP_ACCESS_ONLY
        ) {
          nav(ROUTES.WHY_BRIDGE.path);
          return;
        }

        if (routeData.data.path === ROUTES.REWARDS.path && !isRewardsAllowed(accountDepData?.allow_rewards)) {
          nav(ROUTES.COURSES.path);
        }
      }
    },
    [
      accountDepData?.allow_rewards,
      accountDepData?.first_login,
      accountDepData?.has_submitted_onboarding_survey,
      nav,
      routeData.data?.META,
      routeData?.data?.path,
    ]
  );

  const { data, isFetching, refetch, isSuccess } = useMeQuery();

  useEffect(() => {
    const listenCookiesChange: CookieChangeListener = (cookie) => {
      if (CookieManager.matchKeys(cookie.name, CookieManager.ACCESS_TOKEN_KEY)) {
        if (cookie.value) {
          refetch();
        }
      }
    };
    CookieManager.cookies.addChangeListener(listenCookiesChange);

    return () => {
      CookieManager.cookies.removeChangeListener(listenCookiesChange);
    };
  }, [nav, refetch]);

  useEffect(() => {
    if (data) {
      handleRedirection(data);
      logger.setId({ customer_id: data.id });
    }
  }, [data, handleRedirection]);

  useEffect(() => {
    if (isSuccess && data) {
      setChatNameEmail(data.email, data.name);
      identifyHotJar(data);
      CookieManager.setLastUsername(data.email);
    }
  }, [data, identifyHotJar, isSuccess]);

  const value: MeContextType = useMemo(
    () => ({
      data,
      isLoadingMeData: isFetching,
      isFetching,
    }),
    [data, isFetching]
  );

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
}
