import { Navigate } from 'react-router-dom';

import { useUser } from '@/_providers';

import ROUTES from '../routes';

export function RedirectionResolver() {
  const { isLoggedIn } = useUser();
  return <Navigate to={(isLoggedIn ? ROUTES.IN_PROGRESS : ROUTES.LOGIN).path} />;
}
