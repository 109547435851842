import { useCallback } from 'react';

import { useIsMounted } from 'atp-react-ui';
import useHotjar from 'react-use-hotjar';

import { config } from '@/_config';

declare global {
  interface Window {
    myCustomLogger: () => void;
  }
}

window.myCustomLogger = console.info;

export function useInitHotJar() {
  const { initHotjar, stateChange } = useHotjar();
  const isAllowed = config.isProduction && config.isATP;

  useIsMounted(() => {
    if (isAllowed) {
      initHotjar(config.atpHotjarApiKey, 6, false, window.myCustomLogger);
    }
  });

  return useCallback(
    (pathname: string) => {
      if (isAllowed) {
        stateChange(pathname);
      }
    },
    [isAllowed, stateChange]
  );
}

export function useIdentifyHotJar() {
  const { identifyHotjar } = useHotjar();
  const isAllowed = config.isProduction && config.isATP;

  return useCallback(
    ({ email }: { email: string }) => {
      if (isAllowed) {
        identifyHotjar(email || 'email not found', { utcTimeStamp: new Date().toUTCString() }, window.myCustomLogger);
      }
    },
    [identifyHotjar, isAllowed]
  );
}
