import { Skeleton } from 'atp-react-ui';

import { Container } from '@/_components';
import { HeadersTabWrapper } from '@/_containers';

export function ZinreloLoader() {
  return (
    <>
      <Container>
        <Skeleton className="h-20" />
      </Container>
      <Container>
        <HeadersTabWrapper>
          {[0, 1, 2].map((tab) => (
            <div key={tab} className="py-2">
              <Skeleton className="h-8 w-28" />
            </div>
          ))}
        </HeadersTabWrapper>
      </Container>
      <Container>
        <div className="mt-8 grid grid-cols-1 gap-6 pb-10 sm:grid-cols-2 pre-lg:grid-cols-3 pre-xl:grid-cols-4">
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
        </div>
      </Container>
    </>
  );
}
