import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export type PrimaryLinkProps = LinkProps & {
  type?: 'default' | 'lighter';
};

export function PrimaryLink({ to, children, className, type = 'default', ...rest }: PrimaryLinkProps) {
  return (
    <Link
      to={to}
      className={twMerge(
        'text-primary no-underline hover:underline',
        type === 'lighter' && 'text-primary-medium',
        className
      )}
      {...rest}
    >
      {children}
    </Link>
  );
}
