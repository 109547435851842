import { Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex items-center">
            <Skeleton className="mr-4 size-8" />
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="h-[1.125rem] w-64" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container className="mt-12 flex justify-center">
        <div className="flex flex-col items-center gap-y-4 md:w-1/2">
          <Skeleton className="size-32 rounded-full" />
          <Skeleton className="mt-5 h-8" />
          <Skeleton className="h-8" />
          <Skeleton className="h-8" />
          <Skeleton className="h-8" />
          <Skeleton className="h-8" />
          <Skeleton className="h-12 w-64" />
        </div>
      </Container>
    </>
  );
}
