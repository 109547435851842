import type { UseQueryOptions } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { queryClient, apiClientAod } from '@/_services';

import { OdtQuestionResponseSchema } from './use-odt-questions-query';

export const AnswerSchema = z.object({
  id: z.string(),
  question: z.string(),
  author_id: z.string(),
  author_name: z.string(),
  is_instructor: z.boolean(),
  body: z.string(),
  is_helpful: z.boolean().nullable(),
  created_on: z.string(),
  modified_on: z.string(),
  viewed: z.boolean(),
  feedback: z.string().nullable(),
  attachments: z.array(z.string()),
});

export type AnswerSchemaType = z.infer<typeof AnswerSchema>;

export const AodAnswersApiSchema = z.object({
  answers: z.array(AnswerSchema),
  'followup-questions': z.array(OdtQuestionResponseSchema),
});

export type AodAnswersApiResponseType = z.infer<typeof AodAnswersApiSchema>;

type UseAodAnswersQueryParams = Partial<Omit<UseQueryOptions<AodAnswersApiResponseType>, 'queryKey' | 'queryFn'>> & {
  id: string;
};

const getQueryKey = ({ id }: Pick<UseAodAnswersQueryParams, 'id'>) => ['AOD_ANSWERS', id];

export const useAodAnswersQuery = ({ id, ...options }: UseAodAnswersQueryParams) =>
  useSuspenseQuery<AodAnswersApiResponseType, Error, AodAnswersApiResponseType>({
    queryKey: getQueryKey({ id }),
    queryFn: async () => apiClientAod.request({ endpoint: `/questions/${id}/answers/` }),
    ...options,
  });

export const invalidateAodAnswersQuery = ({ id }: Pick<UseAodAnswersQueryParams, 'id'>) =>
  queryClient.invalidateQueries({ queryKey: getQueryKey({ id }) });
