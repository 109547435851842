import { z } from 'zod';

const aodCourseSchema = z.object({
  id: z.string(),
  name: z.string(),
  length: z.string(),
  scheduled_class: z.string(),
  description: z.string(),
  exam_standard: z.unknown().nullable(),
  ranking: z.string(),
});

export type AodCourseSchema = z.infer<typeof aodCourseSchema>;

export const UnregisteredAodCoursesApiResponseSchema = z.array(aodCourseSchema);
export type UnregisteredAodCoursesApiResponseType = z.infer<typeof UnregisteredAodCoursesApiResponseSchema>;
