import { Modal, ModalBody } from 'atp-react-ui';

import { RewardsTerms } from './terms';

type TermsModalProps = {
  open: boolean;
  onClose: () => void;
};

export function TermsModal({ open, onClose }: TermsModalProps) {
  return (
    <Modal open={open} onClose={onClose} className="md:max-w-5xl">
      <ModalBody>
        <RewardsTerms />
      </ModalBody>
    </Modal>
  );
}
