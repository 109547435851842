import { Button, Modal, ModalBody, ModalFooter, Text } from 'atp-react-ui';
import { Link } from 'react-router-dom';

import { ROUTES } from '@/_navigation';
import { dateTime, getFormattedCurrency } from '@/_shared';

import { ACH_CAMPAIGN_END_DATE, ACH_CAMPAIGN_SAVING_AMOUNT } from './constants';
import { useAchCampaignModal } from './use-ach-campaign-modal';

export function AchCampaignModal() {
  const { showAchModal, toggleAchModal } = useAchCampaignModal();

  return (
    <Modal open={showAchModal} onClose={toggleAchModal}>
      <ModalBody>
        <Text as="caption-medium">
          Save on convenience fees and earn a {getFormattedCurrency(ACH_CAMPAIGN_SAVING_AMOUNT)} bonus by updating your
          auto-pay settings to your checking account before {dateTime.format(ACH_CAMPAIGN_END_DATE, 'MMMM Do, YYYY')}.
        </Text>
      </ModalBody>
      <ModalFooter>
        <Link
          to={ROUTES.BILLING_PAYMENT_METHODS.path}
          state={ROUTES.BILLING_PAYMENT_METHODS.buildState({ add: 'ach' })}
          onClick={toggleAchModal}
        >
          <Button modal as="contained">
            Update Auto-Pay to Checking Account
          </Button>
        </Link>
      </ModalFooter>
    </Modal>
  );
}
