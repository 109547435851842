import { Card, CardBody, CircleSkeleton, Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start pb-6">
          <section className="mt-2 flex items-center">
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="h-[1.125rem] w-96" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container>
        <section className="flex flex-col gap-y-4 pre-xl:w-3/4">
          <Skeleton className="h-6 w-40" />
          <Card>
            <CardBody className="px-6 py-12 sm:px-10">
              <section className="flex items-center justify-between py-4">
                <div className="flex items-center justify-between gap-x-3">
                  <CircleSkeleton className="size-4" />
                  <div className="flex flex-col gap-y-1">
                    <Skeleton className="h-[1.125rem] w-24" />
                    <Skeleton className="h-[0.9375rem] w-20" />
                  </div>
                </div>
                <Skeleton className="h-[1.125rem] w-24" />
              </section>
              <div className="border-b border-outline" />
              <section className="flex items-center justify-between py-4">
                <div className="flex items-center justify-between gap-x-3">
                  <CircleSkeleton className="size-4" />
                  <div className="flex flex-col gap-y-1">
                    <Skeleton className="h-[1.125rem] w-24" />
                    <Skeleton className="h-[0.9375rem] w-20" />
                  </div>
                </div>
                <Skeleton className="h-[1.125rem] w-24" />
              </section>
              <div className="border-b border-outline" />
              <section className="flex items-center justify-between py-4">
                <div className="flex items-center justify-between gap-x-3">
                  <CircleSkeleton className="size-4" />
                  <div className="flex flex-col gap-y-1">
                    <Skeleton className="h-[1.125rem] w-24" />
                    <Skeleton className="h-[0.9375rem] w-20" />
                  </div>
                </div>
                <Skeleton className="h-[1.125rem] w-24" />
              </section>
            </CardBody>
          </Card>

          <Skeleton className="mt-4 h-6 w-40" />
          <Card>
            <CardBody className="border-b border-outline px-6 py-12 sm:px-10">
              <section className="flex items-center justify-between py-4">
                <div className="flex items-center justify-between gap-x-3">
                  <CircleSkeleton className="size-4" />
                  <Skeleton className="h-[2.375rem] w-11" />
                  <div className="flex flex-col gap-y-1">
                    <Skeleton className="h-[1.125rem] w-24" />
                    <Skeleton className="h-[0.9375rem] w-20" />
                  </div>
                </div>
              </section>
              <div className="border-b border-outline" />
              <section className="flex items-center justify-between py-4">
                <div className="flex items-center justify-between gap-x-3">
                  <CircleSkeleton className="size-4" />
                  <Skeleton className="h-[2.375rem] w-11" />
                  <div className="flex flex-col gap-y-1">
                    <Skeleton className="h-[1.125rem] w-24" />
                    <Skeleton className="h-[0.9375rem] w-20" />
                  </div>
                </div>
              </section>
            </CardBody>
            <CardBody className="flex flex-col gap-y-1 border-b border-outline px-6 py-12 sm:px-10">
              <Skeleton className="h-[1.125rem] w-40" />

              <article className="mt-1 flex items-center justify-between">
                <Skeleton className="h-[1.125rem] w-28" />
                <Skeleton className="h-[1.125rem] w-28" />
              </article>
              <article className="flex items-center justify-between">
                <Skeleton className="h-[1.125rem] w-28" />
                <Skeleton className="h-[1.125rem] w-28" />
              </article>
              <article className="flex items-center justify-between">
                <Skeleton className="h-[1.125rem] w-28" />
                <Skeleton className="h-[1.125rem] w-28" />
              </article>
              <article className="mt-1 flex items-center justify-between">
                <Skeleton className="h-[1.125rem] w-28" />
                <Skeleton className="h-[1.125rem] w-28" />
              </article>
            </CardBody>

            <CardBody className="flex flex-row items-end justify-between gap-x-3 px-6 py-12 sm:px-10">
              <div className="w-full space-y-2">
                <Skeleton className="h-[1.125rem]" />
                <Skeleton className="h-[1.125rem]" />
                <Skeleton className="h-[1.125rem]" />
                <Skeleton className="h-[1.125rem] w-3/4" />
              </div>
              <div>
                <Skeleton />
              </div>
            </CardBody>
          </Card>
        </section>
      </Container>
    </>
  );
}
