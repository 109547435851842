import { lazy } from 'react';

import { ErrorBoundary } from '@/_components';

import { CourseSectionLayout } from '../courses/components';

import type { PageProps } from './types';

const Page = lazy(() => import('./page'));

export default function CoursesUnregistered(props: PageProps) {
  const { id } = props;

  return (
    <CourseSectionLayout id={id}>
      <ErrorBoundary>
        <Page {...props} />
      </ErrorBoundary>
    </CourseSectionLayout>
  );
}
