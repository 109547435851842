import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import { moodleClient } from '@/_services';

type ChangeMoodlePasswordMutationSchema = {
  username: string;
  password: string;
};

type ChangeMoodlePasswordApiResponseSchema = {
  code: 1 | -1 | -2 | 0;
};

export const useChangeMoodlePassword = (
  options?: UseMutationOptions<ChangeMoodlePasswordApiResponseSchema, unknown, ChangeMoodlePasswordMutationSchema>
) =>
  useMutation<ChangeMoodlePasswordApiResponseSchema, unknown, ChangeMoodlePasswordMutationSchema>({
    ...(options || {}),
    mutationFn: async (payload) => {
      const urlencoded = new URLSearchParams();
      urlencoded.append('username', payload.username);
      urlencoded.append('password', payload.password);

      return moodleClient.post({
        endpoint: '/login/achieve_forgot_password.php',
        method: 'POST',
        payload: urlencoded,
        formData: true,
        isAuth: false,
        init: {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        },
      });
    },
  });
