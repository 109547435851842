import { dateTime } from '@/_shared/utils';

export function shouldShowSelfOnboardingSurvey(
  firstLogin: string | undefined,
  hasSubmitted: boolean | undefined | null,
  isWhyBridgeCompleted: boolean | undefined | null
) {
  const hoursSinceFirstLogin = dateTime.getDurationDifference(dateTime.now(), firstLogin, 'hours');

  return isWhyBridgeCompleted && !hasSubmitted && hoursSinceFirstLogin >= 48 && hoursSinceFirstLogin <= 72;
}
