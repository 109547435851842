import { Card, CardBody, Skeleton } from 'atp-react-ui';

import { Container, InputSkeleton, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex items-center">
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="h-[1.125rem] w-96" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6">
        <section className="flex flex-col gap-y-4 pre-xl:w-3/4">
          <Skeleton className="h-6 w-40" />
          <Card>
            <CardBody className="px-6 py-12 sm:px-10">
              <InputSkeleton />
              <Skeleton className="mt-1 h-4 w-96" />

              <Skeleton className="mt-8 h-11 w-32" />
            </CardBody>
          </Card>
        </section>
      </Container>

      <Container className="mt-8">
        <section className="flex flex-col gap-y-4 pre-xl:w-3/4">
          <Skeleton className="h-6 w-40" />
          <Card>
            <CardBody className="px-6 py-12 sm:px-10">
              <div className="grid-col-3 grid gap-4">
                <div className="col-span-3">
                  <InputSkeleton />
                </div>

                <div className="col-span-3 flex flex-row flex-wrap gap-x-4">
                  <div className="flex-1">
                    <InputSkeleton />
                  </div>
                  <div className="flex-1">
                    <InputSkeleton />
                  </div>
                  <div className="flex-1">
                    <InputSkeleton />
                  </div>
                </div>

                <div className="col-span-3">
                  <InputSkeleton />
                </div>

                <div className="col-span-3">
                  <InputSkeleton />
                  <Skeleton className="mt-1 h-4 sm:w-96" />
                </div>
                <div>
                  <Skeleton className="mt-8 h-11 w-32" />
                </div>
              </div>
            </CardBody>
          </Card>
        </section>
      </Container>
    </>
  );
}
