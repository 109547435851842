import { Skeleton } from 'atp-react-ui';

export function ProgressSkeleton({ upperRight = true }: { upperRight?: boolean }) {
  return (
    <div>
      <div className="flex w-full justify-between">
        <Skeleton className="h-[1.125rem] w-40" />
        {upperRight && <Skeleton className="h-[1.125rem] w-20" />}
      </div>
      <Skeleton className="mt-1 h-2" />
    </div>
  );
}
