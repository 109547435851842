import { Skeleton } from 'atp-react-ui';

import { Container, PublicPageHeader } from '@/_components';

export default function Loading({ title }: { title: string }) {
  return (
    <>
      <PublicPageHeader title={title} />
      <Container className="mt-11 flex h-[16.4375rem] flex-col">
        <Skeleton className="-mt-0.5 h-4 w-2/3 md:w-1/3" />
        <Skeleton className="mt-5 h-6 w-full" />
      </Container>

      <Container className="mb-6 mt-16 flex items-center justify-end gap-4 pt-2">
        <Skeleton className="h-10 w-11" />
        <Skeleton className="mr-0.5 h-4 w-24" />
        <Skeleton className="h-10 w-24" />
      </Container>
    </>
  );
}
