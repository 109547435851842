import { memo } from 'react';

import { Card } from 'atp-react-ui';

import { PrepCourseResourcesButton } from '../prep-course-resources-button';

import { PrepCourseBody } from './prep-course-body';
import { PrepCourseFooter } from './prep-course-footer';
import { PrepCourseHeader } from './prep-course-header';
import { PrepCourseLessonButton } from './prep-course-lesson-button';
import { type PrepCourseCardProps } from './types';

function PrepCourseCardBase({ header, body, lessonButton, footer, resources, actionCallback }: PrepCourseCardProps) {
  return (
    <Card className="course-card prep-course-card overflow-hidden">
      <article className="course-card-article">
        <PrepCourseHeader {...header} />

        <section className="course-card-article-body flex flex-col gap-4">
          <PrepCourseBody {...body} />

          <div className="flex flex-grow-0 flex-col gap-y-1 px-2">
            {resources && <PrepCourseResourcesButton {...resources} />}
            {lessonButton && <PrepCourseLessonButton {...lessonButton} course_id={header.id} />}
          </div>
        </section>
      </article>
      {footer && (
        <PrepCourseFooter
          {...footer}
          actionCallback={actionCallback({
            id: header.id,
            imageUrl: header.imageUrl,
            name: body.name,
            subTitle: body.subTitle,
            status: footer.status,
            examDate: footer.examDate,
            exams: footer.exams,
          })}
        />
      )}
    </Card>
  );
}

export const PrepCourseCard = memo(PrepCourseCardBase);
