import React from 'react';

export type LessonNotificationContextType = {
  liveCourses: string[];
  setCourseLive: (courseId: string) => void;
  setCourseOffline: (courseId: string) => void;
};

export const LessonNotificationContext = React.createContext<LessonNotificationContextType>({
  liveCourses: [],
  setCourseLive: () => {},
  setCourseOffline: () => {},
});
