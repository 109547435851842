import { z } from 'zod';

const RegisteredCourseExamSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  exam_admin: z.unknown().nullable(),
  exam_title: z.string().nullable(),
  examiner: z.unknown().nullable(),
  link_to_exam: z.string().nullable(),
  cost: z.unknown().nullable(),
  exam: z.unknown().nullable(),
  class_field: z.unknown().nullable(),
});

const registeredCourseSchema = z.object({
  id: z.string(),
  course_id: z.string(),
  course_name: z.string(),
  length: z.string(),
  next_start: z.string().nullable(),
  description: z.string(),
  exam_standard: z.string().optional(),
  start_date: z.string(),
  end_date: z.string(),
  start_time: z.string(),
  end_time: z.string(),
  instructor_name: z.string(),
  day_s_of_the_week: z.string(),
  time_formula: z.string(),
  join_lesson_link: z.string().nullable(),
  course_resources_link: z.string(),
  time_zone: z.string(),
  exams: z.array(RegisteredCourseExamSchema),
  is_complete: z.boolean(),
  allow_drop: z.boolean(),
  allow_join_lesson: z.boolean(),
  zoom_meeting_id: z.string().nullable(),
  instructor_email: z.string().nullable(),
  instructor_employee_email: z.string(),
  system_timestamp: z.string(),
  instructor_getting_late: z.string().nullable(),
  lesson_canceled_1: z.string().nullable(),
  lesson_canceled_2: z.string().nullable(),
  lesson_canceled_3: z.string().nullable(),
  odt_enabled: z.boolean(),
  ycbm_team_calendar: z.string().nullable(),
  course_image: z.string(),
});

export type RegisteredCourseSchema = z.infer<typeof registeredCourseSchema>;

export const RegisteredCoursesApiResponseSchema = z.array(registeredCourseSchema);

export type RegisteredCoursesApiResponseType = z.infer<typeof RegisteredCoursesApiResponseSchema>;

export type RegisteredCoursesModifiedResponse = {
  prep: RegisteredCourseSchema[];
  odt: RegisteredCourseSchema[];
};
