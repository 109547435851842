import ArrowPathIcon from '@heroicons/react/24/outline/ArrowPathIcon';
import SingleSlashIcon from '@heroicons/react/24/outline/SignalSlashIcon';
import { Button, Modal, ModalBody, ModalFooter, Icon } from 'atp-react-ui';

interface IProps {
  isOpen: boolean;
  refetch: () => void;
}

export function BlockingRetry({ isOpen, refetch }: IProps) {
  return (
    <Modal open={isOpen} disableInternalClosing hideCloseButton onClose={() => {}} className="mx-4 my-auto">
      <ModalBody className="flex flex-col items-center text-center">
        <Icon icon={SingleSlashIcon} className="size-16 text-accent-x-light" />
        <div className="mt-6 text-center text-accent-light">
          Oops, something went wrong. Try fixing your internet connection and try again.
        </div>
      </ModalBody>
      <ModalFooter className="justify-center">
        <Button className="w-44" iconLeft={ArrowPathIcon} onClick={refetch}>
          Retry
        </Button>
      </ModalFooter>
    </Modal>
  );
}
