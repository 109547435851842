import { forwardRef } from 'react';

import { useInputStyle } from 'atp-react-ui';
import type { PatternFormatProps } from 'react-number-format';
import { PatternFormat } from 'react-number-format';

export type PatternInputProps = Omit<PatternFormatProps, 'value' | 'defaultValue'> & {
  value: string | number | undefined;
  defaultValue?: string | number;
};

const PatternInput = forwardRef<HTMLInputElement, PatternInputProps>(({ className, ...props }, ref) => {
  const { styleCls } = useInputStyle({ ...props, className });
  return <PatternFormat className={styleCls} getInputRef={ref} {...props} />;
});

PatternInput.displayName = 'PatternInput';

export { PatternInput };
