import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { FormValidation } from '@/_shared';

export const moodleLoginFormSchema = z
  .object({
    username: FormValidation.email,
    password: FormValidation.password,
  })
  .required();

export type TLoginForm = z.infer<typeof moodleLoginFormSchema>;

const defaultValues: Readonly<TLoginForm> = {
  username: '',
  password: '',
} as const;

export const useFormDef = (v = defaultValues) =>
  useForm<TLoginForm>({
    resolver: zodResolver(moodleLoginFormSchema),
    defaultValues: v,
  });
