import { z } from 'zod';

import { dateTime } from '@/_shared';

import type { COURSE_COMPLETION_STATUS } from './shared';
import { getCourseImage } from './shared';

export const examQuerySchema = z.object({
  exam_admin: z.string().nullable(),
  link_to_exam: z.string(),
  exam_name: z.string(),
  course_name: z.string(),
  id: z.string(),
});

export type ExamQuerySchema = z.infer<typeof examQuerySchema>;

export const examStatusSchema = z.union([z.literal('Registered'), z.literal('Taken'), z.literal('In Class')]);
export type ExamStatusSchema = z.infer<typeof examStatusSchema>;

export const scheduledExamQuerySchema = z.object({
  exams: z.array(examQuerySchema).nullable(),
  course_status: examStatusSchema,
  exam_standard: z.string(),
  course_name: z.string(),
  id: z.string(),
});
export type ScheduledExamQuerySchema = z.infer<typeof scheduledExamQuerySchema>;

export const upcomingExamQuerySchema = z.object({
  id: z.string(),
  course_name: z.string(),
  exam_day: z.string(),
  remaining_days: z.number(),
  passed_time: z.null(),
  exam_date: z.string(),
  exam_standard: z.string(),
});
export type UpcomingExamQuerySchema = z.infer<typeof upcomingExamQuerySchema>;

export const passedExamQuerySchema = z.object({
  id: z.string(),
  course_name: z.string(),
});
export type PassedExamQuerySchema = z.infer<typeof passedExamQuerySchema>;

export const examsQuerySchema = z.object({
  scheduled_exams: z.array(scheduledExamQuerySchema).nullable(),
  upcoming_exams: z.array(upcomingExamQuerySchema).nullable(),
  passed_exams: z.array(passedExamQuerySchema),
});

export type CompletedCourseQuerySchema = ScheduledExamQuerySchema | UpcomingExamQuerySchema | PassedExamQuerySchema;

export type ExamsQuerySchema = z.infer<typeof examsQuerySchema>;

export type ExamsQueryModifiedSchema = {
  id: string;
  name: string;
  subTitle: string;
  status: COURSE_COMPLETION_STATUS;
  imageUrl: string;
  examDate?: string;
  courseStatus?: ExamStatusSchema;
  exams?: ExamQuerySchema[];
};

export function parseExamsResponse(data: ExamsQuerySchema): ExamsQueryModifiedSchema[] {
  const scheduledExams: ExamsQueryModifiedSchema[] =
    data.scheduled_exams
      ?.filter((exam) => Boolean(exam.exam_standard))
      .map((exam) => ({
        id: exam.id,
        name: exam.course_name,
        subTitle: 'PREP COURSE',
        imageUrl: getCourseImage(exam.course_name),
        status: 'UN_SCHEDULED',
        courseStatus: exam.course_status,
        exams: exam.exams?.filter((_exam) => _exam.exam_admin?.toLowerCase() !== 'uexcel'),
      })) || [];

  const upcomingExams: ExamsQueryModifiedSchema[] =
    data.upcoming_exams?.map((exam) => {
      let status: COURSE_COMPLETION_STATUS = 'SCHEDULED';
      if (exam.exam_date) {
        if (dateTime.checkGreater(exam.exam_date, new Date())) {
          status = 'WAITING';
        }
      }
      return {
        id: exam.id,
        name: exam.course_name,
        subTitle: 'PREP COURSE',
        imageUrl: getCourseImage(exam.course_name),
        status,
        examDate: exam.exam_date,
      };
    }) || [];

  const passedExams: ExamsQueryModifiedSchema[] =
    data.passed_exams?.map((exam) => ({
      id: exam.id,
      name: exam?.course_name ?? '—',
      subTitle: 'PREP COURSE',
      imageUrl: getCourseImage(exam?.course_name ?? '—'),
      status: 'PASSED',
    })) || [];

  return [...scheduledExams, ...upcomingExams, ...passedExams];
}
