import type { SyntheticEvent } from 'react';

import { useBoolean } from 'atp-react-ui';

import { Preloader } from './preloader';

export type IFrameProps = {
  className?: string;
  title: string;
  noExtraParam?: boolean;
  height?: string;
  inPage?: boolean;
  urlObj: {
    link: string;
    id?: string;
    callback?: (e: SyntheticEvent) => void;
  };
};

export function IframeViewer({
  className,
  title,
  urlObj: { link, id, callback },
  noExtraParam = false,
  inPage = true,
  height = '100%',
}: IFrameProps) {
  const [isLoaded, loaded] = useBoolean();

  return (
    <div className="flex flex-1 flex-col">
      {!isLoaded && <Preloader />}
      <iframe
        title={title}
        src={`${link}${noExtraParam ? '' : '/?noframe=true&skipHeaderFooter=true'}`}
        id={id}
        className={`min-h-full w-full px-0 ${inPage ? '' : 'max-h-full h-screen md:h-full'} border-0 bg-transparent ${
          isLoaded ? 'block' : 'hidden'
        } ${className}`}
        // allowtransparency
        allowFullScreen
        width="100%"
        height={height}
        onLoad={(e) => {
          loaded.setTrue();
          callback?.(e);
        }}
      />
    </div>
  );
}
