import type { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

function PageHead({
  id,
  headerClassName,
  className,
  children,
}: PropsWithChildren<{ id?: string; headerClassName?: string; className?: string }>): JSX.Element {
  return (
    <header id={id} className={twMerge('pt-8', headerClassName)}>
      <div className={twMerge('flex items-center justify-between pb-0', className)}>{children}</div>
    </header>
  );
}

export default PageHead;
