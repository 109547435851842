import { config } from '@/_config';

import type { PrimaryLinkProps } from './primary-link';
import { PrimaryLink } from './primary-link';

type CustomerSuccessLinkProps = Omit<PrimaryLinkProps, 'to'>;

export function CustomerSuccessLink(props: CustomerSuccessLinkProps) {
  return (
    <PrimaryLink to={`mailto:${config.atpCustomerSuccess}`} {...props}>
      Customer Success
    </PrimaryLink>
  );
}
