import { Card, CardBody, Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

import { OrderSummarySectionLoader } from './components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="mt-1 flex-col items-start">
          <Skeleton className="h-4 w-44" />

          <section className="mt-3 flex items-center">
            <Skeleton className="mr-4 size-8" />
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="mt-4 flex items-center gap-2">
            <Skeleton className="h-4 w-5" />
            <Skeleton className="h-4 w-28" />
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6 flex flex-col gap-4 lg:flex-row">
        <section className="flex-grow">
          <Card aria-labelledby="course-information-title" className="h-full border border-outline">
            <CardBody className="mt-2.5 flex h-full flex-1 flex-col gap-1.5 px-6 py-10 sm:px-10">
              <Skeleton className="h-4 w-28" />
              <Skeleton className="mt-3 h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-2/3" />
            </CardBody>
          </Card>
        </section>
        <section className="w-full flex-shrink-0 lg:w-[26rem]">
          <Card aria-labelledby="course-information-title" className="h-full border border-outline">
            <CardBody className="flex h-full flex-1 flex-col gap-2 px-6 py-10 sm:px-10">
              <Skeleton className="mt-2 h-4 w-28" />

              <Skeleton className="mt-3 h-4 w-32" />
              <Skeleton className="h-4 w-32" />
              <div className="-mt-2">
                <OrderSummarySectionLoader />
              </div>
            </CardBody>
          </Card>
        </section>
      </Container>
    </>
  );
}
