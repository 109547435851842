import React from 'react';

import { useLocation } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCurrentLocationState = <T = Record<string, any>>() => {
  const { state } = useLocation();

  const currentState = React.useMemo(() => {
    const locationState: Record<string, undefined | string | number> = {};
    const keys = Object.keys(state || {});
    for (let i = 0; i < keys.length; i++) {
      locationState[keys[i]] = state[keys[i]];
    }
    return locationState;
  }, [state]);

  return currentState as Partial<T>;
};
