import { createContext, useContext } from 'react';

import { noop } from '@/_shared';

import type { OnboardingContextType } from './types';

export const OnboardingContext = createContext<OnboardingContextType>({
  showOnboardingVideo: false,
  showVideo: noop,
  hideVideo: noop,
});

export const useOnboardingContext = () => useContext(OnboardingContext);
