import { useEffect } from 'react';

import { useBoolean, useDebounce } from 'atp-react-ui';

export function useIsMounted(props?: { delay: number }) {
  const [isMounted, mount] = useBoolean();
  const delayedMount = useDebounce(isMounted, props?.delay || 0);

  useEffect(() => {
    mount.setTrue();

    return () => {
      mount.setFalse();
    };
  }, [mount]);

  return props?.delay ? delayedMount : isMounted;
}
