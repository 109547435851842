import { Skeleton } from 'atp-react-ui';

import { InputSkeleton } from '@/_components';
import AchieveLogo from '@/_shared/img/achieve-logo.svg?react';

export default function Loading() {
  return (
    <div className="flex flex-col gap-y-6">
      <div className="self-center">
        <AchieveLogo />
      </div>

      <div className="flex flex-col space-y-4">
        <Skeleton className="h-6 w-14 self-center" />

        <InputSkeleton />
        <InputSkeleton />

        <Skeleton className="h-6 w-28" />
        <div>
          <Skeleton className="mt-2 h-[2.625rem] w-full" />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex-1 border-b border-outline" />
        <Skeleton className="h-5 w-28" />
        <div className="flex-1 border-b border-outline" />
      </div>

      <div className="flex flex-col gap-y-6">
        <div className="flex flex-row items-center justify-evenly gap-2">
          <div className="w-1/2 shrink-0 grow-0">
            <Skeleton className="mt-2 h-[2.625rem] w-full" />
          </div>
          <div className="w-1/2 shrink-0 grow-0">
            <Skeleton className="mt-2 h-[2.625rem] w-full" />
          </div>
        </div>

        <Skeleton className="h-8 w-32 self-center" />
      </div>
    </div>
  );
}
