import { Skeleton } from 'atp-react-ui';

import { Container, StickyPageHeader } from '@/_components';
import { HeadersTabWrapper } from '@/_containers';

import { Loading as CourseSectionLoading } from '../components';

export default function CoursesLoading() {
  return (
    <>
      <StickyPageHeader threshold={30} className="top-9 pb-0">
        <Container>
          <HeadersTabWrapper>
            {[0, 1, 2].map((tab) => (
              <div key={tab} className="py-2">
                <Skeleton className="h-8 w-28" />
              </div>
            ))}
          </HeadersTabWrapper>
        </Container>
      </StickyPageHeader>
      <CourseSectionLoading withTitle />
      <CourseSectionLoading withTitle />
      <CourseSectionLoading withTitle />
    </>
  );
}
