import { config } from '@/_config';

import type { MailingAddress } from '../../_providers/hooks';

export function doubleNumber(oNum: string | number) {
  const num = `${oNum}`;
  const x = parseInt(`${num}`, 10) > 9 || num === '00' ? `${num}` : `0${num}`;

  if (parseInt(x, 10) === 0) {
    return '00';
  }
  return x;
}

export const toHourMinutesFormatting = (duration = 0, isInMilliseconds = false) => {
  const mins = (isInMilliseconds ? duration / 1000 : duration) / 60;
  const hours = parseInt(`${mins / 60}`, 10);
  const minutes = parseInt(`${mins % 60}`, 10);

  const formatted = `${doubleNumber(hours)}:${doubleNumber(minutes)}`;

  return {
    hour: hours,
    minutes,
    formatted,
  };
};

const oPluralize = (num: string | number, word: string, plural = `${word}s`) => {
  const newNumber = Number(num);
  const newWord = [1, -1].includes(newNumber) ? word : plural;
  return `${num} ${newWord}`;
};

export const pluralize = (val: string | number, word: string, plural = `${word}s`) => {
  if (typeof val === 'object') {
    return (num: string | number, nWord: string) => oPluralize(num, nWord, val[nWord]);
  }
  return oPluralize(val, word, plural);
};

export const mailingAddressToString = ({
  mailing_street,
  mailing_city,
  mailing_state,
  mailing_postal_code,
  mailing_country,
}: MailingAddress): string => {
  const addressParts: (string | undefined | null)[] = [
    mailing_street,
    mailing_city,
    mailing_state && mailing_postal_code
      ? `${mailing_state} ${mailing_postal_code}`
      : mailing_state || mailing_postal_code,
    mailing_country,
  ].filter(Boolean); // Filter out any undefined or empty strings

  return addressParts.join(', ');
};

export const getFormattedCurrency = (amount: number, withDecimal = false, rounded = false) => {
  if (!amount && amount !== 0) return '';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: withDecimal ? 2 : 0,
  });

  return formatter.format(rounded ? Math.round(amount) : amount);
};

export function removeNullIfAny<T extends object>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== null && value !== undefined)
  ) as Partial<T>;
}

export function formatCreditCardNumber(input: string): string {
  // Remove all non-digit characters, if any
  const digitsOnly = input.replace(/\D/g, '');

  // Insert a space after every 4th digit
  const formatted = digitsOnly.replace(/(\d{4})(?=\d)/g, '$1 ');

  return formatted;
}

export function getImageSources(str: string) {
  const source = [];
  const div = document.createElement('div');
  div.innerHTML = str;
  const imgNodes = div.getElementsByTagName('img');
  for (let i = 0; i < imgNodes.length; i++) {
    const imgSrc = imgNodes[i].getAttribute('src');
    if (imgSrc) source.push(imgSrc);
  }

  return source;
}

export function extractContent(s: string) {
  const span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

export function isValidQuestionForSubmission(question: string, minQuestionLength = 15) {
  return extractContent(question).length >= minQuestionLength;
}

export function isInViewport(element: HTMLElement | null) {
  if (!element) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...funcArgs: Parameters<T>) => void {
  let timeoutId: number | undefined;

  return (...args: Parameters<T>) => {
    const later = () => {
      timeoutId = undefined;
      func(...args);
    };

    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(later, wait);
  };
}

export function keyBy<
  A extends object,
  K extends keyof {
    [P in keyof A as A[P] extends PropertyKey ? P : never]: unknown;
  },
>(array: A[], key: K) {
  return array.reduce(
    (r, x) => ({ ...r, [x[key] as unknown as PropertyKey]: x }),
    {} as { [P in A[K] as A[K] extends PropertyKey ? A[K] : never]: A }
  );
}

export default keyBy;

export const noop = () => {};

export function extractHttpsLink(text: string | null | undefined): string | null {
  if (!text) {
    return null;
  }
  const urlRegex = /https?:\/\/[^\s<]+/g;
  const matches = text.match(urlRegex);
  return matches && matches[0] ? matches[0] : null;
}

export function shuffleArr<T>(array: T[]) {
  const nArr = [...array];
  for (let i = nArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = nArr[i];
    nArr[i] = nArr[j];
    nArr[j] = temp;
  }
  return nArr;
}

export const roundTo = (num: number, decimalPlaces = 2) => {
  const factorOfTen = 10 ** decimalPlaces;
  return Math.round(num * factorOfTen) / factorOfTen;
};

export function extractDomain(url: string): string {
  const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\\/\n]+)/im;
  const matches = url.match(domainRegex);
  if (matches && matches[1]) {
    const domain = matches[1];
    const parts = domain.split('.');
    if (parts.length > 2) {
      return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }
    return domain;
  }
  return '';
}

export function log(...args: unknown[]) {
  if (config.isProduction) {
    return;
  }
  console.log(...args);
}

export function pathToClassName(url: string) {
  return url.replace(/\//g, '-').replace(/^-/, '');
}
