import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient } from '@/_services';
import type { ApiError } from '@/_services';

const PrintLineItemSchema = z.object({
  id: z.number(),
  title: z.string(),
  printable_normalization: z.object({
    interior: z.object({
      source_file: z.null(),
      normalized_file: z.null(),
      source_url: z.string(),
      source_md5sum: z.null(),
      job_id: z.null(),
      page_count: z.null(),
    }),
    cover: z.object({
      source_file: z.null(),
      normalized_file: z.null(),
      source_url: z.string(),
      source_md5sum: z.null(),
      job_id: z.null(),
      page_count: z.null(),
    }),
    pod_package_id: z.string(),
  }),
  status: z.object({
    name: z.string(),
    messages: z.object({ info: z.string() }),
  }),
  is_reprint: z.boolean(),
  thumbnail_url: z.string(),
  external_id: z.string(),
  quantity: z.number(),
  printable_id: z.null(),
  order_line_item_id: z.null(),
  tracking_id: z.string(),
  tracking_urls: z.null(),
  pod_package_id: z.string(),
});

const PrintCostSchema = z.object({
  shipping_cost: z.null(),
  line_item_costs: z.null(),
  total_cost_excl_tax: z.null(),
  total_tax: z.null(),
  total_cost_incl_tax: z.null(),
  currency: z.null(),
  fees: z.array(z.unknown()),
});

const PrintShippingAddressSchema = z.object({
  user_id: z.string(),
  organization: z.null(),
  street1: z.string(),
  street2: z.null(),
  city: z.string(),
  postcode: z.string(),
  phone_number: z.string(),
  email: z.null(),
  is_business: z.boolean(),
  name: z.string(),
  state_code: z.string(),
  country_code: z.string(),
});

const PrintingStatusSchema = z.object({
  name: z.string(),
  message: z.string(),
  changed: z.string(),
});

export const CreatePrintJobApiResponseSchema = z.object({
  id: z.number(),
  line_items: z.array(PrintLineItemSchema),
  shipping_level: z.string(),
  shipping_option_id: z.string(),
  status: PrintingStatusSchema,
  costs: PrintCostSchema,
  parent_job_id: z.null(),
  child_job_ids: z.array(z.unknown()),
  estimated_shipping_dates: z.null(),
  reprints: z.array(z.unknown()),
  is_cancellable: z.boolean(),
  order_id: z.null(),
  date_created: z.string(),
  date_modified: z.string(),
  external_id: z.string(),
  dropship_profile_id: z.string(),
  contact_email: z.string(),
  production_delay: z.number(),
  production_due_time: z.null(),
  aggregated_payment_print_job: z.boolean(),
  aggregated_deposit_print_job: z.boolean(),
  tax_country: z.null(),
  order_marked_as_paid: z.null(),
  payments: z.array(z.unknown()),
  shipping_address: PrintShippingAddressSchema,
  shipping_option_level: z.string(),
});

export type CreatePrintJobApiResponseType = z.infer<typeof CreatePrintJobApiResponseSchema>;

export type CreatePrintJobApiPayload = {
  course_id: string;
  shipping_address: {
    city: string;
    country_code: string;
    name: string;
    phone_number: string;
    postcode: string;
    state_code: string;
    street1: string;
  };
  is_first_order: boolean;
  shipping_level: string;
  amount: string;
  creditcard_id: number;
  requested_from: 'COURSE_ENROLMENT' | 'REGISTERED_COURSE';
};

export const useCreatePrintBookRequestMutation = (
  mutationOptions: UseMutationOptions<CreatePrintJobApiResponseType, ApiError, CreatePrintJobApiPayload> = {}
) => {
  const { mutateAsync, ...options } = useMutation<CreatePrintJobApiResponseType, ApiError, CreatePrintJobApiPayload>({
    ...mutationOptions,
    mutationFn: async (payload) => apiClient.post({ endpoint: '/lulu/print-jobs/', payload }),
  });

  return [mutateAsync, options] as const;
};
