import { useButton } from 'atp-react-ui';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import AtpCharLogoIcon from '@/_shared/img/atp-char-dark-logo.svg?react';

import Container from './container';
import { StickyHeaderWithSubtitle } from './sticky-header-with-subtitle';

export function PublicPageHeader({ title, description }: { title: string; description?: string }) {
  const linkBtn = useButton({ as: 'link', className: 'size-12 p-0' });

  return (
    <>
      <Container className="flex h-20 items-end justify-between">
        <Link to="/" className={twMerge(linkBtn.buttonStyles, 'hover:bg-transparent focus:ring-0')}>
          <span className="sr-only">Prep Portal logo</span>
          <AtpCharLogoIcon />
        </Link>
      </Container>
      <StickyHeaderWithSubtitle title={title} subtitle={description} className="top-6" />
    </>
  );
}
