import type { HTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

export function TwoColumn(props: HTMLAttributes<HTMLDivElement>) {
  return <article {...props} className="grid grid-cols-1 gap-4 lg:grid-flow-col-dense lg:grid-cols-3" />;
}

export function LeftCell({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <section
      {...rest}
      aria-labelledby="description"
      className={twMerge('space-y-4 lg:col-span-1 lg:col-start-1', className)}
    />
  );
}

export function RightCell(props: HTMLAttributes<HTMLDivElement>) {
  return <section {...props} className="lg:col-span-2 lg:col-start-2" />;
}

export function TableCell({ isLast, className, ...props }: HTMLAttributes<HTMLTableCellElement> & { isLast: boolean }) {
  return (
    <td
      {...props}
      className={twMerge(isLast ? '' : 'border-b border-outline', 'table-cell whitespace-nowrap px-3 py-4', className)}
    />
  );
}
