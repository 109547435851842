import React, { useRef, forwardRef, useImperativeHandle, useCallback } from 'react';

import { Spinner } from 'atp-react-ui';
import { twMerge } from 'tailwind-merge';

import defaultPic from '@/_shared/img/default-profile.png';

import { AvatarBase } from './avatar-base';
import type { AvatarFallbackProps } from './avatar-fallback';
import { AvatarFallback } from './avatar-fallback';
import type { AvatarImageProps } from './avatar-image';
import { AvatarImage } from './avatar-image';

export type AvatarProps = {
  size?: 'xs' | 'lg';
  imageUrl?: string;
  avatarImageProps?: AvatarImageProps;
  fallbackLabel?: string;
  avatarFallbackProps?: AvatarFallbackProps;
  className?: string;
  disableEditing?: boolean;
  disableChangeAvatar?: boolean;
  isLoading?: boolean;
  onFileChange?: (file: File) => void;
};

export type AvatarRefType = {
  handleEdit: () => void;
  reset: () => void;
};

const Avatar = forwardRef<AvatarRefType, AvatarProps>(
  (
    {
      size = 'lg',
      imageUrl,
      fallbackLabel,
      avatarImageProps,
      avatarFallbackProps,
      className,
      disableEditing = false,
      isLoading = false,
      disableChangeAvatar = false,
      onFileChange,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleEdit = useCallback(() => {
      inputRef.current?.click();
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files && files.length > 0) {
        onFileChange?.(files[0]);
      }
    };

    const reset = () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    useImperativeHandle(ref, () => ({ handleEdit, reset }), [handleEdit]);

    const showDefaultImage = !imageUrl && !fallbackLabel;

    return (
      <>
        <AvatarBase
          className={twMerge(
            'group relative flex size-16 shrink-0 grow-0 ring-2 ring-primary ring-offset-2',
            size === 'xs' && 'size-8 ring-0 hover:ring-2',
            className
          )}
        >
          <AvatarImage src={showDefaultImage ? defaultPic : imageUrl} {...avatarImageProps} />

          {fallbackLabel && (
            <AvatarFallback {...avatarFallbackProps}>{fallbackLabel.toUpperCase().slice(0, 2)}</AvatarFallback>
          )}

          {isLoading ? (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60">
              <Spinner size="xs" />
            </div>
          ) : (
            <div
              className={twMerge(
                'profile-button pointer-events-none absolute hidden h-full w-full cursor-pointer items-center justify-center bg-black bg-opacity-60 text-white',
                !disableEditing ? 'pointer-events-auto group-hover:flex' : ''
              )}
              onClick={disableChangeAvatar ? undefined : handleEdit}
            >
              Edit
            </div>
          )}
        </AvatarBase>
        <input ref={inputRef} type="file" className="hidden" accept=".jpg, .jpeg, .png" onChange={handleChange} />
      </>
    );
  }
);

Avatar.displayName = 'Avatar';

export { Avatar };
