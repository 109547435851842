/* eslint-disable react-refresh/only-export-components */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import { noop } from '@/_shared';

type SurveyContextType = {
  error: boolean;
  setError: (error: boolean) => void;
  required: boolean;
  setRequired: (required: boolean) => void;
};

export const SurveyContext = createContext<SurveyContextType>({
  error: false,
  required: false,
  setError: noop,
  setRequired: noop,
});

export function SurveyProvider({ children }: { children: ReactNode }) {
  const [error, setError] = useState(false);
  const [required, setRequired] = useState(false);

  const value = useMemo(() => ({ error, setError, required, setRequired }), [error, required]);

  return <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>;
}

export const useSurveyContext = () => {
  const context = useContext(SurveyContext);

  if (!context) {
    throw new Error('useSurveyContext must be used within a SurveyProvider');
  }

  return context;
};
