import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { queryClient, apiClient } from '@/_services';

export const courseSurveyQuerySchema = z.object({
  id: z.string(),
  course_id: z.string(),
  course_name: z.string(),
  survey: z
    .object({
      allow: z.boolean(),
      stage: z.number(),
    })
    .nullable()
    .optional(),
});

export type CourseSurveyQuerySchema = z.infer<typeof courseSurveyQuerySchema>;

export const courseSurveyQueryAllSchema = z.array(courseSurveyQuerySchema);

export type CourseSurveyQueryAllSchema = z.infer<typeof courseSurveyQueryAllSchema>;

const getQueryKey = () => ['COURSE_SURVEYS'];

export const useCourseSurveyQuery = () =>
  useQuery<CourseSurveyQueryAllSchema>({
    queryKey: getQueryKey(),
    queryFn: async () =>
      apiClient.request({
        endpoint: '/course/surveys/',
      }),
    select: (data) => data.filter((survey) => survey.survey?.allow),
  });

export const invalidateCourseSurveys = () => queryClient.invalidateQueries({ queryKey: getQueryKey() });
