import React from 'react';

import type { CookieChangeListener } from 'universal-cookie';

import { CookieManager } from '@/_services';

import type { TUserDataModel } from '../me-provider/model';

import { useAuth } from './use-auth';

export const useUser = () => {
  const { logout } = useAuth();
  const user = {} as TUserDataModel; // TODO: Get this from the api
  const [isLoading] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState(CookieManager.getAccessToken());
  const [refreshToken, setRefreshToken] = React.useState(CookieManager.getRefreshToken());

  React.useEffect(() => {
    const listenCookiesChange: CookieChangeListener = (cookie) => {
      if (CookieManager.matchKeys(cookie.name, CookieManager.ACCESS_TOKEN_KEY)) {
        setAccessToken(cookie.value);
      } else if (CookieManager.matchKeys(cookie.name, CookieManager.REFRESH_TOKEN_KEY)) {
        setRefreshToken(cookie.value);
      }
    };

    CookieManager.cookies.addChangeListener(listenCookiesChange);

    return () => {
      CookieManager.cookies.removeChangeListener(listenCookiesChange);
    };
  }, []);

  return {
    user,
    isLoading,
    isLoggedIn: CookieManager.isLoggedIn(),
    accessToken,
    refreshToken,
    logout,
  };
};
