import { Input, Text, twMerge } from 'atp-react-ui';

import { TnCSection } from '@/_prep-portal/terms/components/sections/section';
import { SectionLayout } from '@/_prep-portal/terms/components/sections/section-layout';

export function WellcoachesAgreementTerms({
  name,
  actualFullName,
  date,
  error,
  handleNameChange,
}: {
  name: string;
  actualFullName: string;
  date: string;
  error?: string;
  handleNameChange: (_: string) => void;
}) {
  return (
    <SectionLayout>
      <Text as="h3" className="text-accent">
        Wellness Coaching Program Service Agreement
      </Text>
      <TnCSection title="Description of Coaching Services">
        Welcome to our Wellness Coaching Program! In addition to our Tutoring, Mentoring and Dedicated Advisors that
        support your educational knowledge and skills, we have partnered with the leaders in wellness coaching,
        Wellcoaches, to support your overall well-being. This Agreement shares details of this Program and the
        expectations of each participant.
      </TnCSection>
      <TnCSection title="Coaching is Optional">
        Wellcoaches services are completely optional and must be opted into by an Achieve customer. While coaching aids
        in personal and professional development, it is not the same as services like therapy, financial planning,
        career counseling, and other specialized services. As a client, you are encouraged to make your own decisions
        and can reject any advice from your coach.
      </TnCSection>
      <TnCSection title="Client Commitments">
        You are fully responsible for your physical, mental, and emotional well-being during coaching, including your
        decisions. Coaching will touch various life areas—work, health, relationships, etc.—but it does not include
        medical or psychiatric treatment. It's essential to consult appropriate professionals for legal, medical, or
        financial issues, as coaching does not replace professional advice in these fields.
      </TnCSection>
      <TnCSection title="Partnership Notice">
        Achieve Test Prep and Wellcoaches are separate entities. This partnership allows Wellcoaches to offer practical
        experience to its newer coaches while providing guidance to you, our customer. If concerns arise related to
        Achieve Test Prep, such as course content or payments, please contact your Dedicated Advisor, as your Wellcoach
        will not have knowledge in these areas.
      </TnCSection>
      <TnCSection title="Confidentiality">
        Your privacy is paramount. Wellcoaches and your coach will maintain the confidentiality of all personal
        information shared during sessions, accessible only to your coach and Wellcoaches' administrative staff.
        Personal data will not be disclosed without your written consent, adhering to federal HIPAA guidelines.
      </TnCSection>
      <TnCSection title="Release of Liability">
        By participating in coaching, you waive any claims against your coach and Wellcoaches for injuries or damages
        related to coaching activities. You acknowledge the potential risks of exercise suggested during coaching and
        accept all responsibility for your participation.
        <br />
        <br />
        Please ensure you've understood all parts of this agreement before proceeding.
        <br />
        <br />
        <div className="flex flex-row items-center justify-start">
          <div className="flex flex-col">
            <span>Client Signature: </span>
            <p className="invisible mt-1 text-xs">Error placeholder</p>
          </div>
          <div>
            <Input
              value={name}
              placeholder={actualFullName}
              onChange={(e) => handleNameChange(e.target.value)}
              className="ml-2 w-48 rounded-none border-0 border-b-[1px] focus:ring-0"
            />
            <p className={twMerge('ml-2 mt-1 text-xs text-error', !error ? 'invisible' : 'visible')}>
              {error || 'placeholder'}
            </p>
          </div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-start">
          Date: <Input value={date} disabled className="ml-2 w-48 rounded-none border-0 border-b-[1px] focus:ring-0" />
        </div>
        <br />
        <br />
        This streamlined agreement ensures clarity about the services and boundaries of coaching while emphasizing
        privacy and responsibility.
      </TnCSection>
    </SectionLayout>
  );
}
