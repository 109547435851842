import { Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

export default function Loading() {
  return (
    <div className="flex h-full w-full flex-col">
      <Container>
        <PageHead className="mt-1 flex-col items-start">
          <Skeleton className="h-4 w-44" />

          <section className="mt-3 flex items-center">
            <Skeleton className="mr-4 size-8" />
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="mt-3 flex items-center gap-2">
            <Skeleton className="ml-12 h-4 w-28" />
          </div>
        </PageHead>
      </Container>

      <Container className="mt-16 flex h-[16.4375rem] flex-col">
        <Skeleton className="-mt-0.5 h-4 w-2/3 md:w-1/3" />
        <Skeleton className="mt-5 h-6 w-full" />
      </Container>

      <Container className="mb-6 mt-16 flex items-center justify-end gap-4 pt-2">
        <Skeleton className="h-10 w-11" />
        <Skeleton className="mr-0.5 h-4 w-24" />
        <Skeleton className="h-10 w-24" />
      </Container>
    </div>
  );
}
