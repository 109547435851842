import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import type { PaginatedApiResponse } from '@/_services';
import { queryClient, apiClientAod } from '@/_services';

export const OdtQuestionResponseSchema = z.object({
  id: z.string(),
  customer_id: z.string(),
  customer_name: z.string(),
  course_id: z.string(),
  course_name: z.string(),
  title: z.string(),
  body: z.string(),
  attachments: z.array(z.string()).nullable(),
  parent_question_id: z.string().nullable(),
  answers_count: z.number(),
  unread_answers_count: z.number(),
  resolved_by: z.unknown().nullable(),
  resolved: z.boolean(),
  has_follow_up: z.boolean(),
  answered_on: z.unknown().nullable(),
  notified_on: z.unknown().nullable(),
  created_on: z.string(),
  modified_on: z.string(),
});
export type OdtQuestionResponseType = z.infer<typeof OdtQuestionResponseSchema>;

export type OdtQuestionsApiResponseType = PaginatedApiResponse<OdtQuestionResponseType>;

type OdtQuestionsApiParams = {
  is_instructor: boolean;
  page_size: number;
  course_id: string | null;
};

type OdtQuestionsApiProps = { courseId: string | null | undefined };

const getQueryKey = ({ courseId }: OdtQuestionsApiProps) => ['ODT_QUESTIONS', courseId];

export const useOdtQuestionsQuery = ({ courseId }: OdtQuestionsApiProps) =>
  useSuspenseQuery({
    queryKey: getQueryKey({ courseId }),
    queryFn: async () =>
      apiClientAod.request<OdtQuestionsApiResponseType, OdtQuestionsApiParams>({
        endpoint: `/questions/`,
        params: {
          is_instructor: false,
          page_size: 10000000,
          course_id: courseId || null,
        },
      }),
    refetchInterval: 60000,
  });

export const invalidateOdtQuestionsQuery = ({ courseId }: OdtQuestionsApiProps) =>
  queryClient.invalidateQueries({ queryKey: getQueryKey({ courseId }) });
