import { Text } from 'atp-react-ui';

import Container from './container';
import PageHead from './page-head';
import { StickyPageHeader } from './sticky-header';

type StickyHeaderWithSubtitleProps = {
  title: string;
  subtitle?: string;
  className?: string;
};

export function StickyHeaderWithSubtitle({ title, subtitle, className }: StickyHeaderWithSubtitleProps) {
  return (
    <StickyPageHeader className={className}>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex items-center">
            <Text as="h1">{title}</Text>
          </section>

          {subtitle ? (
            <Text as="caption-medium" className="pt-3 text-accent-light/80">
              {subtitle}
            </Text>
          ) : null}
        </PageHead>
      </Container>
    </StickyPageHeader>
  );
}
