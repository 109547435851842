import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

const Container = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ id?: string; fullWidth?: boolean; className?: string }>
>(({ children, id, className = '', fullWidth = false }, ref) => (
  <main
    ref={ref}
    id={id}
    className={twMerge(
      'container',
      'mx-auto px-6 sm:px-4 md:px-6 pre-lg:px-4 lg:px-11 pre-xl:px-4 xl:px-3',
      fullWidth
        ? 'max-w-full'
        : 'max-w-full sm:max-w-[44rem] md:max-w-[48rem] pre-lg:max-w-[60rem] lg:max-w-none xl:max-w-7xl',
      className
    )}
  >
    {children}
  </main>
));

Container.displayName = 'Container';

export default Container;
