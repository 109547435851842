import BookOpenIcon from '@heroicons/react/24/outline/BookOpenIcon';
import CheckCircleIcon from '@heroicons/react/24/outline/CheckCircleIcon';
import ClipboardDocumentCheckIcon from '@heroicons/react/24/outline/ClipboardDocumentCheckIcon';
import ClipboardDocumentListIcon from '@heroicons/react/24/outline/ClipboardDocumentListIcon';
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon';
import InformationCircleOutlineIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import PencilSquareIcon from '@heroicons/react/24/outline/PencilSquareIcon';
import TrashIcon from '@heroicons/react/24/outline/TrashIcon';
import XCircleIcon from '@heroicons/react/24/outline/XCircleIcon';
import CalendarIcon from '@heroicons/react/24/solid/CalendarIcon';
import CheckBadgeIcon from '@heroicons/react/24/solid/CheckBadgeIcon';
import { Button, Icon, Text, Tooltip, useBoolean } from 'atp-react-ui';
import { Link } from 'react-router-dom';

import { ROUTES } from '@/_navigation';
import { dateTime } from '@/_shared';

import { CourseDependencyModal } from './components';
import { type PrepCourseFooterProps } from './types';

export function PrepCourseFooter({
  surveyId,
  surveyType,
  status,
  examDate,
  blockExam = false,
  isSpanish2Taken = false,
  actionCallback,
}: PrepCourseFooterProps) {
  const [showBlocked, blocked] = useBoolean();

  const handleScheduleExam = () => {
    if (blockExam) {
      blocked.setTrue();
      return;
    }
    actionCallback('SCHEDULE');
  };

  let content = null;

  if (status === 'UN_SCHEDULED') {
    content = (
      <footer className="flex-shrink-0 bg-secondary px-2 py-2">
        <Tooltip zIndex={2} content="Schedule exam" placement="top-start">
          <Button
            block
            theme="secondary"
            className="schedule-exam-button justify-between px-4 py-2 focus:ring-0"
            iconRight={blockExam ? ExclamationTriangleIcon : CalendarIcon}
            onClick={handleScheduleExam}
          >
            Schedule your Exam
          </Button>
        </Tooltip>
      </footer>
    );
  } else if (status === 'WAITING') {
    content = (
      <footer className="flex flex-shrink-0 items-center justify-between bg-gray-75 py-2 pl-2 pr-3">
        <Text as="caption-medium-bold" className="px-4 text-accent">
          Exam scheduled for:
          <Text as="caption" className="text-accent-light">
            {dateTime.format(examDate, 'dddd, YYYY-MM-DD')}
          </Text>
        </Text>
        <div className="flex items-center">
          <Tooltip zIndex={2} content="Reschedule exam" placement="top-start">
            <Button
              size="md"
              onClick={() => actionCallback('RESCHEDULE')}
              className="bg-gray-75 text-accent focus:ring-0"
              icon={PencilSquareIcon}
            />
          </Tooltip>
          <Tooltip zIndex={2} content="Delete exam schedule" placement="top-start">
            <Button
              size="md"
              onClick={() => actionCallback('REMOVE_DATE')}
              className="bg-gray-75 text-accent focus:ring-0"
              icon={TrashIcon}
            />
          </Tooltip>
        </div>
      </footer>
    );
  } else if (status === 'SCHEDULED') {
    content = (
      <footer className="flex flex-shrink-0 items-center justify-between bg-secondary py-2 pl-2 pr-3">
        <Text as="caption-medium-bold" className="px-4 text-white">
          Did you pass?
        </Text>

        <div className="flex items-center">
          <Tooltip zIndex={2} content="Pass" placement="top-start">
            <Button
              size="md"
              onClick={() => actionCallback('SUBMIT_RESULT_PASS')}
              theme="secondary"
              className="focus:ring-0"
              icon={CheckCircleIcon}
            />
          </Tooltip>
          <Tooltip zIndex={2} content="Fail" placement="top-start">
            <Button
              size="md"
              onClick={() => actionCallback('SUBMIT_RESULT_FAIL')}
              className="focus:ring-0"
              theme="secondary"
              icon={XCircleIcon}
            />
          </Tooltip>
          <div className="mx-2 h-5 w-px rounded border border-white" />
          <Tooltip zIndex={2} content="Reschedule exam" placement="top-start">
            <Button
              size="md"
              onClick={() => actionCallback('RESCHEDULE')}
              className="focus:ring-0"
              theme="secondary"
              icon={PencilSquareIcon}
            />
          </Tooltip>
        </div>
      </footer>
    );
  } else if (status === 'PASSED') {
    content = (
      <footer className="flex flex-shrink-0 items-center justify-between bg-success px-2 py-3.5">
        <Text as="caption-medium-bold" className="px-4 py-1 text-white">
          You passed! Congrats!!!
          <Text as="caption">{examDate}</Text>
        </Text>
        <Icon icon={CheckBadgeIcon} className="mr-3 size-6 text-white" />
      </footer>
    );
  } else if (status === 'FAILED') {
    content = (
      <footer className="flex flex-shrink-0 items-center justify-between bg-warning-x-light px-2 py-2.5">
        <Text as="caption-medium" className="px-4 text-accent-light">
          No worries! A mentor will contact you in the next 48 hours
        </Text>
        <Icon icon={InformationCircleOutlineIcon} className="mr-3 text-accent-medium" />
      </footer>
    );
  } else if (status === 'ABOUT_TO_START') {
    content = (
      <footer className="flex-shrink-0 bg-secondary px-2 py-2">
        <Tooltip zIndex={2} content="Pre-course Material" placement="top-start">
          <Button block theme="secondary" className="justify-between px-4 py-2 focus:ring-0" iconRight={BookOpenIcon}>
            Pre-course Material
          </Button>
        </Tooltip>
      </footer>
    );
  } else if (status === 'SURVEY') {
    let surveyTitle = `How is your course going so far?`;
    if (surveyType === 2) {
      surveyTitle = `Congrats on completing your course.`;
    } else if (surveyType === 3) {
      surveyTitle = `Thanks for sharing your exam result.`;
    }

    content = (
      <footer className="flex-shrink-0 bg-secondary px-2 py-1.5">
        <Link
          to={ROUTES.COURSE_SURVEY.buildPath({
            surveyId: surveyId || '',
            surveyType: surveyType || 0,
          })}
        >
          <Button
            block
            theme="secondary"
            className="justify-between px-1.5 py-0.5 focus:ring-0"
            iconRight={ClipboardDocumentListIcon}
          >
            <div className="text-left">
              <div>{surveyTitle}</div>
              <Text as="caption" className="mb-px">
                Please share your feedback.
              </Text>
            </div>
          </Button>
        </Link>
      </footer>
    );
  } else {
    content = (
      <footer className="flex-shrink-0 bg-secondary px-2 py-2">
        <Tooltip zIndex={2} content="Take Pre-course quiz" placement="top-start">
          <Button
            block
            theme="secondary"
            className="justify-between px-4 py-2 focus:ring-0"
            iconRight={ClipboardDocumentCheckIcon}
            // onClick={() => actionCallback('')}
          >
            Take Pre-course quiz
          </Button>
        </Tooltip>
      </footer>
    );
  }

  return (
    <>
      {content}
      <CourseDependencyModal open={showBlocked} onClose={blocked.setFalse} isSpanish2Taken={isSpanish2Taken} />
    </>
  );
}
