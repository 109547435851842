export const scheduledTimes = [
  {
    id: '-1',
    name: 'Lindsay Walton',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-2',
    name: 'Dr. Danial Wolf',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-3',
    name: 'Anne Wade',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-4',
    name: 'Tanzeel Qumber',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-5',
    name: 'Wadood Ahmed',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-6',
    name: 'Zain Raza',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-7',
    name: 'Shane Best',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-8',
    name: 'Donald White',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-9',
    name: 'Kate Blanchet',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-10',
    name: 'Lindsay Walton',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
  {
    id: '-11',
    name: 'Miley Jones',
    fromDate: '06/11',
    toDate: ' 07/15/2022',
    day: 'Monday',
    startTime: '10:30 am',
    endTime: '11:30 am',
  },
];
