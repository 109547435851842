import ArrowLeftIcon from '@heroicons/react/24/outline/ArrowLeftIcon';
import { Button, Text } from 'atp-react-ui';

import { BreadCrumbs, Container, PageHead, StickyPageHeader } from '@/_components';
import type { RouteRecordsType } from '@/_navigation';
import { ROUTES } from '@/_navigation';
import { useBackNavigation } from '@/_shared';

const pages = [ROUTES.ON_DEMAND_TUTORING, ROUTES.ON_DEMAND_TUTORING_QUESTION] as RouteRecordsType[];

export function Header() {
  const { onBack } = useBackNavigation({
    pathname: ROUTES.ON_DEMAND_TUTORING_AWAITING.path,
  });

  return (
    <StickyPageHeader>
      <Container>
        <PageHead className="flex-col items-start">
          <BreadCrumbs pages={pages} />
          <section className="mt-2 flex items-center">
            <Button icon={ArrowLeftIcon} size="sm" as="link" className="mr-4" onClick={onBack} />
            <Text as="h1">View Question</Text>
          </section>
        </PageHead>
      </Container>
    </StickyPageHeader>
  );
}
