import CopyIcon from '@heroicons/react/24/outline/DocumentDuplicateIcon';
import { Button, Modal, ModalBody, ModalHeader, Text, useBoolean, useCopyToClipboard } from 'atp-react-ui';

import { googleAnalytics4, snackbar } from '@/_services';
import { dateTime } from '@/_shared';

import { TermsModal } from './terms-modal';

type ReferModalProps = {
  id: string;
  open: boolean;
  onClose: () => void;
};

export function ReferModal({ id, open, onClose }: ReferModalProps) {
  const [isTermsOpen, openTermsModal] = useBoolean();

  const { copy } = useCopyToClipboard();

  let link = '';
  if (open) {
    const { origin } = window.location;
    link = `${origin}/refer?refer_uid=${dateTime.now().unix()}QAL&uid=${id}&uid_stamp=${dateTime.now().valueOf()}`;
  }

  const handleURLCopy = async () => {
    const res = await copy(link);
    if (res) {
      googleAnalytics4.event({ category: 'copy_referral_link', action: 'copy_referral_link' });
      snackbar.success('Referral URL copied to Clipboard.', {
        position: 'top-right',
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>Refer a friend and get 20,000* points EASY !!!</ModalHeader>
      <ModalBody>
        <Text as="caption-medium" className="pl-4 leading-loose">
          <ul className="list-disc">
            <li>Copy and share the link provided below with your friends and family.</li>
            <li>Your referee will be asked to fill the Refer Program form</li>
            <li>Once we receive the information we will contact your referee</li>
            <li>
              You start earning points as soon as your referee registers for their first course.{' '}
              <span className="mt-4 font-bold italic">It’s that Easy!</span>
            </li>
          </ul>
        </Text>

        <Button onClick={openTermsModal.setTrue} as="link" size="sm" className="mt-4 p-1">
          <span className="text-primary underline">*Terms and conditions apply</span>
        </Button>

        <Text as="h6" className="mt-4">
          Start referring !!! Start earning !!!
        </Text>

        <div className="mt-6 flex gap-3">
          <input
            type="text"
            name="link"
            value={link}
            disabled
            className="border-divider focus:border-primary-main focus:ring-primary-main block w-full rounded-md border px-3 py-2 focus-visible:outline-0"
          />
          <Button className="w-28" size="sm" iconLeft={CopyIcon} as="contained" onClick={handleURLCopy}>
            Copy
          </Button>
        </div>

        <TermsModal open={isTermsOpen} onClose={openTermsModal.setFalse} />
      </ModalBody>
    </Modal>
  );
}
