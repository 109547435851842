import { lazy, Suspense } from 'react';

import type { DatepickerType, DateValueType } from 'react-tailwindcss-datepicker';

import { Loader } from './loader';

const TWDatepicker = lazy(() => import('react-tailwindcss-datepicker'));

type DatePickerProps = DatepickerType;

function DatePicker(props: DatePickerProps) {
  return (
    <Suspense fallback={<Loader />}>
      <TWDatepicker {...props} />
    </Suspense>
  );
}

export { type DateValueType, type DatePickerProps, DatePicker };
