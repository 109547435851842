import React from 'react';

import { InfiniteProgressBar } from '@/_components';
import type { RouteRecordsType } from '@/_navigation';
import { ROUTES } from '@/_navigation';
import { useAccountDependenciesContext, useMeContext } from '@/_providers';
import { isRewardsAllowed, RESTRICTIONS_TYPE } from '@/_shared/policies';

import Navbar from '../navbar';

export function Header() {
  const { data: meData, isLoadingMeData } = useMeContext();
  const { data: accountDepData, isLoading: isLoadingAccountDepData } = useAccountDependenciesContext();

  const allowedSecondaryLinks = React.useMemo(() => {
    if (!meData) {
      return [];
    }
    if (meData.accessType === RESTRICTIONS_TYPE.PPP_ACCESS_BLOCKED) {
      return [];
    }

    let secondaryLinks = [
      {
        ...ROUTES.IN_PROGRESS,
        META: { ...ROUTES.IN_PROGRESS.META, name: 'Courses' },
      },
      ROUTES.ON_DEMAND_TUTORING,
      ROUTES.REWARDS,
    ];

    if (!accountDepData?.aod_records.has_aod_enrollment) {
      secondaryLinks = secondaryLinks.filter((link) => link.path !== ROUTES.ON_DEMAND_TUTORING.path);
    }
    if (!isRewardsAllowed(accountDepData?.allow_rewards)) {
      secondaryLinks = secondaryLinks.filter((link) => link.path !== ROUTES.REWARDS.path);
    }

    return secondaryLinks as RouteRecordsType[];
  }, [accountDepData?.allow_rewards, accountDepData?.aod_records.has_aod_enrollment, meData]);

  const user = React.useMemo(
    () => ({
      name: meData?.name || '-',
      role: 'Customer',
      email: meData?.email || '-',
      imageUrl: meData?.image || '',
    }),
    [meData?.email, meData?.image, meData?.name]
  );

  const isLoading = isLoadingMeData || isLoadingAccountDepData;

  return (
    <>
      {isLoading && <InfiniteProgressBar className="fixed top-0 z-30" />}
      <Navbar secondaryLinks={allowedSecondaryLinks} user={user} />
    </>
  );
}
