import type { RouteRecordsType, RoutesKeyType } from './routes';
import ROUTES from './routes';

export const openNewTab = (url: string) => window.open(url, '_blank');

export const findRouteByURL = (url: string): RouteRecordsType => {
  const routeKeys = Object.keys(ROUTES);
  let oKey: string | undefined;
  for (let i = 0; i < routeKeys.length; i++) {
    const isActive = isActiveRoute(ROUTES[routeKeys[i] as RoutesKeyType].path, url);
    if (isActive) {
      oKey = routeKeys[i];
      break;
    }
  }

  return ROUTES[oKey as RoutesKeyType] as RouteRecordsType;
};

function isActiveRoute(routePattern: string, pathname: string): boolean {
  const segments = pathname.split('/').filter(Boolean);
  const patternSegments = routePattern.split('/').filter(Boolean);

  for (let i = 0; i < patternSegments.length; i++) {
    const segment = segments[i];
    const patternSegment = patternSegments[i];

    // If the pattern segment is a wildcard, the route matches
    if (patternSegment === '*') {
      return true;
    }

    // If there's a wildcard in the middle of the pattern, everything after is ignored
    if (patternSegment === '*' && i === patternSegments.length - 1) {
      return segments.length >= i;
    }

    // Dynamic segment (e.g., :id)
    if (patternSegment.startsWith(':')) {
      // It's a dynamic segment, ignore for matching

      continue;
    }

    // If the segment doesn't match the pattern and it's not a dynamic segment, return false
    if (segment !== patternSegment) {
      return false;
    }
  }

  // If all segments match and the lengths are the same, or if the pattern ends with a wildcard
  return (
    segments.length === patternSegments.length ||
    (patternSegments.length < segments.length && patternSegments[patternSegments.length - 1] === '*')
  );
}
