import type { PropsWithChildren } from 'react';

import { Card, CardBody } from 'atp-react-ui';

export function Layout({ children }: PropsWithChildren<unknown>) {
  return (
    <section className="flex h-screen justify-center overflow-auto bg-login bg-cover bg-center bg-no-repeat py-4 md:items-center md:py-10">
      <Card className="my-auto">
        <CardBody className="w-80 max-w-full px-6 py-10 xs:w-96 xs:p-14 sm:p-14">{children}</CardBody>
      </Card>
    </section>
  );
}
