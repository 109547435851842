import { createContext } from 'react';

import { noop } from '@/_shared';

import type { BookingsContextType } from './types';

export const BookingsContext = createContext<BookingsContextType>({
  topic: '',
  courseId: '',
  bookingType: undefined,
  from: '',
  setTopic: noop,
  setCourseId: noop,
  setBookingType: noop,
  setFrom: noop,
  reset: noop,
});
