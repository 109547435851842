import React, { useEffect } from 'react';

import { useBoolean } from 'atp-react-ui';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/_navigation';
import { ReferModal } from '@/_prep-portal/rewards/components';

import { useAccountDependencies } from '../hooks/use-account-dependencies';

import type { AccountDependenciesContextType } from './account-dependencies-context';
import { AccountDependenciesContext } from './account-dependencies-context';

export function AccountDependenciesProvider({ children }: { children: React.ReactNode }) {
  const nav = useNavigate();
  const { data, isLoading, isFetching, isSuccess } = useAccountDependencies();
  const { id, tutoring_taken } = data || {};
  const referToAFriendCtrl = useBoolean();

  const value: AccountDependenciesContextType = React.useMemo(() => {
    const { zinrelo_user_info } = data || {};
    const isRewardProgramActive = Object.keys(zinrelo_user_info || {}).length > 0;

    return {
      data,
      isLoading,
      isFetching,
      setReferralModal: referToAFriendCtrl[1].setTrue,
      isRewardProgramActive,
    };
  }, [data, isLoading, isFetching, referToAFriendCtrl]);

  useEffect(() => {
    if (tutoring_taken && isSuccess && !isFetching) {
      nav(ROUTES.POST_TUTORING_SURVEY.path);
    }
  }, [isFetching, isSuccess, nav, tutoring_taken]);

  return (
    <AccountDependenciesContext.Provider value={value}>
      {children}
      {id && <ReferModal id={id} open={referToAFriendCtrl[0]} onClose={referToAFriendCtrl[1].setFalse} />}
    </AccountDependenciesContext.Provider>
  );
}
