import type { PropsWithChildren, SyntheticEvent } from 'react';
import React from 'react';

import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import { Button, Modal, ModalBody, ModalFooter, Text } from 'atp-react-ui';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const slots = ['8:00AM-1:00PM', '1:00PM-6:00PM', '6:00PM-9:00PM'];
const slotsUI = ['8AM-1PM', '1PM-6PM', '6PM-9PM'];
const timingUI = ['Morning', 'Afternoon', 'Evening'];

interface Props {
  open: boolean;
  callback: (value: string) => void;
  value?: string;
}

export function ContactTimeSelector({ callback, value = '', open }: PropsWithChildren<Props>): JSX.Element {
  const [selected, setSelected] = React.useState(value || '');
  const selectedRef = React.useRef(selected);

  const singleSlot = selected === '' ? [] : selected.split(';');
  const singleSlotObjArr = singleSlot.map((slot) => {
    const [day, times] = slot.split(', ');
    const dayIndex = days.indexOf(day);
    const slotIndex = slots.indexOf(times);

    return { day, times, dayIndex, slotIndex };
  });

  const handleTimeChange = (day: string, slot: number) => (_: SyntheticEvent) => {
    const nSlot = slots[slot];
    const nValue = `${day}, ${nSlot}`;
    let values = singleSlotObjArr.map((s) => `${s.day}, ${s.times}`);

    if (values.includes(nValue)) {
      values = values.filter((v) => v !== nValue);
    } else {
      values.push(nValue);
    }
    const newValues = values.join(';');
    selectedRef.current = newValues;
    setSelected(newValues);
  };

  const handleDayChange = (_: SyntheticEvent) => {
    callback(selected);
  };

  React.useEffect(() => {
    if (selectedRef.current !== value) {
      setSelected(value);
    }
  }, [value]);

  return (
    <Modal open={open} disableInternalClosing hideCloseButton onClose={() => {}}>
      <ModalBody className="flex flex-col items-center text-center">
        <Text as="caption-medium" className="w-full text-left">
          Select at least three preferred time slots.
        </Text>

        {days.map((day) => {
          const selectedDay = singleSlotObjArr.filter((slot) => slot.day === day);

          return (
            <article className="mt-4 flex w-full items-center justify-between" key={day}>
              <div
                className={`${
                  selectedDay.length > 0 ? 'border-primary bg-primary' : 'border-outline'
                } flex size-11 flex-shrink-0 flex-grow-0 select-none items-center justify-center rounded-full border`}
              >
                <Text as="div" className={selectedDay.length > 0 ? 'text-white' : 'text-accent-light'}>
                  {day.substring(0, 3)}
                </Text>
              </div>

              {slots.map((slot, index) => {
                const selectedSlot = selectedDay.find((d) => d.slotIndex === index);
                return (
                  <Button
                    key={slot}
                    as={!selectedSlot ? 'link' : 'outline'}
                    className={`${
                      !selectedSlot ? '' : 'border-primary'
                    } flex-col px-3 py-0.5 leading-tight text-accent-light sm:px-5`}
                    onClick={handleTimeChange(day, index)}
                  >
                    <span className={!selectedSlot ? '' : 'text-primary'}>{timingUI[index]}</span>
                    <span className="text-xs font-normal">{slotsUI[index]}</span>
                  </Button>
                );
              })}
            </article>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button modal iconRight={ArrowRightIcon} onClick={handleDayChange}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
}
