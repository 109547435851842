import { PageHeader, ZinreloLoader } from './components';

export default function Loading() {
  return (
    <>
      <PageHeader />
      <ZinreloLoader />
    </>
  );
}
