import { CookieManager } from '@/_services/cookie-manager';
import { logger } from '@/_services/logger';
import { queryClient } from '@/_services/query-client';

export function logoutUser() {
  queryClient.removeQueries();
  CookieManager.removeAuthTokens();
  logger.clearSession();
}

export function checkOnboardingVideoShown(userId: string) {
  return localStorage.getItem(`onboarded-${userId}`) === 'true';
}

export function onboardingVideoShown(userId: string) {
  return localStorage.setItem(`onboarded-${userId}`, 'true');
}
