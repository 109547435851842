import { Card, CardBody, Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex items-center">
            <Skeleton className="mr-4 size-8" />
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="h-[1.125rem] w-64" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6">
        <section className="flex w-full flex-col gap-y-4 pre-xl:w-3/4">
          <OneRow />
          <OneRow />
          <OneRow />
          <OneRow />
          <OneRow />
        </section>
      </Container>
    </>
  );
}

function OneRow() {
  return (
    <Card>
      <CardBody className="flex items-center justify-between">
        <div>
          <Skeleton className="h-[1.125rem] w-44" />
          <Skeleton className="mt-1 h-[1.125rem] sm:w-64" />
        </div>
        <div>
          <Skeleton className="h-9 w-24" />
        </div>
      </CardBody>
    </Card>
  );
}
