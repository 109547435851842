import { Skeleton } from 'atp-react-ui';

export default function Loading() {
  return (
    <div className="absolute inset-0 left-auto m-0 mt-12 flex flex-row-reverse items-center justify-center sm:pl-20 pre-lg:w-1/2 pre-lg:justify-end">
      <div className="block px-8 text-white pre-lg:w-[500px] pre-lg:text-accent">
        <div className="flex flex-col gap-y-2 font-bold">
          <Skeleton className="h-10 bg-accent/30" />
          <Skeleton className="h-10 bg-accent/30" />
          <Skeleton className="h-10 w-3/4 bg-accent/30" />
        </div>
        <div className="mt-3 border-b pb-4 text-xl font-semibold leading-6 pre-lg:border-accent">
          <Skeleton className="h-6 bg-accent/30" />
        </div>
        <div className="pt-4 font-sans text-base font-normal leading-normal">
          <Skeleton className="h-6 bg-accent/30" />
        </div>
      </div>
    </div>
  );
}
