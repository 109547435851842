import { Skeleton } from 'atp-react-ui';

import { Container } from '@/_components';

export default function Loading() {
  return (
    <Container className="flex h-screen items-center justify-center">
      <section className="flex flex-col gap-6 md:w-3/4">
        <Skeleton className="h-8" />
        <Skeleton className="h-8" />
        <Skeleton className="h-8" />
        <Skeleton className="h-8" />
        <Skeleton className="h-8 w-3/4" />
      </section>
    </Container>
  );
}
