import { useEffect, useMemo, useState } from 'react';

import CheckCircleIcon from '@heroicons/react/24/outline/CheckCircleIcon';
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/24/outline/ChevronUpIcon';
import MegaPhoneIcon from '@heroicons/react/24/outline/MegaphoneIcon';
import { Button, Icon, Text } from 'atp-react-ui';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '@/_navigation';
import { AnimatedSlider } from '@/_shared';

import { Container } from '../../_components/layouts';

import { useAccountIssueNotification } from './account-issues-notification';
import { useNotificationsContext } from './provider';

const VALID_ROUTES = [ROUTES.COURSES, ROUTES.PROFILE, ROUTES.ON_DEMAND_TUTORING];

export function NotificationBar() {
  useAccountIssueNotification();
  const { notifications, systemNotifications, visible, remindLater, show, hide, onRemindLater } =
    useNotificationsContext();
  const location = useLocation();

  const allNotifications = useMemo(
    () => [...systemNotifications, ...notifications],
    [systemNotifications, notifications]
  );

  const isValidNotification = allNotifications.length > 0;
  const [state, setState] = useState(0);

  useEffect(() => {
    if (!VALID_ROUTES.some((r) => location.pathname.startsWith(r.path))) {
      hide();
    } else {
      show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (remindLater || !isValidNotification || !visible) {
    return null;
  }

  const notification = allNotifications[state];

  return (
    <article className="z-[19] flex flex-col items-center gap-4 bg-accent-light py-2">
      <Container>
        <article className="flex-0 flex max-w-full items-center gap-x-2">
          <section className="flex size-10 flex-shrink-0 flex-grow-0 items-center justify-center self-start rounded-lg bg-accent">
            <Icon icon={MegaPhoneIcon} className="text-white" />
          </section>
          <section className="flex-1 overflow-hidden">
            <AnimatedSlider key={state}>
              <div className="flex flex-col flex-nowrap justify-between gap-3 md:flex-row">
                <div className="flex flex-row flex-nowrap items-center gap-x-4">
                  <Text as="caption-medium" className="text-white">
                    {notification}
                  </Text>
                </div>
              </div>
            </AnimatedSlider>
          </section>
          {allNotifications.length > 1 && (
            <div className="flex flex-row flex-nowrap items-center justify-end gap-x-2">
              <Button
                size="sm"
                as="outline"
                className="text-gray-75 hover:border-primary-medium hover:text-primary-medium focus:border-gray-75 focus:ring-0"
                iconLeft={CheckCircleIcon}
                onClick={onRemindLater}
              >
                Remind me later
              </Button>

              <section className="flex h-full flex-col items-center justify-start">
                <Button
                  onClick={() => {
                    if (state !== 0) {
                      setState(state - 1);
                    }
                  }}
                  disabled={state === 0}
                  as="link"
                  size="xs"
                  className="hover:bg-accent/90 disabled:border-transparent disabled:bg-transparent disabled:opacity-50"
                  iconClassName="text-white"
                  icon={ChevronUpIcon}
                />

                <Button
                  onClick={() => {
                    setState(state + 1);
                  }}
                  disabled={state === allNotifications.length - 1}
                  as="link"
                  size="xs"
                  className="hover:bg-accent/90 disabled:border-transparent disabled:bg-transparent disabled:opacity-50"
                  iconClassName="text-white"
                  icon={ChevronDownIcon}
                />
              </section>
            </div>
          )}
        </article>
      </Container>
    </article>
  );
}
