import { Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex flex-col items-center">
            <Skeleton className="h-8 w-64" />
            <Skeleton className="mt-3 h-[1.125rem] w-64" />
          </section>
        </PageHead>
      </Container>

      <Container className="mt-7">
        <div className="mt-3 flex flex-col gap-y-4 md:w-3/4">
          <Skeleton className="h-6 w-96" />
          <Skeleton className="h-24" />
          <Skeleton className="mt-2 h-6 w-64" />
          <Skeleton className="w-84 h-64" />
        </div>
      </Container>
    </>
  );
}
