import { getCourseImage } from '../shared';

import type { RegisteredCoursesApiResponseType, RegisteredCoursesModifiedResponse } from './types';

const UNUSED_COURSES = ['Achieve 101', 'New Student Orientation'];

export function filterOutCourses(data: RegisteredCoursesApiResponseType): RegisteredCoursesApiResponseType {
  return data.filter((course) => !UNUSED_COURSES.includes(course.course_name));
}

export function splitPrepAndOdtCourses(data: RegisteredCoursesApiResponseType): RegisteredCoursesModifiedResponse {
  return data.reduce(
    (allCourses: RegisteredCoursesModifiedResponse, curCourse) => {
      if (curCourse.odt_enabled) {
        allCourses.odt.push(curCourse);
      } else {
        allCourses.prep.push({ ...curCourse, course_image: getCourseImage(curCourse.course_name) });
      }
      return allCourses;
    },
    { prep: [], odt: [] } as RegisteredCoursesModifiedResponse
  );
}
