import type { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { pathToClassName } from '@/_shared';

export function CourseSectionLayout({
  id,
  className,
  children,
}: PropsWithChildren<{ id: string; className?: string }>) {
  return (
    <section id={id} className={twMerge(pathToClassName(id), className)}>
      {children}
    </section>
  );
}
