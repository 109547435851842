import React from 'react';

import { useSearchParams } from 'react-router-dom';

export const useCurrentSearchParams = <T extends Record<string, string> = Record<string, string>>(): {
  searchParams: T;
  strParams: string;
} => {
  const [params] = useSearchParams();

  const { searchParams, strParams } = React.useMemo(() => {
    const _searchParams: Record<string, string> = Array.from(params.entries())
      .filter(([_, value]) => Boolean(value))
      .reduce(
        (acc, [key, value]) => {
          acc[key] = `${value}`;
          return acc;
        },
        {} as Record<string, string>
      );

    const _strParams = new URLSearchParams(_searchParams).toString();
    return { searchParams: _searchParams, strParams: _strParams };
  }, [params]);

  return { searchParams: searchParams as T, strParams };
};
