import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useMeContext } from '@/_providers/me-provider';
import { checkOnboardingVideoShown, onboardingVideoShown } from '@/_shared';

import { OnboardingContext } from './context';
import type { OnboardingContextType } from './types';

export function OnboardingProvider({ children }: { children: ReactNode }) {
  const { data } = useMeContext();

  const [showOnboardingVideo, setShowOnboardingVideo] = useState<boolean>(!checkOnboardingVideoShown(data?.id || ''));

  const handleShowVideo = useCallback(() => {
    setShowOnboardingVideo(true);
  }, []);

  const handleHideVideo = useCallback(() => {
    if (!data?.id) {
      return;
    }

    setShowOnboardingVideo(false);
    onboardingVideoShown(data.id);
  }, [data?.id]);

  useEffect(() => {
    if (data?.id && !checkOnboardingVideoShown(data.id)) {
      handleShowVideo();
    }
  }, [data?.id, handleShowVideo]);

  const value: OnboardingContextType = useMemo(
    () => ({
      showOnboardingVideo,
      showVideo: handleShowVideo,
      hideVideo: handleHideVideo,
    }),
    [showOnboardingVideo, handleShowVideo, handleHideVideo]
  );

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}
