import React from 'react';

import type { To } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useCurrentLocationState } from './use-current-location-state';
import { useCurrentSearchParams } from './use-current-search-params';

export const useBackNavigation = ({ pathname }: { pathname?: string } = {}) => {
  const nav = useNavigate();
  const { strParams } = useCurrentSearchParams();
  const currentLocationState = useCurrentLocationState();

  const onBack = React.useCallback(() => {
    const nextState = { ...currentLocationState, naturalBack: undefined };

    if (!pathname || currentLocationState.naturalBack) {
      nav(-1 as To, { state: nextState });
      return;
    }
    nav({ pathname, search: strParams }, { state: nextState });
  }, [currentLocationState, pathname, nav, strParams]);

  const onBackNatural = React.useCallback(() => {
    const nextState = { ...currentLocationState, naturalBack: undefined };

    if (currentLocationState.naturalBack) {
      nav(-1 as To, { state: nextState });
    }
  }, [currentLocationState, nav]);

  return { onBack, onBackNatural };
};
