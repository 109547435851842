/* eslint-disable react-refresh/only-export-components */
import { CustomerSuccessLink } from '@/_components';

type NeedToPayDownPaymentNotificationProps = {
  depositAmount: number;
};

export function NeedToPayDownPaymentNotification({ depositAmount }: NeedToPayDownPaymentNotificationProps) {
  return (
    <>
      Looks like you have a ${depositAmount} down payment balance. Contact <CustomerSuccessLink type="lighter" /> to pay
      it off so your service isn&apos;t interrupted.
    </>
  );
}

export const hasDownPaymentIssue = (depositAmount: number | null, isUnifiedCustomer: boolean) => {
  if (depositAmount && depositAmount > 0 && isUnifiedCustomer) {
    return true;
  }
  return false;
};
