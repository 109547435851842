import { Skeleton } from 'atp-react-ui';

export function OrderSummarySectionLoader() {
  return (
    <section className="mt-4">
      <Skeleton className="h-4 rounded-lg" />
      <Skeleton className="mt-2 h-4 rounded-lg" />
      <Skeleton className="mt-2 h-4 rounded-lg" />
      <Skeleton className="mt-2 h-4 rounded-lg" />
      <Skeleton className="mt-6 h-4 rounded-lg" />
      <Skeleton className="mt-1 h-4 rounded-lg" />
      <Skeleton className="mt-1 h-4 rounded-lg" />
      <div className="mt-4 w-full border-t border-outline" />
      <Skeleton className="mt-4 h-4 rounded-lg" />
    </section>
  );
}
