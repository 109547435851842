import { Card, CardBody, CircleSkeleton, Skeleton } from 'atp-react-ui';

import { Container, PageHead, ProgressSkeleton } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="gap-x- items-center justify-start">
          <CircleSkeleton className="size-16" />

          <div className="flex flex-col gap-y-2">
            <Skeleton className="h-[1.125rem] w-48 max-w-full md:w-96" />
            <Skeleton className="h-[1.125rem] w-32" />
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6">
        <section className="flex w-full flex-col gap-y-2 pre-xl:w-3/4">
          <ProgressSkeleton />
          <ProgressSkeleton />
          <ProgressSkeleton />
          <ProgressSkeleton />
        </section>
      </Container>

      <Container className="mt-6">
        <section className="flex w-full flex-col gap-y-4 pre-xl:w-3/4">
          <Card>
            <CardBody className="flex items-center justify-between">
              <div>
                <Skeleton className="h-[1.125rem] w-44" />
                <Skeleton className="mt-1 h-[1.125rem] w-full" />
              </div>
              <div>
                <Skeleton className="size-9" />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody className="flex items-center justify-between">
              <div>
                <Skeleton className="h-[1.125rem] w-44" />
                <Skeleton className="mt-1 h-[1.125rem] w-full" />
              </div>
              <div>
                <Skeleton className="size-9" />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody className="flex items-center justify-between">
              <div>
                <Skeleton className="h-[1.125rem] w-44" />
                <Skeleton className="mt-1 h-[1.125rem] w-full" />
              </div>
              <div>
                <Skeleton className="size-9" />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody className="flex items-center justify-between">
              <div>
                <Skeleton className="h-[1.125rem] w-44" />
                <Skeleton className="mt-1 h-[1.125rem] w-full" />
              </div>
              <div>
                <Skeleton className="size-9" />
              </div>
            </CardBody>
          </Card>
        </section>
      </Container>
    </>
  );
}
