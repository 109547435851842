/* eslint-disable react-refresh/only-export-components */
import { Text } from 'atp-react-ui';

import { CustomerSuccessLink, PrimaryLink } from '@/_components';
import { ROUTES } from '@/_navigation';
import { dateTime } from '@/_shared';

type NeedToPayNotificationProps = {
  delinquentDate: string;
  isUnifiedUser: boolean;
};

export function NeedToPayNotification({ delinquentDate, isUnifiedUser }: NeedToPayNotificationProps) {
  return (
    <>
      Your payment was due on{' '}
      <Text as="subtitle-small" className="inline text-primary-medium">
        {dateTime.format(delinquentDate, 'MM/DD/YYYY')}
      </Text>{' '}
      -{' '}
      {isUnifiedUser ? (
        <>
          Contact <CustomerSuccessLink type="lighter" />
        </>
      ) : (
        <PrimaryLink to={ROUTES.BILLING_MAKE_A_PAYMENT.path} type="lighter">
          click here
        </PrimaryLink>
      )}{' '}
      to review your payment information & make a payment. Please be advised, you will need to have a payment posted to
      your account before{' '}
      <Text as="subtitle-small" className="inline text-primary-medium">
        {dateTime.format(dateTime.add(delinquentDate, 15, 'day'), 'MM/DD/YYYY')}
      </Text>{' '}
      to continue your courses with no interruptions. Thank you.
    </>
  );
}

export const hasPendingPaymentIssue = (delinquentDate: string | null) => {
  if (!delinquentDate) {
    return false;
  }
  return dateTime.isDateSameOrAfter(new Date(), delinquentDate);
};
