import React from 'react';

import type { FormLabelProps } from 'atp-react-ui/dist/components/form-components/types';

import { FormPatternPasswordInput } from './form-pattern-password-input';
import type { PatternInputProps } from './pattern-input';

export type FormSsnInputProps = Omit<PatternInputProps, 'format' | 'type'> & FormLabelProps;

const FormSsnInput = React.forwardRef<HTMLInputElement, FormSsnInputProps>((props, ref) => (
  <FormPatternPasswordInput ref={ref} {...props} type="password" format="###-##-####" mask="_" />
));

FormSsnInput.displayName = 'FormSsnInput';

export { FormSsnInput };
