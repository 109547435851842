import React from 'react';

import { twMerge } from 'tailwind-merge';

import { TextBody } from '../text-body';
import { TextCaption } from '../text-caption';

type TnCSectionProps = {
  title?: string;
  children: React.ReactNode;
  titleClassName?: string;
  descriptionClassName?: string;
};
export function TnCSection({ title, titleClassName, descriptionClassName, children }: TnCSectionProps) {
  return (
    <>
      {title ? <TextCaption className={twMerge('mt-4', titleClassName)}>{title}</TextCaption> : null}
      <TextBody className={twMerge(title ? 'mt-2' : '', descriptionClassName)}>{children}</TextBody>
    </>
  );
}
