import type { PropsWithChildren } from 'react';

import { useBoolean, useIsMounted } from 'atp-react-ui';

interface IProps {
  className?: string;
  animateClass?: string;
}

export function AnimatedSlider({
  children,
  className = '',
  animateClass = '-translate-y-full',
}: PropsWithChildren<IProps>): JSX.Element {
  const [shouldStart, start] = useBoolean(false);

  useIsMounted(() => {
    start.setTrue();
  });

  return (
    <div className={`${className} ${shouldStart ? '' : animateClass} transform-gpu transition-transform duration-500`}>
      {children}
    </div>
  );
}
