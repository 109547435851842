import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { useMeContext } from '@/_providers/me-provider';
import { queryClient, apiClient } from '@/_services';

const PlanInfoSchema = z.object({
  moodle_progress: z.number(),
  attendance_progress: z.number(),
  participation_progress: z.number(),
  plan_progress: z.number(),
});

const ExamInfoSchema = z.object({
  pass_rate: z.number(),
  pass_count: z.number(),
  attempts: z.number(),
});

const CourseInfoSchema = z.object({
  enrolled: z.number(),
  completed: z.number(),
  pending: z.number(),
  no_of_odt: z.number(),
  no_of_prep: z.number(),
});

const ProfileApiResponseSchema = z.object({
  email: z.string(),
  username: z.string(),
  last_name: z.string(),
  first_name: z.string(),
  name: z.string(),
  title: z.string().nullable(),
  mobile_phone: z.string().nullable(),
  mailing_street: z.string().nullable(),
  mailing_city: z.string().nullable(),
  mailing_state: z.string().nullable(),
  mailing_postal_code: z.string().nullable(),
  mailing_country: z.string().nullable(),
  mailing_latitude: z.number().nullable(),
  mailing_longitude: z.number().nullable(),
  mailing_geocode_accuracy: z.number().nullable(),
  plan_info: PlanInfoSchema.optional(),
  exams_info: ExamInfoSchema,
  courses_info: CourseInfoSchema,
  program_of_interest_master: z.string().nullable(),
  pain_emotional_need: z.unknown().nullable(),
  mailing_address: z.string(),
  preferred_contact_method_master: z.string(),
  preferred_day_and_time_to_contact: z.string(),
  preferred_contact_frequency: z.string(),
  ssn: z.string(),
  birthdate: z.string(),
});

export type ProfileApiResponseType = z.infer<typeof ProfileApiResponseSchema>;
type ProfileApiTransformedResponseType = ProfileApiResponseType & { image: string };

const getQueryKey = ({ customerId }: { customerId: string | undefined }) => ['PROFILE', customerId];

export const useProfileQuery = () => {
  const { data: meData } = useMeContext();
  const customerId = meData?.id || '';

  const profileQuery = useSuspenseQuery<ProfileApiResponseType, unknown, ProfileApiTransformedResponseType>({
    queryKey: getQueryKey({ customerId }),
    queryFn: async () =>
      apiClient.request<ProfileApiResponseType>({
        endpoint: `/customers/${customerId}/profile/`,
        responseSchema: ProfileApiResponseSchema,
      }),
    select(data) {
      return {
        ...data,
        image: meData?.image || '',
      };
    },
  });

  return { ...profileQuery, customerId };
};

export const invalidateProfileQuery = ({ customerId }: { customerId: string | undefined }) =>
  queryClient.invalidateQueries({ queryKey: getQueryKey({ customerId }) });
