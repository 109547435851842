import type { CookieSetOptions, Cookie } from 'universal-cookie';
import Cookies from 'universal-cookie';

export enum ACCOUNT_IDENTITY {
  STUDENT = 'c3R1ZGVudA',
  INSTRUCTOR = 'aW5zdHJ1Y3Rvcg',
}

const DEFAULT_COOKIE_OPTIONS: CookieSetOptions = { path: '/' };

export class CookieManager {
  // COOKIE_PREFIX makes sure that cookies don't overlap with other apps (e.g. instructor portal)
  // static COOKIE_PREFIX = '/atp-student/';
  static COOKIE_PREFIX = '';

  static ACCESS_TOKEN_KEY = 'access';

  static REFRESH_TOKEN_KEY = 'refresh';

  static USER_TYPE_KEY = 'type';

  static LAST_USERNAME = 'last-username';

  static LAST_PASSWORD = 'last-password';

  static TOKEN_EXPIRE_DAYS = 7;

  static cookies = new Cookies();

  static getPrefixedKey(key: string): string {
    return `${this.COOKIE_PREFIX}${key}`;
  }

  static matchKeys(key1: string, key2: string): boolean {
    return key1 === this.getPrefixedKey(key2);
  }

  static get(key: string) {
    return this.cookies.get(this.getPrefixedKey(key));
  }

  static set(key: string, value: Cookie, options: CookieSetOptions = DEFAULT_COOKIE_OPTIONS): void {
    this.cookies.set(this.getPrefixedKey(key), value, options);
  }

  static remove(key: string, options: CookieSetOptions = DEFAULT_COOKIE_OPTIONS): void {
    this.cookies.remove(this.getPrefixedKey(key), options);
  }

  static setAuthTokens(
    access: string,
    refresh: string,
    type: ACCOUNT_IDENTITY = ACCOUNT_IDENTITY.STUDENT,
    options: CookieSetOptions = DEFAULT_COOKIE_OPTIONS
  ): void {
    const tokenExpiresInSeconds = this.TOKEN_EXPIRE_DAYS * 24 * 60 * 60;

    this.set(this.ACCESS_TOKEN_KEY, access, { ...options, maxAge: tokenExpiresInSeconds });
    this.set(this.REFRESH_TOKEN_KEY, refresh, options);
    this.set(this.USER_TYPE_KEY, type, options);
  }

  static setAccessToken(token: string) {
    return this.set(this.ACCESS_TOKEN_KEY, token);
  }

  static getAccessToken(): string | undefined {
    return this.get(this.ACCESS_TOKEN_KEY);
  }

  static getRefreshToken(): string | undefined {
    return this.get(this.REFRESH_TOKEN_KEY);
  }

  static removeAuthTokens(options: CookieSetOptions = DEFAULT_COOKIE_OPTIONS): void {
    this.remove(this.ACCESS_TOKEN_KEY, options);
    this.remove(this.REFRESH_TOKEN_KEY, options);
    this.remove(this.USER_TYPE_KEY, options);
  }

  static isLoggedIn(): boolean {
    return Boolean(this.get(this.ACCESS_TOKEN_KEY));
  }

  static getAccountType(): ACCOUNT_IDENTITY {
    const type = this.get(this.USER_TYPE_KEY);
    return type || ACCOUNT_IDENTITY.STUDENT;
  }

  static isStudentAccount(): boolean {
    const type = this.getAccountType();
    return type === ACCOUNT_IDENTITY.STUDENT;
  }

  static isInstructorAccount(): boolean {
    const type = this.getAccountType();
    return type === ACCOUNT_IDENTITY.INSTRUCTOR;
  }

  static setLastUsername(username: string) {
    this.set(this.LAST_USERNAME, username);
  }

  static getLastUsername(): string | undefined {
    return this.get(this.LAST_USERNAME);
  }

  static setLastPassword(password: string) {
    this.set(this.LAST_PASSWORD, password);
  }

  static getLastPassword(): string | undefined {
    return this.get(this.LAST_PASSWORD);
  }
}
