import { Card, CardBody, Skeleton, twMerge } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';
import { useCurrentLocationState } from '@/_shared';

import { ClassSchedulesLayout, ClassSchedulesLoading, LeftCell, RightCell, TwoColumn } from './components';

export default function Loading() {
  const { isOdt = false } = useCurrentLocationState<{ isOdt: boolean }>();

  const renderDescriptionLoader = () => (
    <Card aria-labelledby="course-information-title" className={twMerge('lg:h-80', isOdt && 'pre-xl:w-3/4')}>
      <CardBody>
        <Skeleton className="h-6 w-3/4" />

        <Skeleton className="mt-4 h-3 w-full" />
        <Skeleton className="mt-1 h-3 w-full" />
        <Skeleton className="mt-1 h-3 w-full" />
        <Skeleton className="mt-4 h-3 w-full" />
        <Skeleton className="mt-1 h-3 w-full" />
        <Skeleton className="mt-1 h-3 w-full" />

        {isOdt && (
          <>
            <Skeleton className="mt-4 h-3 w-full" />
            <Skeleton className="mt-1 h-3 w-full" />
            <Skeleton className="mt-1 h-3 w-full" />
            <Skeleton className="mt-4 h-3 w-full" />
            <Skeleton className="mt-1 h-3 w-full" />
            <Skeleton className="mt-1 h-3 w-full" />
          </>
        )}

        <Skeleton className="mt-1 h-3 w-3/4" />
      </CardBody>
    </Card>
  );

  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <Skeleton className="h-5 w-44" />

          <section className="mt-2 flex items-center">
            <Skeleton className="mr-4 size-8" />
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="mr-1.5 size-5" />
              <Skeleton className="h-5 w-20" />
            </div>
            <div className="flex items-center">
              <Skeleton className="mr-1.5 size-5" />
              <Skeleton className="h-5 w-20" />
            </div>
            <div className="flex items-center">
              <Skeleton className="mr-1.5 size-5" />
              <Skeleton className="h-5 w-20" />
            </div>
            <div className="flex items-center">
              <Skeleton className="mr-1.5 size-5" />
              <Skeleton className="h-5 w-20" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6">
        {isOdt ? (
          renderDescriptionLoader()
        ) : (
          <TwoColumn>
            <LeftCell>{renderDescriptionLoader()}</LeftCell>
            <RightCell>
              <ClassSchedulesLayout>
                <ClassSchedulesLoading />
              </ClassSchedulesLayout>
            </RightCell>
          </TwoColumn>
        )}
      </Container>
    </>
  );
}
