export const MAILING_OPTIONS_DATA = {
  MAIL: { name: 'Standard Mail', price: '', time: '13-14 days' },
  EXPEDITED: { name: 'Expedited', price: '', time: '11-12 days' },
  EXPRESS: { name: 'Express', price: '', time: '10-11 days' },
};
export type MailingOptionKeyType = keyof typeof MAILING_OPTIONS_DATA;

export const MAILING_OPTIONS = Object.entries(MAILING_OPTIONS_DATA).map(([key, val]) => ({
  ...val,
  key: key as MailingOptionKeyType,
  label: val.name,
  name: `${val.name} (${val.time})`,
  value: val.name,
}));
export type MailingOptionType = (typeof MAILING_OPTIONS)[number];
export const MAILING_OPTIONS_KEYS = Object.keys(MAILING_OPTIONS_DATA);
