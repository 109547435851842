import React from 'react';

import { Card } from 'atp-react-ui';

export function ClassSchedulesLayout({ children }: { children: React.ReactNode }) {
  return (
    <Card aria-labelledby="course-information-title" className="overflow-hidden">
      {children}
    </Card>
  );
}
