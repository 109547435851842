import React from 'react';

import { Text, twMerge } from 'atp-react-ui';

type TextCaptionProps = {
  children: React.ReactNode;
  className?: string;
};

export function TextCaption({ children, className }: TextCaptionProps) {
  return (
    <Text as="subtitle-small" className={twMerge('text-lg text-accent-medium', className)}>
      {children}
    </Text>
  );
}
