import { useMutation } from '@tanstack/react-query';

import { apiClient } from '@/_services';

import type { ExamQuerySchema } from './use-exams-query';

type UpdateExamDateApiPayload = {
  id: string;
  result: 'Pass' | 'Fail';
};

export const useSubmitExamResultMutation = () =>
  useMutation<ExamQuerySchema, unknown, UpdateExamDateApiPayload>({
    mutationFn: async ({ id, result }) =>
      apiClient.post({
        endpoint: `/exams/submit-result/${id}/`,
        method: 'PUT',
        payload: { result },
      }),
  });
