import { forwardRef } from 'react';

import EyeIcon from '@heroicons/react/24/outline/EyeIcon';
import EyeSlashIcon from '@heroicons/react/24/outline/EyeSlashIcon';
import type { InputProps } from 'atp-react-ui';
import { Button, FormInput, useBoolean } from 'atp-react-ui';
import type { FormLabelProps } from 'atp-react-ui/dist/components/form-components/types';

export const FormPasswordInput = forwardRef<HTMLInputElement, InputProps & FormLabelProps>(
  ({ type = 'password', className, ...props }, ref) => {
    const [isHidden, hide] = useBoolean(true);

    return (
      <div className="relative">
        <FormInput {...props} className={`${className} pr-11`} ref={ref} type={isHidden ? type : 'text'} />
        <Button
          icon={isHidden ? EyeIcon : EyeSlashIcon}
          onClick={hide.toggle}
          size="sm"
          as="link"
          aria-label="Toggle secure field visibility"
          className="absolute right-[0.3125rem] top-7"
        />
      </div>
    );
  }
);

FormPasswordInput.displayName = 'FormPasswordInput';
