import type { ReactNode } from 'react';

import CloseIcon from '@heroicons/react/24/outline/XMarkIcon';
import type { ModalProps } from 'atp-react-ui';
import { Button, Modal, twMerge } from 'atp-react-ui';

type FullModalProps = Omit<ModalProps, 'hideCloseButton' | 'children' | 'unmount' | 'onClose'> & {
  children: ReactNode;
  onClose: () => void;
  closeButtonClassName?: string;
};

export function FullModal({ children, className, closeButtonClassName, onClose, ...props }: FullModalProps) {
  return (
    <Modal
      {...props}
      hideCloseButton
      className={twMerge('w-3/4 min-w-0 self-center !rounded-xl', className)}
      onClose={onClose}
    >
      <ModalCloseButton onClick={onClose} className={closeButtonClassName} />
      {children}
    </Modal>
  );
}

function ModalCloseButton({ onClick, className }: { onClick: () => void; className?: string }) {
  return (
    <Button
      onClick={onClick}
      iconClassName="text-white"
      className={twMerge('absolute right-4 top-4 z-10 size-6 bg-gray-900 px-2 hover:bg-gray-500 md:size-10', className)}
      icon={CloseIcon}
    />
  );
}
