import React from 'react';

import { Outlet } from 'react-router-dom';

import { CookieManager } from '@/_services';

import { BookingsContext } from './context';
import type { BookingsContextType, BookingType } from './types';

export function BookingsProvider() {
  const [state, setState] = React.useState<
    Omit<BookingsContextType, 'setTopic' | 'setCourseId' | 'reset' | 'setBookingType' | 'setFrom'>
  >({
    topic: CookieManager.get('bookings-topic') || '',
    courseId: CookieManager.get('bookings-courseId') || '',
    bookingType: CookieManager.get('bookings-bookingType'),
    from: CookieManager.get('bookings-from') || '',
  });

  const setTopic = React.useCallback((topic: string) => {
    setState((prevState) => ({ ...prevState, topic }));
    CookieManager.set('bookings-topic', topic);
  }, []);

  const setCourseId = React.useCallback((courseId: string) => {
    setState((prevState) => ({ ...prevState, courseId }));
    CookieManager.set('bookings-courseId', courseId);
  }, []);

  const setBookingType = React.useCallback((bookingType: BookingType) => {
    setState((prevState) => ({ ...prevState, bookingType }));
    CookieManager.set('bookings-bookingType', bookingType);
  }, []);

  const setFrom = React.useCallback((from: string) => {
    setState((prevState) => ({ ...prevState, from }));
    CookieManager.set('bookings-from', from);
  }, []);

  const reset = React.useCallback(() => {
    CookieManager.remove('bookings-topic');
    CookieManager.remove('bookings-courseId');
    setState({ topic: '', courseId: '', bookingType: undefined, from: '' });
  }, []);

  const value: BookingsContextType = React.useMemo(
    () => ({ ...state, setTopic, setCourseId, setBookingType, reset, setFrom }),
    [state, setTopic, setCourseId, setBookingType, reset, setFrom]
  );

  return (
    <BookingsContext.Provider value={value}>
      <Outlet />
    </BookingsContext.Provider>
  );
}
