import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient } from '@/_services';

export const midSurveyMutationPayload = z.object({
  mc_overall_experience: z.number(),
  mcf_question_2_a1: z.string(),
  mcf_question_2_a2: z.string(),
  mcf_question_2_a3: z.string(),
  mcf_question_2_b1: z.string(),
  mcf_question_2_b2: z.string(),
  mcf_question_2_b3: z.string(),
  mcf_question_3: z.number(),
  mcf_question_4_a1: z.string(),
  mcf_question_4_a2: z.string(),
  mcf_question_4_a3: z.string(),
  mcf_question_4_b1: z.string(),
  mcf_question_4_b2: z.string(),
  mcf_question_4_b3: z.string(),
  class_attendance: z.string(),
  mcf_question_5: z.number(),
  mcf_question_6_a1: z.string(),
  mcf_question_6_a2: z.string(),
  mcf_question_6_a3: z.string(),
  mcf_question_6_b1: z.string(),
  mcf_question_6_b2: z.string(),
  mcf_question_6_b3: z.string(),
});

export type MidSurveyMutationPayload = z.infer<typeof midSurveyMutationPayload>;

export const endSurveyMutationPayload = z.object({
  end_course_overall_experience: z.number(),
  ecf_question_2_a1: z.string(),
  ecf_question_2_a2: z.string(),
  ecf_question_2_a3: z.string(),
  ecf_question_2_b1: z.string(),
  ecf_question_2_b2: z.string(),
  ecf_question_2_b3: z.string(),
  ecf_question_3: z.number(),
  ecf_question_4_a1: z.string(),
  ecf_question_4_a2: z.string(),
  ecf_question_4_a3: z.string(),
  ecf_question_4_b1: z.string(),
  ecf_question_4_b2: z.string(),
  ecf_question_4_b3: z.string(),
  class_attendance: z.string(),
  ecf_question_5: z.number(),
  ecf_question_6_a1: z.string(),
  ecf_question_6_a2: z.string(),
  ecf_question_6_a3: z.string(),
  ecf_question_6_b1: z.string(),
  ecf_question_6_b2: z.string(),
  ecf_question_6_b3: z.string(),
  ecf_question_7: z.number(),
  ecf_question_8: z.number(),
  ecf_question_8_a1: z.string(),
  ecf_question_8_a2: z.string(),
  ecf_question_8_a3: z.string(),
  ecf_question_8_b1: z.string(),
  ecf_question_8_b2: z.string(),
  ecf_question_8_b3: z.string(),
});

export type EndSurveyMutationPayload = z.infer<typeof endSurveyMutationPayload>;

export const postSurveyMutationPayload = z.object({
  post_exam_overall_experience: z.number(),
  pef_question_2_a1: z.string(),
  pef_question_2_a2: z.string(),
  pef_question_2_a3: z.string(),
  pef_question_2_b1: z.string(),
  pef_question_2_b2: z.string(),
  pef_question_2_b3: z.string(),
  pef_question_3: z.number(),
  pef_question_4_a1: z.string(),
  pef_question_4_a2: z.string(),
  pef_question_4_a3: z.string(),
  pef_question_4_b1: z.string(),
  pef_question_4_b2: z.string(),
  pef_question_4_b3: z.string(),
  class_attendance: z.string(),
});

export type PostSurveyMutationPayload = z.infer<typeof postSurveyMutationPayload>;

export const useMidCourseSurveyMutation = () => {
  const { mutateAsync, ...options } = useMutation<unknown, unknown, MidSurveyMutationPayload>({
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: `/survey/midcourse/`,
        method: 'POST',
        payload,
      }),
  });

  return [mutateAsync, options] as const;
};

export const useEndCourseSurveyMutation = () => {
  const { mutateAsync, ...options } = useMutation<unknown, unknown, EndSurveyMutationPayload>({
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: `/survey/endofcourse/`,
        method: 'POST',
        payload,
      }),
  });

  return [mutateAsync, options] as const;
};

export const usePostCourseSurveyMutation = () => {
  const { mutateAsync, ...options } = useMutation<unknown, unknown, PostSurveyMutationPayload>({
    mutationFn: async (payload) =>
      apiClient.post({
        endpoint: `/survey/postexam/`,
        method: 'POST',
        payload,
      }),
  });

  return [mutateAsync, options] as const;
};
