import { Skeleton } from 'atp-react-ui';

import { Container } from '@/_components';

import { Header } from './components';

export default function Loading() {
  return (
    <>
      <Header />
      <Container className="flex flex-col gap-y-6">
        <Question />
        <Answer className="self-start rounded-bl-none" />
        <Answer className="self-end rounded-br-none" />
        <Answer className="self-start rounded-bl-none" />
        <Answer className="self-end rounded-br-none" />
        <Answer className="self-start rounded-bl-none" />
        <Answer className="self-end rounded-br-none" />
      </Container>
    </>
  );
}

function Question() {
  return (
    <div className="relative flex h-36 min-w-0 rounded-xl rounded-bl-none bg-white shadow-card">
      <div className="flex flex-1 flex-col px-6 py-5">
        <Skeleton className="h-4 w-52" />
        <Skeleton className="mt-1 h-4 w-64" />
        <Skeleton className="mt-4 h-4 w-full" />
        <Skeleton className="mt-1 h-4 w-full" />
        <Skeleton className="mt-1 h-4 w-3/4" />
      </div>
    </div>
  );
}

function Answer({ className }: { className?: string }) {
  return (
    <div className={`relative flex h-36 w-3/4 min-w-96 rounded-xl bg-white shadow-card ${className}`}>
      <div className="flex flex-1 flex-col px-6 py-5">
        <Skeleton className="h-4 w-52" />
        <Skeleton className="mt-1 h-4 w-64" />
        <Skeleton className="mt-4 h-4 w-full" />
        <Skeleton className="mt-1 h-4 w-full" />
        <Skeleton className="mt-1 h-4 w-3/4" />
      </div>
    </div>
  );
}
