import React from 'react';

import { useAccountDependenciesContext, useMeContext } from '@/_providers';
import { log } from '@/_shared';

import { useNotificationsContext } from '../provider';

import { NeedToPayDownPaymentNotification, hasDownPaymentIssue } from './need-to-pay-down-payment-notification';
import { NeedToPayNotification, hasPendingPaymentIssue } from './need-to-pay-notification';

export const useAccountIssueNotification = () => {
  const { setNotifications, visible } = useNotificationsContext();

  const { data: meData } = useMeContext();
  const { data: accountDepsData } = useAccountDependenciesContext();

  const notifications: JSX.Element[] = React.useMemo(() => {
    if (!meData || !accountDepsData) {
      return [];
    }
    const _notifications: JSX.Element[] = [];
    const { plan_summary, delinquent_date } = meData;
    const { atp_pilot, unified_customers } = plan_summary || {};
    const { deposit_info /* , card_info */ } = accountDepsData;

    // CHARGENT SPECIFIC
    // if (hasCardExpiryIssue(card_info)) {
    //   _notifications.push(<CardExpiryNotification key="card-expiry" cardInfo={card_info} isUnifiedUser={!atp_pilot} />);
    // }

    log('[Notification] hasDownPaymentIssue:', {
      remaining_balance: deposit_info?.remaining_balance,
      unified_customers,
    });
    if (hasDownPaymentIssue(deposit_info?.remaining_balance, Boolean(unified_customers))) {
      _notifications.push(
        <NeedToPayDownPaymentNotification key="need-downpayment" depositAmount={deposit_info?.remaining_balance} />
      );
    }

    log('[Notification] hasPendingPaymentIssue:', {
      delinquent_date,
    });
    if (hasPendingPaymentIssue(delinquent_date)) {
      _notifications.push(
        <NeedToPayNotification key="need-to-pay" delinquentDate={delinquent_date ?? ''} isUnifiedUser={!atp_pilot} />
      );
    }
    return _notifications;
  }, [accountDepsData, meData]);

  React.useEffect(() => {
    setNotifications(notifications);

    // return () => {
    //   setNotifications([]);
    // };
  }, [notifications, setNotifications]);

  return { visible };
};
