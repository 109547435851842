import React from 'react';

import { Button } from 'atp-react-ui';

export type SectionLayoutBaseProps = {
  initials?: string;
  checkInitials?: boolean;
  onProceed?: () => void;
};

type SectionLayoutProps = SectionLayoutBaseProps & {
  children: React.ReactNode;
  className?: string;
};

export function SectionLayout({ initials, checkInitials = false, children, className, onProceed }: SectionLayoutProps) {
  return (
    <section className={className}>
      {children}
      {typeof onProceed === 'function' && (
        <div className="mt-4 flex justify-end">
          <Button
            as="link"
            onClick={onProceed}
            className="h-10 w-40 rounded-none border-0 border-b-2 border-dashed border-primary text-primary"
          >
            {checkInitials && initials ? initials : <span className="text-gray-400">Initials</span>}
          </Button>
        </div>
      )}
    </section>
  );
}
