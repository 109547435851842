import { SUPPORTED_ENVIRONMENT, config } from '@/_config';

import { log } from '../_shared/utils';

const envs = [SUPPORTED_ENVIRONMENT.PRODUCTION];

let ga4Initialized = false;

declare global {
  interface Window {
    ga4EventsPipe: unknown[];
    ga4Timer: string | number | NodeJS.Timeout | null | undefined;
  }
}

window.ga4EventsPipe = [];
window.ga4Timer = null;

const init = () => {
  import('react-ga4').then((GA4) => {
    const ReactGA = GA4.default;
    if (!ga4Initialized) {
      ReactGA.initialize('G-N57RBTBCNS', {
        testMode: !envs.includes(config.currentEnvironment),
      });

      // const consentStatus = localStorage.getItem('gaConsentStatus');
      // if (consentStatus === 'granted') {
      //   ReactGA.gtag('consent', 'update', {
      //     analytics_storage: 'granted',
      //   });
      // } else if (consentStatus === 'denied') {
      //   ReactGA.gtag('consent', 'update', {
      //     analytics_storage: 'denied',
      //   });
      // }

      ga4Initialized = true;
    }
  });
};

const event = (evtData: unknown) => {
  window.ga4EventsPipe.push(evtData);
  if (!ga4Initialized) return;

  import('react-ga4').then((GA4) => {
    const ReactGA = GA4.default;
    if (!envs.includes(config.currentEnvironment)) {
      log('GA EVENT:', evtData);
      return undefined;
    }

    if (!window.ga4Timer) {
      window.ga4Timer = setInterval(() => {
        if (window.ga4EventsPipe.length > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ReactGA.event(window.ga4EventsPipe.shift() as unknown);
        } else {
          clearInterval(window.ga4Timer as number);
          window.ga4Timer = null;
        }
      }, 2000);
    }
    return undefined;
  });
};

export const googleAnalytics4 = {
  init,
  event,
};
