import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient } from '@/_services';

type PreReqQuestionApiPayload = {
  enrollmentId: string;
  nursing_question_s_answer: string;
};

export const PreReqQuestionApiResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  nursing_question_s_answer: z.string(),
});

type PreReqQuestionApiResponse = z.infer<typeof PreReqQuestionApiResponseSchema>;

export const usePreReqQuestionMutation = ({ onSuccess }: { onSuccess: () => void }) => {
  const { mutateAsync, ...options } = useMutation<PreReqQuestionApiResponse, unknown, PreReqQuestionApiPayload>({
    mutationFn: ({ enrollmentId, nursing_question_s_answer }) =>
      apiClient.post({
        method: 'PUT',
        endpoint: `/enrollment/${enrollmentId}/`,
        payload: { nursing_question_s_answer },
      }),
    onSuccess,
  });

  return [mutateAsync, { ...options }] as const;
};
