import { twMerge } from 'tailwind-merge';
import './style.css';

export function InfiniteProgressBar({ className }: { className?: string }) {
  return (
    <div className={twMerge('relative h-1 w-full overflow-hidden bg-accent-light/30', className)}>
      <div className="infiniteProgress absolute h-full w-1/2 bg-primary-medium" />
    </div>
  );
}
