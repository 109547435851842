import type { CSSProperties } from 'react';
import { useCallback } from 'react';

import { Text } from 'atp-react-ui';
import type { Props as JoyrideProps, State as JoyrideState, CallBackProps } from 'react-joyride';
import Joyride, { STATUS } from 'react-joyride';

export type WalkthroughProps = JoyrideProps & {
  ready?: boolean;
  onComplete: (data: CallBackProps) => void;
};
export type WalkthroughCallbackState = JoyrideState;
export type WalkthoughStep = JoyrideProps['steps'][number];
export type WalkthroughState = Pick<WalkthroughProps, 'run' | 'steps'>;

const buttonStyle: CSSProperties = {
  color: 'rgb(255, 255, 255)',
  backgroundColor: 'rgb(255, 96, 0)',
  borderRadius: '8px',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  border: '1px solid transparent',
  outline: 'none',
};

const buttonOutlined: CSSProperties = {
  ...buttonStyle,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  borderColor: 'rgb(209, 213, 219)',
  color: 'rgb(109, 109, 109)',
};

const buttonContained: CSSProperties = {
  ...buttonStyle,
  backgroundColor: 'rgb(50, 54, 59)',
  borderColor: 'rgb(0, 0, 0, 0)',
  color: 'rgb(255, 255, 255)',
};

const locale: WalkthroughProps['locale'] = {
  next: 'Continue',
  last: 'Finish',
};

const styles: WalkthroughProps['styles'] = {
  buttonNext: buttonStyle,
  buttonBack: { ...buttonContained, marginRight: '0.5rem' },
  buttonClose: buttonStyle,
  buttonSkip: buttonOutlined,
};

export function Walkthrough({ callback, onComplete, steps, ready = true, ...props }: WalkthroughProps) {
  const walkthroughCallback: Required<WalkthroughProps>['callback'] = useCallback(
    (data) => {
      const { status } = data;

      if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
        onComplete(data);
      }

      if (callback) {
        callback(data);
      }
    },
    [callback, onComplete]
  );

  return (
    ready && (
      <Joyride
        steps={parseSteps(steps)}
        hideCloseButton
        disableOverlayClose
        disableCloseOnEsc
        {...props}
        callback={walkthroughCallback}
        locale={locale}
        styles={styles}
      />
    )
  );
}

function parseSteps(steps: WalkthroughState['steps']): WalkthroughState['steps'] {
  return steps.map((step) => {
    const s = { ...step };
    // if (s.title) {
    //   s.title = <Text as="h4">{s.title}</Text>;
    // }
    if (s.content) {
      s.content = (
        <Text as="caption-medium" className="-mb-3 text-accent-light">
          {s.content}
        </Text>
      );
    }
    return s;
  });
}
