import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from '@/_services';
import type { ApiEndpointMethod } from '@/_services';

import type { MeApiResponse } from './use-me-query';
import { ME_QUERY_KEY } from './use-me-query';

type UseMeMutationParams<Response, Payload> = UseMutationOptions<Response, unknown, Payload> & {
  method?: Exclude<ApiEndpointMethod, 'GET'>;
};

export const useMeMutation = <Payload, Response = MeApiResponse>({
  method = 'PUT',
  ...options
}: UseMeMutationParams<Response, Payload>) => {
  const queryClient = useQueryClient();

  const { mutateAsync, data, isPending, isError, error, isSuccess } = useMutation<Response, unknown, Payload>({
    ...options,
    mutationFn: async (payload) => apiClient.post({ endpoint: '/me/', method, payload }),
    onSuccess: (...args) => {
      queryClient.setQueryData(ME_QUERY_KEY, args[0]);
      options.onSuccess?.call(undefined, ...args);
    },
  });

  return [mutateAsync, { data, isLoading: isPending, isError, error, isSuccess }] as const;
};
