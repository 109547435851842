import { Card, CardBody, Skeleton } from 'atp-react-ui';

import { Container, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex items-center">
            <Skeleton className="mr-4 size-8" />
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="h-[1.125rem] w-64" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6">
        <section className="flex w-full flex-col gap-y-4 pre-xl:w-3/4">
          <article className="flex items-center justify-between">
            <Skeleton className="h-6 w-32" />
            <Skeleton className="h-9 w-32" />
          </article>
          <OneRow />
          <OneRow />
          <OneRow />
          <article className="mt-6 flex items-center justify-between">
            <Skeleton className="h-6 w-32" />
            <Skeleton className="h-9 w-32" />
          </article>
          <OneRow />
          <OneRow />
          <OneRow />
        </section>
      </Container>
    </>
  );
}

function OneRow() {
  return (
    <Card>
      <CardBody className="flex items-center justify-between">
        <section className="flex items-center">
          <Skeleton className="mr-4 h-[2.375rem] w-11" />
          <div>
            <Skeleton className="h-[1.125rem] w-28 sm:w-40" />
            <Skeleton className="mt-1 h-[1.125rem] w-20 sm:w-32" />
          </div>
        </section>
        <section className="flex gap-x-4">
          <Skeleton className="size-8" />
          {/* <Skeleton className="size-8" /> */}
        </section>
      </CardBody>
    </Card>
  );
}
