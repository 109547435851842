import React from 'react';

import { Text, twMerge } from 'atp-react-ui';

type TextBodyProps = {
  children: React.ReactNode;
  className?: string;
};

export function TextBody({ children, className }: TextBodyProps) {
  return (
    <Text as="div" className={twMerge('text-base text-accent-light', className)}>
      {children}
    </Text>
  );
}
