import { Mutex } from 'async-mutex';
import { SupportedServerTypes } from 'atp-api-logger';

import { config } from '@/_config';
import { logger } from '@/_services/logger';
import { logoutUser } from '@/_shared/utils/user';

import { CookieManager } from '../cookie-manager';
import { snackbar } from '../snackbar';

import type { ACCOUNT_TYPE_URL_INDEX } from './constants';
import { ApiError } from './error';
import type {
  DefaultParamsType,
  RequestParamsBase,
  ApiFormErrorResponse,
  ApiFormErrorDetail,
  ApiFormError,
  ApiErrorResponse,
  ApiErrorResponseObject,
  // ZodValidationSchemaType,
  RequestParamsMakeRequest,
  ApiEndpointMethod,
} from './types';

export class ApiClient {
  public static atpBaseUrl = config.baseURL[0];

  public static aodBaseUrl = config.baseURL[1];

  private baseUrl = ApiClient.atpBaseUrl;

  private mutex = new Mutex();

  constructor({ baseUrl = config.baseURL[0] }: { baseUrl: string }) {
    this.baseUrl = baseUrl;
  }

  setBaseUrl(accountType: ACCOUNT_TYPE_URL_INDEX) {
    this.baseUrl = config.baseURL[accountType];
  }

  objectToQueryParam(paramsObj: Record<string, unknown> | undefined) {
    if (!paramsObj) {
      return '';
    }
    const obj = {} as Record<string, string>;

    Object.keys(paramsObj).forEach((oKey) => {
      if (paramsObj[oKey] !== undefined && paramsObj[oKey] !== null) {
        obj[oKey] = `${paramsObj[oKey]}`;
      }
    });

    return new URLSearchParams(obj).toString();
  }

  private async baseRequest<P extends DefaultParamsType>({
    endpoint,
    init,
    method = 'GET',
    body = undefined,
    params,
    isAuth = true,
    formData = false,
    baseUrl,
  }: RequestParamsBase<P>): Promise<Response> {
    const queryString = this.objectToQueryParam(params);
    const _baseUrl = baseUrl || this.baseUrl;
    const urlWithParams = `${_baseUrl}${endpoint}${queryString ? `?${queryString}` : ''}`;

    let requestBody;
    if (method !== 'GET') {
      requestBody = formData ? body : JSON.stringify(body);
    }

    const promise = fetch(urlWithParams, {
      ...init,
      headers: {
        ...(init?.headers || {}),
        ...(isAuth ? { Authorization: `Bearer ${CookieManager.getAccessToken()}` } : {}),
      },
      method,
      body: requestBody,
    });

    logger.trackPromise(promise, {
      api_name: endpoint.toString(),
      method,
      server_type: _baseUrl === ApiClient.aodBaseUrl ? SupportedServerTypes.AOD : SupportedServerTypes.PREP,
    });

    return promise;
  }

  private async renewAccessToken() {
    const refreshToken = CookieManager.getRefreshToken();
    if (refreshToken) {
      try {
        const refreshResult = await this.baseRequest({
          baseUrl: ApiClient.atpBaseUrl,
          endpoint: '/token/refresh/',
          method: 'POST',
          init: {
            headers: {
              'content-type': 'application/json',
            },
          },
          body: { refresh: refreshToken },
        });
        if (refreshResult.ok) {
          const refreshData = (await refreshResult.json()) as {
            access: string;
          };
          if (refreshData?.access) {
            CookieManager.setAccessToken(refreshData.access);
            return;
          }
        }
      } catch (error) {
        console.error("Couldn't renew access token", error);
      }
    }
    logoutUser();
    throw new ApiError("Couldn't renew access token", null);
  }

  private flattenObject(obj: ApiFormErrorResponse | ApiFormErrorDetail, parentKey = ''): Record<string, string[]> {
    const result: Record<string, string[]> = {};

    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        const nestedFormError = obj[key] as ApiFormError[];
        // Check if the array contains ApiFormError objects
        if (nestedFormError.length > 0 && typeof nestedFormError[0] === 'object' && 'message' in nestedFormError[0]) {
          result[parentKey] = (result[parentKey] || []).concat(
            (obj[key] as ApiFormError[]).map((item) => item.message)
          );
        } else {
          // Handle the case where the value is a string array
          result[key] = (result[key] || []).concat(obj[key] as string[]);
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively process nested objects
        Object.assign(result, this.flattenObject(obj[key] as ApiFormErrorResponse, key));
      } else if (typeof obj[key] === 'string') {
        Object.assign(result, { [key]: [obj[key]] });
      }
    });

    return result;
  }

  private async parseApiError(res: Response): Promise<ApiError> {
    let errorResponse: ApiErrorResponse | null = null;
    let errorMessage = 'An unknown error occurred.';

    try {
      errorResponse = (await res.json()) as ApiErrorResponse;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (res.status) {
        errorMessage = res.statusText;
      } else {
        errorMessage = error.toString();
      }
    }
    if (errorResponse && typeof errorResponse === 'object') {
      if (errorResponse?.error_message) {
        errorMessage = errorResponse.error_message as string;
      } else if (errorResponse?.message) {
        errorMessage = errorResponse.message as string;
      } else if (errorResponse?.status) {
        errorMessage = errorResponse.status as string;
      } else if (errorResponse?.detail) {
        errorMessage = errorResponse.detail as string;
      } else if (errorResponse) {
        const formErrors = errorResponse as ApiFormErrorResponse;
        const flattenFormErrors = this.flattenObject(formErrors);
        const [key] = Object.keys(flattenFormErrors);
        const [value] = flattenFormErrors[key];
        errorMessage = value;
      }
    } else if (typeof errorResponse === 'string') {
      errorMessage = errorResponse as string;
    }
    return new ApiError(errorMessage, errorResponse);
  }

  private async handleUnauthorizedRequest(response: Response, params: RequestParamsBase): Promise<Response> {
    const errorResponse = (await response.json()) as ApiErrorResponseObject;
    if (errorResponse.code === 'user_not_found') {
      logoutUser();
      throw new ApiError('User not found', null);
    }
    if (!this.mutex.isLocked()) {
      const release = await this.mutex.acquire();
      try {
        await this.renewAccessToken();
      } finally {
        release();
      }
    } else {
      await this.mutex.waitForUnlock();
    }
    return this.baseRequest(params);
  }

  // private validateResponse<T>(response: T, schema?: ZodValidationSchemaType): void {
  //   const validationResult = schema?.safeParse(response);
  //   if (validationResult && !validationResult.success) {
  //     log(validationResult.error);
  //     snackbar.error(validationResult.error.toString());
  //   }
  // }

  private handleError(error: unknown): void {
    if (error instanceof ApiError && error.message) {
      console.error(error.message);

      if (error.message != '-1') {
        snackbar.error(error.message, {
          toastId: 'error-msg',
        });
      }
    } else {
      console.error('An unknown error occurred.');
      snackbar.error('An unknown error occurred.', {
        toastId: 'unknown-error',
      });
    }
  }

  private async makeRequest<T, P extends DefaultParamsType = DefaultParamsType>({
    endpoint,
    isAuth = true,
    ...params
  }: RequestParamsMakeRequest<P>): Promise<T> {
    try {
      // Wait until the mutex is available without locking it
      await this.mutex.waitForUnlock();

      // Base request call
      let res = await this.baseRequest({ endpoint, isAuth, ...params });

      if (!res.ok) {
        // Handle unauthorized response
        if (isAuth && res.status === 401) {
          res = await this.handleUnauthorizedRequest(res, {
            endpoint,
            ...params,
          });
        }
        if (!res.ok) {
          throw await this.parseApiError(res);
        }
      }
      if (res.status === 204) {
        // Empty response
        return res as T;
      }
      const response = (await res.json()) as T;
      // this.validateResponse<T>(response, responseSchema);
      return response;
    } catch (e: unknown) {
      this.handleError(e);
      throw e;
    }
  }

  async request<T, P extends DefaultParamsType = DefaultParamsType>({
    endpoint,
    params,
    init = {},
    ...rest
  }: Omit<RequestParamsMakeRequest<P>, 'body' | 'method'>): Promise<T> {
    return this.makeRequest<T>({
      endpoint,
      params,
      init,
      ...rest,
    });
  }

  async post<T, P>({
    endpoint,
    payload,
    init = {},
    method = 'POST',
    formData,
    ...rest
  }: Omit<RequestParamsBase, 'body' | 'method'> & {
    payload: P;
    method?: Exclude<ApiEndpointMethod, 'GET'>;
  }): Promise<T> {
    let headers = init.headers || {};
    if (!formData) {
      headers = { 'content-type': 'application/json' };
    }

    return this.makeRequest<T>({
      endpoint,
      method,
      body: payload,
      formData,
      init: {
        ...init,
        headers: {
          ...headers,
        },
      },
      ...rest,
    });
  }
}

// function logAll(endpoint: string, label: string, state: unknown) {
//   try {
//     googleAnalytics4.event({
//       category: 'API_CALL_LOG',
//       action: endpoint,
//       label,
//       nonInteraction: true,
//     });
//   } catch (e) {
//     log(e);
//   }
// }
