import SUPPORTED_BRANDS from './brands';
import SUPPORTED_ENVIRONMENT from './environments';

const isATP: boolean = import.meta.env.VITE_PORTAL === SUPPORTED_BRANDS.ATP;

export interface IConfig {
  isProduction: boolean;
  isStaging: boolean;
  isDevelopment: boolean;
  isLocal: boolean;
  currentEnvironment: SUPPORTED_ENVIRONMENT;
  currentBrand: SUPPORTED_BRANDS.ATP;
  isATP: boolean;
  isODTEnabled: boolean;
  isDedicatedAdviserEnabled: boolean;
  googleLoginClientId: string;

  name: string;
  baseURL: string[];
  tinyMCEKey: string;
  zinreloPartnerId: string;
  facebookLoginAppId: string;
  atpHotjarApiKey: number;
  swaAtpContactSupportTell: string;
  swaAtpContactSupportEmail: string;
  atpCustomerSuccess: string;

  upComingExamSupportLink: {
    link: string;
    id: string;
  };

  swaAtpSupportLink: {
    link: string;
    id: string;
  };

  moodleLink: string;
  ycbm_team_calendar: string;

  instructorPortalLink: string;
  bridgePlanLink: string;
  supportFormLink: string;

  moodleBaseUrl: string;
  defaultMoodlePassword: string;
}

const config: IConfig = {
  isProduction: import.meta.env.VITE_ENVIRONMENT === SUPPORTED_ENVIRONMENT.PRODUCTION,
  isStaging: import.meta.env.VITE_ENVIRONMENT === SUPPORTED_ENVIRONMENT.STAGING,
  isDevelopment: import.meta.env.VITE_ENVIRONMENT === SUPPORTED_ENVIRONMENT.DEVELOPMENT,
  isLocal: import.meta.env.VITE_ENVIRONMENT === SUPPORTED_ENVIRONMENT.LOCAL,
  currentEnvironment: (import.meta.env.VITE_ENVIRONMENT as SUPPORTED_ENVIRONMENT) || SUPPORTED_ENVIRONMENT.DEVELOPMENT,

  googleLoginClientId: import.meta.env.VITE_GOOGLE_CLIENT_ID as string,

  currentBrand: SUPPORTED_BRANDS.ATP,
  isATP,

  isODTEnabled: true,
  isDedicatedAdviserEnabled: true,

  name: import.meta.env.VITE_PORTAL_NAME || '',
  baseURL: [import.meta.env.VITE_API_BASE_URL || '', import.meta.env.VITE_API_AOD_URL || ''],
  tinyMCEKey: import.meta.env.VITE_TINY_MCE_KEY || '',
  zinreloPartnerId: import.meta.env.VITE_ZINRELO || '',

  facebookLoginAppId: import.meta.env.VITE_FB_APP_ID || '',

  atpHotjarApiKey: 2048904,

  swaAtpContactSupportTell: '1-888-600-3640',
  swaAtpContactSupportEmail: 'it-notice@achieve-test-prep.com',
  atpCustomerSuccess: 'customersuccess@achieve-test-prep.com',

  upComingExamSupportLink: {
    link: 'https://advisingmeeting.youcanbook.me',
    id: 'ycbmiframeatpcustomersuccessconcierge',
  },

  swaAtpSupportLink: {
    link: 'https://advisingmeeting.youcanbook.me',
    id: 'ycbmiframeatpcustomersuccessconcierge',
  },

  moodleLink: 'https://moodle.achievetestprep.com/login',
  ycbm_team_calendar: 'https://ap-tutor-achieve.youcanbook.me',

  instructorPortalLink: import.meta.env.VITE_INSTRUCTOR_PORTAL_URL as string,
  bridgePlanLink: import.meta.env.VITE_UNIFIED_PORTAL_URL as string,

  supportFormLink: 'https://help.achievetestprep.com/s/caseform',

  moodleBaseUrl: import.meta.env.VITE_MOODLE_URL || 'https://staging-moodle.achievetestprep.com',
  defaultMoodlePassword: 'welcome12345',
};

export default config;
