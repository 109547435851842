import { Suspense, lazy } from 'react';

import { Outlet } from 'react-router-dom';

import { ErrorBoundary } from '@/_components';
import { MeProvider, AccountDependenciesProvider, WalkthroughProvider, OnboardingProvider } from '@/_providers';

import { AchCampaignModal, WellcoachesAgreementModal } from './campagins';

const LoadingPlayer = lazy(() => import('@/_shared/animations/loading/loading-player'));

export function LayoutWithConfigurations() {
  return (
    <ErrorBoundary boundaryType="blocking">
      <Suspense fallback={<Loader />}>
        <AccountDependenciesProvider>
          <ErrorBoundary boundaryType="blocking">
            <Suspense fallback={<Loader />}>
              <MeProvider>
                <OnboardingProvider>
                  <WalkthroughProvider>
                    <AchCampaignModal />
                    <WellcoachesAgreementModal />
                    <Outlet />
                  </WalkthroughProvider>
                </OnboardingProvider>
              </MeProvider>
            </Suspense>
          </ErrorBoundary>
        </AccountDependenciesProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export function Loader() {
  return (
    <div className="ldr">
      <Suspense fallback={<div className="hidden" />}>
        <LoadingPlayer />
      </Suspense>
    </div>
  );
}
