import type { InputHTMLAttributes } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { FormInput, useBoolean } from 'atp-react-ui';
import type { FormLabelProps } from 'atp-react-ui/dist/components/form-components/types';

import { ContactTimeSelector } from './contact-time-selector';

type FormContactTimeInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onClick'> &
  FormLabelProps & {
    value?: string;
  };

const FormContactTimeInput = forwardRef<HTMLInputElement, FormContactTimeInputProps>(
  ({ value: val, ...props }, ref) => {
    const [value, setValue] = useState(val);
    const [isOpen, open] = useBoolean();

    const onChange = (_value: string) => {
      setValue(_value);
      props.onChange?.({ target: { name: props.name || '', value: _value } } as React.ChangeEvent<HTMLInputElement>);
      open.setFalse();
    };

    useEffect(() => {
      setValue(val);
    }, [val]);

    return (
      <>
        <ContactTimeSelector open={isOpen} callback={onChange} value={value} />

        <FormInput
          {...props}
          ref={ref}
          autoCorrect="false"
          autoComplete="false"
          label="Preferred time of contact"
          onClick={open.setTrue}
          value={value}
        />
      </>
    );
  }
);

FormContactTimeInput.displayName = 'FormContactTimeInput';

export { FormContactTimeInput };
