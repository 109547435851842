import { Card, CardBody, Skeleton } from 'atp-react-ui';

import { Container, InputSkeleton, PageHead } from '@/_components';

export default function Loading() {
  return (
    <>
      <Container>
        <PageHead className="flex-col items-start">
          <section className="mt-2 flex items-center">
            <Skeleton className="h-8 w-64" />
          </section>

          <div className="flex flex-col gap-y-2 pt-3 text-sm text-accent-light/80 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="flex items-center">
              <Skeleton className="h-[1.125rem] w-96" />
            </div>
          </div>
        </PageHead>
      </Container>

      <Container className="mt-6">
        <section className="flex flex-col gap-y-4 pre-xl:w-3/4">
          <Card>
            <CardBody className="px-6 py-12 sm:px-10">
              <div className="flex flex-col gap-4">
                <InputSkeleton />
                <InputSkeleton />
                <InputSkeleton />
                <Skeleton className="mt-4 h-11 w-32" />
              </div>
            </CardBody>
          </Card>
        </section>
      </Container>
    </>
  );
}
