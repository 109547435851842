import type { ModalProps } from 'atp-react-ui';
import { Modal } from 'atp-react-ui';

import type { IFrameProps } from './iframe';
import { IframeViewer } from './iframe';

type IFrameModalProps = Omit<ModalProps, 'disableInternalClosing' | 'unmount'> & {
  iframeProps: Partial<IFrameProps> & Pick<IFrameProps, 'urlObj'>;
};

export function IFrameDialog({ iframeProps, ...props }: IFrameModalProps) {
  return (
    <Modal {...props}>
      <section className="relative -left-6 -right-6 -z-10 m-0 -mb-4 flex h-[calc(100vh_-_4rem)] max-h-[43.75rem] w-screen flex-col bg-white pt-10 outline-none md:max-w-xl">
        <IframeViewer {...iframeProps} title="Contact our Advisors" />
      </section>
    </Modal>
  );
}
