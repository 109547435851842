import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'atp-react-ui';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/_navigation';

type CourseDependencyModalProps = {
  open: boolean;
  onClose: () => void;
  isSpanish2Taken: boolean;
};

export function CourseDependencyModal({ open, isSpanish2Taken, onClose }: CourseDependencyModalProps) {
  const nav = useNavigate();

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>Schedule Exam not available</ModalHeader>
      <ModalBody>
        {isSpanish2Taken
          ? 'Spanish 1 is part of the Spanish program. Complete Spanish 2 course before registering for your exam.'
          : 'Spanish 1 is part of the Spanish program. Register Spanish 2 to complete the program before scheduling your exam.'}
      </ModalBody>
      {!isSpanish2Taken && (
        <ModalFooter>
          <Button
            modal
            onClick={() => {
              nav(ROUTES.UNREGISTERED.path);
              onClose();
            }}
          >
            Visit the Unregistered Section
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}
