import { Skeleton } from 'atp-react-ui';

import { Container } from '@/_components';

import { Header } from './components';

export default function Loading() {
  return (
    <>
      <Header />
      <Container className="pt-1">
        <div className="pt-1 pre-xl:w-3/4">
          <Skeleton className="h-10 w-full" />
        </div>
      </Container>
      <Container className="mt-6 space-y-6">
        <div className="pre-xl:w-3/4">
          <Skeleton className="h-4 w-52" />
          <Skeleton className="mt-1.5 h-80 w-full" />
        </div>
        <Skeleton className="h-11 w-44" />
      </Container>
    </>
  );
}
