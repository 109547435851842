import { STUDENT_STATUS } from '@/_config';
import type {
  AchAccountType,
  ChargeoverPaymentMethodsApiResponseType,
  CreditCardType,
} from '@/_prep-portal/billing/api';

import { dateTime } from '../utils';

// stripe billing data
export function isNewCustomerWithin24Hours(timeSpent?: string | null) {
  return !!timeSpent;
}

// by logic isPaidInOneGoUser users are not subscriber of any plan so has_plan returns false in case of isPaidInOneGoUser
// in all other cases if user has plan has_plan = true
export function hasPlan(has_plan: boolean, is_pif: boolean) {
  return !!has_plan || !!is_pif;
}

// enrollment_end_date: me API/ plan summary
export function hasExpiredPlan({
  enrollment_end_date,
  extension_ea_date,
  byDate = new Date(),
}: {
  enrollment_end_date?: string | null;
  extension_ea_date?: string | null;
  byDate?: Date;
}) {
  let expiredContract = !dateTime.isDateSameOrAfter(enrollment_end_date, byDate);
  if (expiredContract && extension_ea_date) {
    expiredContract = !dateTime.isDateSameOrAfter(extension_ea_date, byDate);
  }

  return expiredContract;
}

// pilot: if user has paid full payment of subscribed plan it pay in full = true
// legacy: if percentage_of_completion = 100 then user has paid full amount of subscription
// is_pif: if user isPaidInOneGoUser
export function hasPaidFullAmount(
  pay_in_full: boolean | null,
  percentage_of_completion: number | null,
  is_pif: boolean
) {
  return !!pay_in_full || percentage_of_completion === 100 || !!is_pif;
}

// tells about current payment method is checking/ACH or not
export function isPaymentMethodAch(defaultMethodObject = null, chargent_orders_payment_method = 'Check') {
  return defaultMethodObject === 'bank_account' || chargent_orders_payment_method === 'Check';
}

// tells about current payment method is checking/ACH or not
export function isAccountOnHold(student_status: STUDENT_STATUS) {
  return student_status === STUDENT_STATUS.ON_HOLD;
}

// tells about current payment method is checking/ACH or not
export function isAccountOnWithdrawal(student_status: STUDENT_STATUS) {
  return student_status === STUDENT_STATUS.WITHDRAWN;
}

export function organizePaymentMethods(
  paymentMethodsData: ChargeoverPaymentMethodsApiResponseType | undefined,
  defaultPaymentMethod: CreditCardType | AchAccountType | undefined
) {
  const credit_cards: (CreditCardType & { isDefault?: boolean })[] = [];
  const ach_accounts: (AchAccountType & { isDefault?: boolean })[] = [];

  if (paymentMethodsData) {
    // Add the default payment method to the appropriate array
    if (defaultPaymentMethod) {
      if ('creditcard_id' in defaultPaymentMethod) {
        // Default payment method is a credit card
        credit_cards.push({ ...defaultPaymentMethod, isDefault: true });
      } else if ('ach_id' in defaultPaymentMethod) {
        // Default payment method is an ACH account
        ach_accounts.push({ ...defaultPaymentMethod, isDefault: true });
      }
    }

    // Add other credit cards and ACH accounts, skipping the default if it's already added
    paymentMethodsData.creditcards.forEach((card) => {
      if (
        !defaultPaymentMethod ||
        !('creditcard_id' in defaultPaymentMethod) ||
        ('creditcard_id' in defaultPaymentMethod && card.creditcard_id !== defaultPaymentMethod.creditcard_id)
      ) {
        credit_cards.push({ ...card, isDefault: false });
      }
    });

    paymentMethodsData.ach.forEach((ach) => {
      if (
        !defaultPaymentMethod ||
        !('ach_id' in defaultPaymentMethod) ||
        ('ach_id' in defaultPaymentMethod && ach.ach_id !== defaultPaymentMethod.ach_id)
      ) {
        ach_accounts.push({ ...ach, isDefault: false });
      }
    });
  }

  return { creditCards: credit_cards, achAccounts: ach_accounts };
}
