import type { PropsWithChildren } from 'react';

import { unescape } from 'html-escaper';
import { twMerge } from 'tailwind-merge';

export const CreateMarkup = (x: string) => ({ __html: x });

type DangerousTextProps = {
  str: string;
  className?: string;
};

export function DangerousText({ str, className }: PropsWithChildren<DangerousTextProps>): JSX.Element {
  return <span className={className} dangerouslySetInnerHTML={CreateMarkup(str)} />;
}

export function QuestionText({ className, str }: PropsWithChildren<DangerousTextProps>): JSX.Element {
  return <DangerousText str={unescape(str)} className={twMerge(className, 'question-text')} />;
}
