import { lazy } from 'react';

import { SurveyPage } from '../shared';

const Page = lazy(() => import('./page'));

export function AfterCallSurvey() {
  return (
    <SurveyPage title="After Call Survey">
      <Page />
    </SurveyPage>
  );
}
