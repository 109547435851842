import { Suspense, lazy } from 'react';

import Loading from './loading';

const Page = lazy(() => import('./page'));

export default function Login() {
  return (
    <Suspense fallback={<Loading />}>
      <Page />
    </Suspense>
  );
}
