import { Card, CardBody, Skeleton } from 'atp-react-ui';

export function TakenCourseSkeleton() {
  return (
    <Card className="w-64">
      <CardBody className="flex flex-col items-start gap-2 p-3 sm:p-3">
        <Skeleton className="h-32" />
        <div className="flex w-full flex-col gap-y-1.5">
          <Skeleton className="h-4 w-2/3" />
          <Skeleton className="h-2 w-1/3" />
        </div>
        <div className="mt-4 flex w-full flex-col gap-y-2">
          <Skeleton className="h-[1.125rem]" />
          <Skeleton className="h-[1.125rem]" />
          <Skeleton className="h-[1.125rem]" />
          <Skeleton className="h-[1.125rem]" />
          <Skeleton className="h-[1.125rem]" />
          <Skeleton className="h-[1.125rem]" />
          <Skeleton className="h-[1.125rem]" />
        </div>
      </CardBody>
    </Card>
  );
}
