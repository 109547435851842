import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient, queryClient } from '@/_services';

import { getCourseImage } from './shared';
import type { ExamsQueryModifiedSchema } from './use-exams-query';
import { examsQuerySchema, parseExamsResponse } from './use-exams-query';
import type { RegisteredCoursesModifiedResponse } from './use-registered-courses-query';
import {
  RegisteredCoursesApiResponseSchema,
  filterOutCourses,
  splitPrepAndOdtCourses,
} from './use-registered-courses-query';
import type { AodCourseSchema } from './use-unregistered-aod-courses-query';
import { UnregisteredAodCoursesApiResponseSchema } from './use-unregistered-aod-courses-query';
import type { UnregisteredCourseSchema } from './use-unregistered-courses-query';
import { UnregisteredCoursesApiResponseSchema } from './use-unregistered-courses-query';

export const courseDashboardQueryResponseSchema = z.object({
  enrollments: UnregisteredCoursesApiResponseSchema,
  aod_enrollments: UnregisteredAodCoursesApiResponseSchema,
  registered_courses: RegisteredCoursesApiResponseSchema,
  exams: examsQuerySchema,
});

export type CourseDashboardQueryResponseType = z.infer<typeof courseDashboardQueryResponseSchema>;

export type CourseDashboardQueryModifiedResponseType = {
  unregistered: (UnregisteredCourseSchema | AodCourseSchema)[];
  registered: RegisteredCoursesModifiedResponse;
  exams: ExamsQueryModifiedSchema[];
};

export const COURSES_DASHBOARD_QUERY_KEY = ['COURSES_DASHBOARD'];

export const useCoursesQuery = () =>
  useSuspenseQuery<CourseDashboardQueryResponseType, unknown, CourseDashboardQueryModifiedResponseType>({
    queryKey: COURSES_DASHBOARD_QUERY_KEY,
    queryFn: async () =>
      apiClient.request({
        endpoint: '/course-dashboard/',
        responseSchema: RegisteredCoursesApiResponseSchema,
      }),
    select: ({
      enrollments,
      aod_enrollments,
      registered_courses,
      exams,
    }): CourseDashboardQueryModifiedResponseType => ({
      unregistered: [
        ...enrollments.map((course) => ({ ...course, course_image: getCourseImage(course.name) })),
        ...aod_enrollments,
      ],
      registered: splitPrepAndOdtCourses(filterOutCourses(registered_courses)),
      exams: parseExamsResponse(exams),
    }),
  });

export const invalidateCoursesQuery = (time = 0) => {
  setTimeout(() => queryClient.invalidateQueries({ queryKey: COURSES_DASHBOARD_QUERY_KEY }), time);
};
